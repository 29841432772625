import { OutfitHomePageDTO } from "api/types/responses/HomePageResponse"
import { IState } from "../SharedStateType"

export type OutfitsStateType = {
// state dedicato alla home page
homePageOutfit:IState<OutfitHomePageDTO>
// state per la pagina degli outfit
outfitsList:IState<OutfitHomePageDTO[]>
// state per outfit selezionato
selectedOutfit: IState<OutfitHomePageDTO>

}


export const OUTFITS = 'outfits'
export type OUTFITS = typeof OUTFITS

export const OUTFITS_HOME_PAGE = `${OUTFITS}/setOutfitsHomePageAction`
export type OUTFITS_HOME_PAGE = typeof OUTFITS_HOME_PAGE

export const GET_OUTFITS_LIST = `${OUTFITS}/getOutfitsListAction`
export type GET_OUTFITS_LIST = typeof GET_OUTFITS_LIST