import { useContext, useEffect } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useAppSelector } from "redux/app/hooks";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { AddressPageEdit } from "./AddressPageEdit";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export function AddressPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, openAsideMenu, setIsLoading } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const { data: userData } = useAppSelector((state) => state.user.user)
  const { data: userAddresses, isLoading: isFetchingAddresses, errors: addressError } = useAppSelector((state) => state.user.userShippingAddresses)
  const { t } = useTranslation();
  const navigate = useNavigate()

  useEffect(() => {

    setIsLoading(isFetchingAddresses)
  }, [isFetchingAddresses])


  const handleAddEditAddress = (id: string | undefined): void => {
    if (isMobile) {
      navigate(PAGE.addressEdit.menuPath, { state: { id: id ? id : undefined } })
      return
    }
    openAsideMenu<string | undefined>(<AddressPageEdit />, id ? id : undefined)

  };

  return (
    <Flex padding={["5% 0", "5% 0", "0"]} width={[1]} justifyContent={"center"}>
      {userData && (
        <Flex key={userData.Id} flexDirection={"column"} width={[1]} padding={["0 1em", "0 5em"]} $gap={1.2} maxWidth={["none", 1100]}>
          <MenuNavigateProps userData={userData} />

          <Flex justifyContent={"space-between"} alignItems={"center"} marginTop={"1em"}>
            <Txt as={"h3"} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"}>
              {t(i18Enum.User_Shipping_Label_ShippingInfo)}
            </Txt>
            <Txt variant='linkLight' color={colors.primaryText} fontSize={[3]} onClick={() => handleAddEditAddress(undefined)}>
              {t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddAddress)}
            </Txt>
          </Flex>

          {userAddresses?.length ? (
            <Grid marginBottom={"15vh"} style={{ rowGap: "1em", columnGap: "1em" }} gridTemplateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}>
              {userAddresses.map((address, index) => (
                <AddressCard
                  addressId={address.Id}
                  key={index}
                  type={addressType.userAddress}
                  Name={address.Name ?? ""}
                  City={address.City ?? ""}
                  AddressLine1={address.AddressLine1 ?? ""}
                  AddressLine2={address.AddressLine2 ?? ""}
                  ZipCode={address.ZipCode ?? ""}
                  CountryKey={address.CountryKey ?? 110}
                  isDefault={!!address.IsDefault}
                  handleEdit={() => handleAddEditAddress(address.Id)}
                  isBilling={address.IsBilling ?? false}
                />
              ))}
            </Grid>
          ) : (
            <Txt marginTop={0} marginBottom={"30vh"} as={"p"} variant='light' color={colors.thirdText} textAlign={"left"} $textTransform='inherit' fontSize={[3]}>
              {t(i18Enum.User_Shipping_ShippingAddresses_Message_NoShippingAddresses)}
            </Txt>
          )}
        </Flex>
      )}
    </Flex>
  );
}
