import { BasketShopDTO } from "redux/features/cart/CartType";

export const filterItems = (shoppingBasketShops: BasketShopDTO[] | undefined, newQuantity?: number, isQuantityIncreased?: boolean) => {
  const items = shoppingBasketShops
    ?.map((shop) =>
      shop.Variants.map((product) => {
        const updatedQuantity = isQuantityIncreased === undefined ? product.Quantity : isQuantityIncreased ? newQuantity : 1
        return {
          item_brand: shop.Name,
          item_id: product.Id,
          item_name: product.ProductName,
          price: product.DiscountedPrice ?? product.Price,
          quantity: updatedQuantity,
        };
      })
    )
    .flat();

  return items;
};
