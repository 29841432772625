import Lottie from "lottie-react";
import Flex from "../primitives/Flex";
import WardrobeImage from "../primitives/WardrobeImage";
import avatar from "../../assets/lotties/AVATAR1.json";

export interface IAvatar {
  imageUrl?: string | null;
  userId?: string;
  disabled?: boolean;
}

export const Avatar = ({ imageUrl, userId, disabled }: IAvatar) => {
  

  const goToUserProfile = (id?: string) => {
    if (disabled || !userId) {
      return;
    }
    alert(`navigate to profile ${id}`);
  };

  return (
    <Flex onClick={() => goToUserProfile(userId)} style={{ cursor: disabled ? "" : "pointer" }}>
      {imageUrl ? (
        <WardrobeImage src={imageUrl} variant={"circle"} style={{ boxShadow: "0px 1px 5px rgba(0,0,0,0.2)" }} />
      ) : (
        <Lottie animationData={avatar} autoplay loop height={"100%"} width={"100%"} />
      )}
    </Flex>
  );

};
