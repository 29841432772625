import { useEffect, useState } from "react";
import Select from "react-select";
import { SpaceProps, WidthProps, PositionProps } from "styled-system";
import { privateCharacters } from "../../utils";
import Box from "../primitives/Box";
import Txt from "../primitives/Txt";
import Flex from "../primitives/Flex";
import ViewHideButton from "./ViewHideButton";
import { CreateDefaultStyle } from "../primitives/Select";
import Label from "components/primitives/Label";
import { CreateDropdownStyle } from "components/primitives/Dropdown";

type variants = "dropdown";

interface SelectProps<T> extends SpaceProps, WidthProps, PositionProps {
  id: string;
  label?: string;
  option: T[];
  isPrivate?: boolean;
  placeholder?: string;
  value?: T | null;
  defaultValue?: T;
  defaultMenuIsOpen?: boolean;
  isSearchable?: boolean;
  handleChange: (value: T) => void;
  labelExtractor: (option: T) => string;
  valueExtractor: (option: T) => string;
  handleBlur: () => void;
  variant?: variants;
  onInputChange?: (value: string) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?:boolean
}

//const { ValueContainer, Placeholder } = components;

function SelectField<T>({ isClearable,handleBlur, onInputChange,defaultMenuIsOpen, defaultValue, id, option, isPrivate, value, handleChange, placeholder, valueExtractor, labelExtractor, variant, isSearchable, isDisabled, isLoading, ...props }: SelectProps<T>) {
  const [privateType, setPrivateType] = useState<boolean | undefined>(isPrivate);
  const handleSee = () => setPrivateType(!privateType);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setPrivateType(isPrivate);
  }, [isPrivate]);

  /*  const handleBlurLocal = () => {
    setFocused(false);
    handleBlur!();
  }; */

  return (
    <Flex flexDirection={"column"} alignItems={"start"} {...props}>
      <Box position={"relative"} width={"100%"}>
        <Label
          fontSize={focused || value ? 2 : 3}
          paddingLeft={["18px"]}
          htmlFor={id}
          position={"absolute"}
          top={focused || value ? "-20px" : "8px"}
          left={0}
          zIndex={1}
          $transition={"all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)"}
        >
          {placeholder}
        </Label>
        <Select<T>
          isClearable={isClearable}
          menuPlacement='auto'
          isLoading={isLoading}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          defaultMenuIsOpen={defaultMenuIsOpen}
          placeholder={placeholder}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
            handleBlur();
          }}
          defaultValue={defaultValue as T}
          value={value}
          getOptionLabel={labelExtractor}
          getOptionValue={valueExtractor}
          theme={(theme) => ({
            ...theme,
            spacing: {
              ...theme.spacing,
              baseUnit: 2,
              controlHeight: 30,
              menuGutter: 8,
            },
          })}
          styles={variant === "dropdown" ? CreateDropdownStyle<T>() : CreateDefaultStyle<T>({ isPrivate, privateType })}
          inputId={id}
          options={option}
          onChange={(option) => handleChange(option as T)}
          onInputChange={(value) => onInputChange?.(value)}
        />
        {value && privateType && (
          <Txt color={"#ffffff"} as={"label"} htmlFor={id} fontSize={[3]} padding={["0.48em 20px"]} position={"absolute"} left={0} top={"0.3em"}>
            {value && privateCharacters(`${value}`)}
          </Txt>
        )}
        {isPrivate !== undefined && <ViewHideButton variant={!privateType ? "see" : "hidden"} position={"absolute"} right={0} top={0} zIndex={0} handleClick={handleSee} />}
      </Box>
      {/* {error && !isFocused && !value && (
        <Txt
          padding={"13px  0 0 18px"}
          variant="error"
          fontSize={[3]}
          textAlign={"left"}
        >
          {capitalize(error)}
        </Txt>
      )} */}
    </Flex>
  );
}

export default SelectField;

/* 
example
<SelectField
  id="select"
  placeholder="select"
  label="label"
  option={[ { label: "op1", value: "op1" }]}
  isPrivate={true}
  value={watch("select")}
  handleChange={({ value }) => setValue("select", value!)}
/>
*/
