import styled from "styled-components";

import {
  PositionProps,
  position,
  ColorProps,
  SpaceProps,
  TypographyProps,
  space,
  color,
  typography,
  compose,
  variant,
  LayoutProps,
  layout,
  BorderProps,
  border,
  fontSize,
  TextAlignProps,
  textAlign,
  textStyle,
  TextStyleProps,
} from "styled-system";

interface TextProps {
  variant?: "success" | "error" | "link" | "linkLight" | "linkDanger" | "linkLightSimple" | "disabledLink" | "info" | "light" | "medium" | "bold" | "crossed" | "promo" | "cardBtn" | "upsideDown";
}

const Txt = styled.span<
  TextProps &
    ColorProps &
    TypographyProps &
    SpaceProps &
    PositionProps &
    LayoutProps &
    BorderProps &
    TextAlignProps &
    TextStyleProps & {
      $textTransform?: string;
      $textDecoration?: string;
      $hoverColor?: string;
      $cursor?: string;
      $textOverflowHidden?:boolean;
    }
>`
  text-transform: ${(props) => props.$textTransform || "uppercase"};
  text-decoration: ${(props) => props.$textDecoration || "none"};
  cursor: ${(props) => props.$cursor || "default"};
  text-overflow: ${(props) => props.$textOverflowHidden ?  "ellipsis" : null };
  white-space: ${(props) => props.$textOverflowHidden ? "nowrap": null };
  overflow: ${(props) => props.$textOverflowHidden ? "hidden" : null };
  word-break: ${(props) => props.$textOverflowHidden ? "break-all" : null };
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${border}
  ${fontSize}
  ${position}
  ${textAlign}
  ${textStyle}
  ${(props) =>
    compose(
      variant({
        variants: {
          link: {
            fontFamily: "BauProMedium",
            textDecoration: "underline",
            cursor: "pointer",
          },
          linkLight: {
            textDecoration: "underline",
            cursor: "pointer",
            fontFamily: "BauPro",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: 300,
            textTransform: "inherit",
          },
          linkDanger: {
            textDecoration: "underline",
            cursor: "pointer",
            fontFamily: "BauPro",
            color: props.color || props.theme.colors.dangerBase,
            fontWeight: 400,
            textTransform: "inherit",
          },
          linkLightSimple: {
            fontFamily: "BauPro",
            cursor: "pointer",
            color: props.color || props.theme.colors.secondaryText,
            fontWeight: 300,
            "&:hover": {
              color: props.$hoverColor || props.theme.colors.primaryBase,
            },
          },
          success: {
            color: props.theme.colors.successText,
          },
          error: {
            color: props.theme.colors.dangerText,
            fontFamily: "BauPro",
            textTransform: "inherit",
          },
          disabledLink: {
            color: props.theme.colors.primaryText,
            fontWeight: "bold",
            cursor: "default",
          },
          info: {
            color: props.theme.colors.secondaryText,
            fontFamily: "BauProMedium",
            fontSize: props.fontSize || ["8px"],
            backgroundColor: props.theme.colors.thirdBg,
            padding: "3px 0.7em 2px",
            lineHeight: "1.1em",
          },
          promo: {
            color: props.theme.colors.secondaryText,
            fontFamily: "BauPro",
            fontSize: ["8px"],
            backgroundColor: props.theme.colors.yellow,
            padding: "3px 0.7em 2px",
            lineHeight: "1.1em",
            boxShadow: props.theme.colors.yellowShadow,
          },
          light: {
            fontFamily: "BauPro",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: 300,
          },
          medium: {
            fontFamily: "BauProMedium",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: 500,
          },
          bold: {
            fontFamily: "BauProBold",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: "bold",
          },
          crossed: {
            fontFamily: "BauPro",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: 300,
            textDecoration: "line-through !important",
          },
          upsideDown: {
            fontFamily: "BauProMedium",
            color: props.color || props.theme.colors.thirdText,
            fontWeight: 500,
            margin: 0,
    

            "&:hover": {
              transform: "scale(-1)",
              transition: "all 0.5s",
            },
          },
        },
      })
    )};
`;

export default Txt;
