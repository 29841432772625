import { useEffect } from "react";

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string | number | readonly string[] | undefined) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";

      if (scrollHeight > 40) {
        textAreaRef.style.borderRadius = 16 + "px";
      } else {
        textAreaRef.style.borderRadius = 50 + "px";
      }
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
