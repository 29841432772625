import { ReactNode, useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";

import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import { BordersProps, HeightProps, MaxHeightProps, PositionProps } from "styled-system";
import Box from "components/primitives/Box";
import { DotsLoader } from "components/molecules/DotsLoader";



type variants = "cart" | "address";
export interface IMobileNavigationBottom extends PositionProps, HeightProps, BordersProps , MaxHeightProps{
  description: string|ReactNode;
  cartShippingInfo?: string;
  text: string;
  variant?: variants;
  buttonAction?: () => void;
  disabled?:boolean
  isLoading?:boolean
}

export const MobileNavigationBottom = ({ isLoading,cartShippingInfo, description, disabled, text, variant, buttonAction, ...props }: IMobileNavigationBottom) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;

  return (
    <>
      <Box marginBottom={customTheme.asideMB} />
      <Flex
        style={{ boxSizing: variant ===  "address" ? "content-box" : "unset"}}
        width={"100%"}
        height={["15%", "100%"]}
        position={["sticky", "sticky"]}
        zIndex={14}
        bottom={0}
        alignItems={isLoading && variant === "cart" ? "center" : "start"}
        backgroundColor={colors.secondaryBg}
        {...props}
      >
        {variant === "cart" ? (
          <Flex flexDirection={"column"} height={["100px", "80px"]} width={"70%"} justifyContent={"center"} alignItems={"start"} paddingLeft={"10%"}>
            <Txt variant='light' fontSize={[2]}>
              {description}
            </Txt>
            <Txt variant='light' color={colors.primaryBase} fontSize={[2]}>
              {cartShippingInfo}
            </Txt>
          </Flex>
        ) : (
          <Flex height={["100px", "80px"]} width={"70%"} justifyContent={"start"} paddingLeft={"10%"} alignItems={"center"}>
            <Txt variant='light' fontSize={[2]}>
              {description}
            </Txt>
          </Flex>
        )}

        <Flex height={["100px", "80px"]} marginBottom={["0px", "0px"]} width={"30%"} justifyContent={"center"} alignItems={"center"}>
          {isLoading ? (
            <DotsLoader colors={colors.thirdBase} />
          ) : (
            <Button height={"100%"} disabled={disabled} type='submit' variant='save' border='none !important' onClick={buttonAction ? () => buttonAction() : undefined}>
              {text}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};
