import Flex from "../primitives/Flex"
import BouncingDot
 from "../atoms/BouncingDot"

interface LoaderProps{
  colors?: string,
  customHeight?: string,
}


export const DotsLoader = ({ colors, customHeight }: LoaderProps) => {
  return (
    <Flex width={[1]} height={customHeight ? customHeight : "35px"} justifyContent={"center"} alignItems={"end"}>
      <BouncingDot color={colors ? colors : "white"} />
      <BouncingDot color={colors ? colors : "white"} $animationDelay={"0.2s"} />
      <BouncingDot color={colors ? colors : "white"} $animationDelay={"0.4s"} />
    </Flex>
  );
};
