import { CategoriesTag, SizeCategory } from "utils/generalTypes/category";
import { getCall } from "api/baseCall";
import { getAriesCall } from "api/baseAriesCall";
import { Tags } from "redux/features/product/ProductsTypes";
import { BaseResponse } from "api/types/responses";
import { FilterList } from "api/types/responses/HomePageResponse";



export const getFilterTagsCall = async ()  =>
  await getAriesCall<BaseResponse<FilterList>>('Product/Filters');

export const getSizeCategoriesCall = async ()  =>
 await getCall<SizeCategory[]>('api/Product/CategorySizes');
