import icon from "../../assets/icons/logo.svg";
import Flex from "components/primitives/Flex";
import Image from "components/primitives/Image";
import Txt from "components/primitives/Txt";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { SpaceProps } from "styled-system";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface StepperHeaderProps extends SpaceProps {
  title: string;
  backToStep?: () => void;
}

function StepperHeader({ title,backToStep, ...props }: StepperHeaderProps) {
  const{customTheme} = useContext(CustomThemeContext)
  const color = customTheme.colors;
  const { t } = useTranslation();
 
  return (
    <Flex width={[1]}>
      <Flex as='header' width={[1]} alignItems={"center"} justifyContent={"space-between"} {...props}>
        <Image src={icon} width={158} height={27} />

        <Txt variant='light' fontSize={[18]} paddingTop={[2]} paddingLeft={[3]} marginRight={"auto"}>
          {title}
        </Txt>
      </Flex>
      {backToStep && (
        <Flex alignItems={"Center"} onClick={backToStep}>
          <ArrowLeft color={color.primaryBase} size={20} />
          <Txt variant='linkLight' fontSize={[18]} color={color.primaryBase} paddingTop={[2]} paddingLeft={[3]} marginRight={"auto"}>
            {t(i18Enum.Common_Back)}
          </Txt>
        </Flex>
      )}
    </Flex>
  );
}

export default StepperHeader;
