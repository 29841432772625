import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { CustomThemeContext } from "context/ThemeContext";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import { AppGeneralContext } from "context/AppGeneralContext";
import { toastType } from "utils";
import { PAGE } from "utils/const/pagePath";
import { DotsLoader } from "components/molecules/DotsLoader";
import { getForgotPasswordCode, verifyForgotPswCall } from "api/authCall";
import Form from "components/primitives/Form";
import Box from "components/primitives/Box";
import ArrowSvg from "components/atoms/ArrowSvg";

export interface ResetPswForm {
  Code: string;
  Email: string;
  Password: string;
  PasswordConfirmation: string;
}

export const ForgotPswPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { addToast } = useContext(AppGeneralContext);
  const [params] = useSearchParams();
  const queryCode = params.get("code");
  const queryEmail = params.get("email");
  const navigate = useNavigate();
  const state: string = useLocation().state;
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState<string>(state ?? queryEmail);
  const [loading, setLoading] = useState<boolean>(false);
  const [registerCode, setRegisterCode] = useState<string|null>(queryCode);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const resetFormInitialValues: ResetPswForm = {
    Code: queryCode ?? "",
    Email: "",
    Password: "",
    PasswordConfirmation: "",
  };

  const resetFormSchema = Yup.object().shape({
    Code: Yup.string(),
    Email: Yup.string(),
    Password: Yup.string()
      .password()
      .required(t(i18Enum.Validation_Client_Field_Required))
      .min(10, t(i18Enum.Login_Password_ValidationFailed, { value: "10" }))
      .minLowercase(1)
      .minUppercase(1)
      .minNumbers(1)
      .minSymbols(1),
    PasswordConfirmation: Yup.string()
      .oneOf([Yup.ref("Password")], t(i18Enum.Login_PasswordsMustMatch))
      .required(t(i18Enum.Validation_Client_Field_Required)),
  });

  const formik = useFormik<ResetPswForm>({
    enableReinitialize: true,
    initialValues: resetFormInitialValues,
    validationSchema: resetFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      const req = {
        Code: registerCode ?? values.Code,
        Email: userEmail,
        Password: values.Password,
      };
      const resp = await verifyForgotPswCall(req);

      if (!resp.isSuccess) {
        addToast(toastType.error, t(i18Enum.ForgotPsw_ErrorMessage));
      } else {
        addToast(toastType.success, t(i18Enum.ForgotPsw_SuccessMessage));
        navigate(PAGE.home.menuPath);
      }
      setSubmitting(false);
      setLoading(false);
      formik.resetForm();
    },
  });

  const handleBack = () => {
    navigate(-1);
  };

   useEffect(() => {
     if (!queryCode) {
       return;
     }
     setRegisterCode(queryCode);
     setIsResetPassword(true);
   }, [queryCode]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserEmail(value);
  };

  const getResetPswForm = async () => {
    setLoading(true);
    const resp = await getForgotPasswordCode(userEmail);
    if (!resp.isSuccess) {
      addToast(toastType.error, resp.error!);
    } else {
      setIsResetPassword(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Flex width={[1]} height={["100"]} maxHeight={["100vh"]} maxWidth={customTheme.maxWidth[0]} position={"relative"} alignItems='center' marginTop={["100px", 0]} justifyContent={"left"}>
        {!isResetPassword ? (
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={["normal", "center"]}
            minHeight={"calc(100vh - 200px)"}
            width={[1, 1, 0.5]}
            padding={["40px 28px 40px"]}
            $gap={4}
            style={{ boxSizing: "border-box" }}
          >
            <ArrowSvg handleClick={handleBack} widthCustom='s' />
            <Txt width={[1, 0.75, 1]} variant='light' fontSize={[3]} textAlign={"end"}>
              {t(i18Enum.ForgotPsw_Desc_VerifyAccount)}
            </Txt>
            <Flex flexDirection={"column"} width={["70%", "40%", "50%"]} $gap={1}>
              <InputFieldWardrobe
                id={"Email"}
                name={"email"}
                type={"email"}
                autoComplete={"email"}
                placeholder={t(i18Enum.User_UserProfile_Label_Email)}
                spaceProp={{ width: 1, marginTop: [2], padding: ["10px 0"] }}
                value={userEmail}
                onChange={(e) => handleChange(e)}
                onBlur={() => formik.setFieldTouched("Email", true)}
              />
              <Button variant='login' onClick={getResetPswForm}>
                {loading ? <DotsLoader customHeight='22px' colors={customTheme.colors.secondaryBase} /> : t(i18Enum.Register_GetCodeButton)}
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Box width={[1]} height={["100vh"]} maxWidth={customTheme.maxWidth[0]}>
            <Form as='form' flexDirection={"column"} onSubmit={formik.handleSubmit} width={[1]} alignItems={"start"}>
              <Flex flexDirection={"column"} alignItems={"start"} width={[1, 1, 0.7, 0.4]} padding={["0 28px"]} marginTop={"59px"} $gap={1.5}>
                <ArrowSvg handleClick={handleBack} widthCustom='s' />
                <Txt as='legend' width={[1]} fontSize={[4]} variant='light' textAlign={"left"}>
                  {t(i18Enum.ForgotPsw_Desc_ChangePassword)}
                </Txt>
                <Flex width={[1]} flexWrap={"wrap"}>
                  <InputFieldWardrobe
                    {...formik.getFieldProps("Code")}
                    id={"Code"}
                    name={"Code"}
                    type="text"
                    spaceProp={{ width: 1, marginTop: [2], padding: ["10px 0"] }}
                    placeholder={t(i18Enum.Register_Label_ValidationCode)}
                    value={formik.values.Code}
                    label={t(i18Enum.Register_Label_ValidationCode)}
                    onChange={formik.handleChange}
                    onBlur={() => formik.setFieldTouched("Code", true)}
                    error={formik.errors.Code}

                  />
                  <InputFieldWardrobe
                    {...formik.getFieldProps("Password")}
                    id={"Password"}
                    name={"Password"}
                    spaceProp={{ width: 1, marginTop: [2], padding: ["10px 0"] }}
                    placeholder={t(i18Enum.Login_Label_NewPassword)}
                    value={formik.values.Password}
                    label={t(i18Enum.Login_Label_NewPassword)}
                    onChange={formik.handleChange}
                    isPrivate={true}
                    onBlur={() => formik.setFieldTouched("Password", true)}
                    error={formik.errors.Password}
                  />

                  <InputFieldWardrobe
                    {...formik.getFieldProps("PasswordConfirmation")}
                    id={"PasswordConfirmation"}
                    name={"PasswordConfirmation"}
                    spaceProp={{ width: 1, marginTop: [2], padding: ["10px 0"] }}
                    placeholder={t(i18Enum.Login_PasswordConfirmation)}
                    value={formik.values.PasswordConfirmation}
                    label={t(i18Enum.Login_Label_NewPassword)}
                    onChange={formik.handleChange}
                    isPrivate={true}
                    onBlur={() => formik.setFieldTouched("PasswordConfirmation", true)}
                    error={formik.errors.PasswordConfirmation}
                  />
                </Flex>
                <Flex width={[1]} justifyContent={"end"}>
                  <Button variant='login' type='submit' marginTop={[2]}>
                    {loading ? <DotsLoader customHeight='22px' colors={customTheme.colors.secondaryBase} /> : t(i18Enum.ForgotPsw_ChangeButton)}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          </Box>
        )}
      </Flex>
      <BackgroundVideo video='first' variant='left' width={[1, 0.6]} height={["100vh"]} top={0} />
    </>
  );
};
