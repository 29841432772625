import { useContext, useEffect } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import * as Yup from "yup";
import Flex from "../primitives/Flex";
import TextareaField from "components/molecules/TextareaField";
import { useFormik } from "formik";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export interface ProfileInfo {
  GivenName?: string;
  FamilyName?: string;
  Username?: string;
  ShortDescription?: string;
  FacebookUrl?: string;
  InstagramUrl?: string;
  TikTokUrl?: string;
  TwitterUrl?: string;
  YouTubeUrl?: string;
  PinterestUrl?: string;
}

const initialProfileInfo: ProfileInfo = {
  GivenName: "",
  FamilyName: "",
  Username: "",
  ShortDescription: "",
  FacebookUrl: "",
  InstagramUrl: "",
  TikTokUrl: "",
  TwitterUrl: "",
  YouTubeUrl: "",
  PinterestUrl: "",
};

type FormField = {
  id: keyof ProfileInfo;
  placeholder: string;
};

type ProfileInfoSchemaObject = {
  [key in keyof ProfileInfo]: Yup.Schema<any>;
};
interface ProfileInfoFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: ProfileInfo) => void;
  onFieldChange: () => void;
  isFormValid: (value: boolean) => void;
  isPrivate: boolean | undefined;
  data: ProfileInfo;
}

function ProfileInformationForm({ onSubmit, onFieldChange, isFormValid, isPrivate, data, ...props }: ProfileInfoFormProps) {
  const { t } = useTranslation();

  const { addToast } = useContext(AppGeneralContext);

  const formPersonalInfoSchema = Yup.object().shape<ProfileInfoSchemaObject>({
    GivenName: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    FamilyName: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    Username: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    ShortDescription: Yup.string().nullable(),
    FacebookUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    InstagramUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    TikTokUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    TwitterUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    YouTubeUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    PinterestUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
  });

  const formFields: FormField[] = [
    { id: "GivenName", placeholder: t(i18Enum.User_UserProfile_Label_GivenName) },
    { id: "FamilyName", placeholder: t(i18Enum.User_UserProfile_Label_FamilyName) },
    { id: "Username", placeholder: t(i18Enum.User_UserProfile_Label_Username) },
    { id: "ShortDescription", placeholder: t(i18Enum.User_UserProfile_Label_ShortDescription) },
    { id: "FacebookUrl", placeholder: t(i18Enum.User_UserProfile_Label_Facebook) },
    { id: "InstagramUrl", placeholder: t(i18Enum.User_UserProfile_Label_Instagram) },
    { id: "TikTokUrl", placeholder: t(i18Enum.User_UserProfile_Label_Tiktok) },
    { id: "TwitterUrl", placeholder: t(i18Enum.User_UserProfile_Label_Twitter) },
    { id: "YouTubeUrl", placeholder: t(i18Enum.User_UserProfile_Label_Youtube) },
    { id: "PinterestUrl", placeholder: t(i18Enum.User_UserProfile_Label_Pinterest) },
  ];

  const formik = useFormik<ProfileInfo>({
    initialValues: data ? data : initialProfileInfo,
    validationSchema: formPersonalInfoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.GivenName && values.FamilyName && values.Username) {
        onSubmit(values);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    isFormValid(formik.isValid);
  }, [formik.isValid]);

  useEffect(() => {
    formik.handleSubmit();
  }, [
    formik.values.FacebookUrl,
    formik.values.FamilyName,
    formik.values.GivenName,
    formik.values.InstagramUrl,
    formik.values.PinterestUrl,
    formik.values.ShortDescription,
    formik.values.TikTokUrl,
    formik.values.TwitterUrl,
    formik.values.Username,
    formik.values.YouTubeUrl,
  ]);

  return (
    <Flex as='form' width={[1]} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} $gap={0.5} {...props}>
      <Flex width={[1, 1, "500px"]} flexDirection={"column"} maxWidth={"500px"} marginBottom={[3]}>
        {formFields.map((field) =>
          field.id === "ShortDescription" ? (
            <TextareaField
              {...formik.getFieldProps(field.id)}
              key={field.id}
              name='ShortDescription'
              spaceProp={{ width: [1], marginTop: 26, fontSize: "10px" }}
              variant='description'
              id={field.id}
              placeholder={field.placeholder}
              isPrivate={isPrivate}
              value={formik.values[field.id]}
              onChange={(e) => {
                formik.handleChange(e);
                onFieldChange();
              }}
              onBlur={() => formik.setFieldTouched(field.id, true)}
              error={formik.errors[field.id] ? formik.errors[field.id] : undefined}
            />
          ) : (
            <InputFieldWardrobe
              {...formik.getFieldProps(field.id)}
              key={field.id}
              name={field.id}
              id={field.id}
              placeholder={field.placeholder}
              label={field.id}
              autoComplete={field.id === "GivenName" ? "given-name" : field.id === "FamilyName" ? "family-name" : "off"}
              spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
              onChange={(e) => {
                formik.handleChange(e);
                onFieldChange();
              }}
              onBlur={() => formik.setFieldTouched(field.id, true)}
              value={formik.values[field.id]}
              error={formik.errors[field.id] ? formik.errors[field.id] : undefined}
            />
          )
        )}
      </Flex>
    </Flex>
  );
}

export default ProfileInformationForm;
