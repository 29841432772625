import styled from "styled-components";
import {
  BorderProps,
  ColorProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  compose,
  space,
  variant,
  width,
  color,
  height,
  MinWidthProps,
  minWidth,
  MaxWidthProps,
  maxWidth,
  TypographyProps,
  typography,
  border,
  size,
  SizeProps
} from "styled-system";
export interface ButtonProps {
  variant?:
  | "primary"
  | "secondary"
  | "addToCart"
  | "buyNow"
  | "save"
  | "success"
  | "danger"
  | "warning"
  | "switcher"
  | "switcherGenre"
  | "login"
  | "register"
  | "social"
  | "option"
  | "optionGray"
  | "optionSuccess"
  | "cancel"
  | "follow"
  | "defollow"
  | "unStiled"
  | "promo";
  widthCustom?: "s" | "m";
  selected?: boolean;
  noHover?: boolean
}

const widthMapping = {
  s: "71px",
  m: "93px",
};

const Button = styled.button<ButtonProps & SizeProps & ColorProps & SpaceProps & WidthProps & HeightProps & MinWidthProps & MaxWidthProps & TypographyProps & BorderProps>`
  max-width: ${(props) => (props.widthCustom ? widthMapping[props.widthCustom!] || "90px" : undefined)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 20px;
  border: ${(props) => props.border || "none"};
  width: auto;
 

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:disabled:hover {
    opacity: 0.5;
    cursor: default;
  }
  &:focus-visible {
    outline: none;
  }

  ${(props) =>
    compose(
      variant({
        variants: {
          primary: {
            backgroundColor: props.theme.colors.primaryBgActive,
            color: props.theme.colors.primaryTextContrast,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
            },
          },
          secondary: {
            backgroundColor: props.theme.colors.secondaryBg,
            color: props.theme.colors.thirdText,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            minWidth: "150px",
            maxHeight: "30px",
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
              color: props.theme.colors.secondaryBg,
            },
          },
          addToCart: {
            border: "1px solid",
            width: "100%",
            borderRadius: "unset",
            backgroundColor: props.theme.colors.secondaryBg,
            color: props.theme.colors.thirdBase,
            fill: props.theme.colors.thirdBase,
            borderColor: props.theme.colors.thirdBase,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBase,
              color: props.theme.colors.secondaryBase,
              borderColor: props.theme.colors.primaryBase,
            },
            "&:hover > svg > path": {
              fill: props.theme.colors.secondaryBase,
            }
          },
          buyNow: {
            border: "1px solid",
            cursor: "pointer",
            width: "100%",
            borderRadius: "unset",
            backgroundColor: props.theme.colors.thirdBase,
            color: props.theme.colors.secondaryBase,
            borderColor: props.theme.colors.thirdBase,
            "& > svg > path": {
              fill: props.theme.colors.secondaryBase,
            },
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBase,
              color: props.theme.colors.secondaryBase,
              borderColor: props.theme.colors.primaryBase,
            },
            "&:hover > svg > path": {
              fill: props.theme.colors.secondaryBase,
            }
          },
          save: {
            width: "100%",
            borderRadius: "unset",
            backgroundColor: props.theme.colors.primaryBase,
            color: props.theme.colors.secondaryBg,
            border: "none",
            "&:hover": {
              backgroundColor: props.theme.colors.thirdBg,
              color: props.theme.colors.secondaryBase,
            },
          },
          danger: {
            backgroundColor: props.theme.colors.dangerBg,
            color: props.theme.colors.secondaryText,
            fontFamily: "BauProMedium",
            "&:hover": {},
          },
          warning: {
            backgroundColor: props.theme.colors.warningSolid,
            color: props.theme.colors.warningTextContrast,
            active: props.theme.colors.warningBgActive,
            borderColor: props.theme.colors.warningBorder,
            "&:hover": {
              backgroundColor: props.theme.colors.warningSolidHover,
            },
          },

          login: {
            minWidth: "160px",
            backgroundColor: props.theme.colors.primaryBg,
            color: props.theme.colors.secondaryBase,
            fontFamily: "BauProMedium",
            padding: 10,
            border: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBgHover,
            },
            "&:disabled": {
              pointerEvents: "none",
              opacity: 0.4,
            },
          },
          register: {
            minWidth: "160px",
            backgroundColor: props.theme.colors.grayBase,
            color: props.theme.colors.thirdText,
            fontFamily: "BauProMedium",
            padding: "10px 22px",
            boxSizing: "content-box",
            border: "none",
            "&:hover": {
              backgroundColor: props.theme.colors.grayBgHover,
              color: props.theme.colors.secondaryText,
            },
            "&:disabled": {
              opacity: 0.4,
            },
          },
          social: {
            backgroundColor: props.theme.colors.primaryBase,
            color: props.theme.colors.textColor,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBorder,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
              borderColor: props.theme.colors.primaryBorderHover,
              color: props.theme.colors.primaryBase,
            },
          },
          option: {
            minWidth: "80px",
            borderRadius: "30px",
            backgroundColor: props.theme.colors.primaryBgActive,
            color: props.theme.colors.secondaryBase,
            padding: props.padding ?? "0.5em 1em",
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            "&:hover": {
              backgroundColor: !props.noHover ? props.theme.colors.primaryBgActive : null,
            },
          },
          optionGray: {
            backgroundColor: props.theme.colors.gray12,
            color: props.theme.colors.thirdBase,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.gray12,
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBase,
              color: props.theme.colors.secondaryBase,
            },
          },
          optionSuccess: {
            backgroundColor: props.theme.colors.primaryBase,
            color: props.theme.colors.secondaryBase,
            active: props.theme.colors.primaryBgActive,
            borderColor: props.theme.colors.primaryBase,
          },
          switcher: {
            width: "unset",
            padding: props.padding ?? "0.5em 1em",
            borderRadius: "30em",
            backgroundColor: props.selected ? props.theme.colors.thirdBase : props.theme.colors.primaryBgActive,
            color: props.theme.colors.secondaryBase,
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            transition: "background 0.2s ease",
            "&:hover": {},
          },
          switcherGenre: {
            minWidth: "80px",
            borderRadius: "30em",
            backgroundColor: props.selected ? props.theme.colors.primaryBg : props.theme.colors.grayBase,
            color: props.selected ? props.theme.colors.secondaryText : props.theme.colors.thirdText,
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            padding: props.padding ?? "0.5em 1em",
            transition: "background 0.2s ease",
            "&:hover": {},
          },
          cancel: {
            borderRadius: "30px",
            backgroundColor: props.theme.colors.secondaryBase,
            color: props.theme.colors.thirdText,
            border: "1px solid",
            borderColor: props.theme.colors.thirdBase,
            fontFamily: "BauProMedium",
            fontSize: "0.85714em",
            height: "34px",
            paddingTop: "2px",
            "&:hover": {},
          },
          success: {
            backgroundColor: props.theme.colors.primaryBase,
            color: props.theme.colors.secondaryText,
            fontFamily: "BauProMedium",
            fontSize: "0.71429rem",
            height: "34px",
            paddingTop: "2px",
            "&:hover": {},
          },
          follow: {
            borderRadius: "30px",
            backgroundColor: props.theme.colors.secondaryBase,
            color: props.theme.colors.thirdBase,
            boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            cursor: "pointer",
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBgActive,
            },
          },

          defollow: {
            borderRadius: "30px",
            backgroundColor: props.theme.colors.primaryBgActive,
            color: props.theme.colors.secondaryBase,
            boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            cursor: "pointer",
            "&:hover": {
              backgroundColor: props.theme.colors.primaryBgActive,
            },
          },
          unStiled: {
            all: "unset",
          },
          promo: {
            width: "unset",
            padding: props.padding ?? "0.5em 1em",
            borderRadius: "30em",
            backgroundColor: props.selected ? props.theme.colors.primaryBg : props.theme.colors.yellow,
            color: props.theme.colors.secondaryBase,
            boxShadow: props.theme.colors.yellowShadow,
            border: "none",
            fontFamily: "BauPro",
            fontSize: [2],
            transition: "background 0.2s ease",
            "&:hover": {},
          },
        },
      }),
      size,
      minWidth,
      width,
      space,
      color,
      height,
      typography,
      typography,
      border,
      maxWidth
    )}

  /* Setting pointer for all child elements */
  & > * {
    cursor: pointer;
  }
`;

export default Button;
