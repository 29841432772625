import { put, race, select, takeEvery, take, takeLatest, call } from "redux-saga/effects";
import {
  UserType,
  GET_USER,
  UserPreference,
  UserShippingAddress,
  GET_USER_SHIPPING_ADDRESSES,
  CREATE_SHIPPING_ADDRESS,
  UPDATE_USER_SHIPPING_ADDRESS,
  SET_DEFAULT_USER_SHIPPING_ADDRESS,
  SEND_USER_PREFERENCE,
  EDIT_USER,
  DELETE_USER,
  SWITCH_PRIVATE_ACCOUNT,
  CREATE_BILLING_ADDRESS,
  UPDATE_USER_BILLING_ADDRESS,
  SET_DEFAULT_USER_BILLING_ADDRESS,
  GET_WHISLIST,
  UPDATE_WHISLIST,
  GET_ORDER_PAGE,
  OrderDTO,
  VariantorderCardDTO,
  OrderType,
  DELETE_ORDER,
  DELETE_ADDRESS,
  ListOrdersResponse,
  REFRESH_STRIPE_URL,
} from "./UserTypes";
import {
  getUserErrorAction,
  getUserAddressesAction,
  getUserSuccessAction,
  manageUserShippingAddressErrorAction,
  manageUserShippingAddressSuccessAction,
  sendUserPreferenceErrorAction,
  sendUserPreferenceSuccessAction,
  manageUserBillingAddressSuccessAction,
  manageUserBillingAddressErrorAction,
  editUserErrorAction,
  deleteUserActionError,
  deleteUserActionSuccess,
  switchPrivateAccountErrorAction,
  getWishListErrorAction,
  getWishListSuccessAction,
  getOrdersErrorAction,
  getOrdersSuccessAction,
  userSlice,
  deleteOrderErrorAction,
  getOrdersAction,
  deleteAddressErrorAction,
  refreshStripeUrlErrorAction,
} from "./userSlice";
import { ApiSimpleResponse, BaseResponse, CoreResponse } from "../../../api/types/responses";
import {
  createShippingAddressCall,
  updateShippingAddressCall,
  deleteAddressCall,
  gerUserAddressListCall,
  setDefaultAddressCall,
  postUserPreferenceCall,
  getUserCall,
  postEditUserCall,
  deleteUserCall,
  switchPrivateAccountCall,
  createBillingAddressCall,
  updateBillingAddressCall,
  getUserWishListCall,
  getOrdersCall,
  deleteOrderByIdCall,
} from "../../../api/userCalls/userCalls";
import { PayloadAction } from "@reduxjs/toolkit";
import { BaseIdRequest } from "api/types/requests";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { CreateUpdateBillingAddressRequest, CreateUpdateShippingAddressRequest } from "api/types/requests/shippingAddressRequest";
import { removeAuthAction } from "../auth/authSlice";
import { ProductType } from "../product/ProductsTypes";
import { stripeCall } from "api/cartCalls/basketCall";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle, toastSuccessStyle, toastWarningStyle } from "utils/const/const";

function* getUserSaga() {
  //chiamata get user
  const response: ApiSimpleResponse<BaseResponse<UserType>> = yield getUserCall();
  console.log("", response);
  if (!response.isSuccess || !response.response || !response.response.Data) {
    yield put(getUserErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  // yield put(putUserPreferenceAction(UserToUserPreference(response.response.Data)))
  const data = response.response.Data;
  yield put(getUserSuccessAction(data));
  //chiamata userCard (vecchie API, serve per popolare altri dati del profilo)
  // const cardResponse: ApiSimpleResponse<UserCard> = yield getUserCardByCodeCall(response.response?.Code ?? "")
  // if (!cardResponse.isSuccess || !cardResponse.response) {
  //   yield put(getUserCardErrorAction(cardresponse.response?.ErrorMessage  ?? ''));
  //   return
  // }
  // yield put(getUserCardSuccessAction(cardResponse.response));
  //chiamata userPreference
  // const preferenceResponse: ApiSimpleResponse<UserPreference> = yield getUserPreferenceCall()
  // if (!preferenceResponse.isSuccess || !preferenceResponse.response) {
  //   yield put(getUserPreferenceErrorAction(preferenceresponse.response?.ErrorMessage  ?? ''));
  //   return
  // }
  // yield put(getUserPreferenceSuccessAction(preferenceResponse.response));
  // return
}

function* editUserSaga({ payload }: PayloadAction<UserType>) {
  const res: ApiSimpleResponse<BaseResponse<UserType>> = yield postEditUserCall(payload);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(editUserErrorAction(res.error ?? ""));
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }
  yield put(getUserSuccessAction(res.response.Data));
  toast.success(t(i18Enum.User_UserProfile_Updated), toastSuccessStyle);
}

function* deleteUserSaga({ payload }: PayloadAction<UserType>) {
  const res: ApiSimpleResponse<CoreResponse> = yield deleteUserCall();
  if (!res.isSuccess) {
    yield put(deleteUserActionError(res.error ?? ""));
    toast.error(`${t(i18Enum.Error_Occurred)}: ${res.error}`, toastErrorStyle);
    return;
  }
  yield put(deleteUserActionSuccess());
  yield put(removeAuthAction());

  toast.error(t(i18Enum.User_UserProfile_Deleted), toastErrorStyle);
}

function* sendUserPreferenceSaga() {
  const preferences: UserPreference | undefined = yield select((state: RootState) => {
    console.log({ state });
    return state.user.userPreference.data;
  });
  if (!preferences) {
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }

  const res: ApiSimpleResponse<BaseResponse<UserType>> = yield postUserPreferenceCall(preferences);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(sendUserPreferenceErrorAction(res.error ?? ""));
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }
  yield put(sendUserPreferenceSuccessAction(res.response.Data));
  toast.success(t(i18Enum.User_UserProfile_PreferencesSaved), toastSuccessStyle);
}

function* switchPrivateAccountSaga() {
  const res: ApiSimpleResponse<BaseResponse<UserType>> = yield switchPrivateAccountCall();
  if (!res.isSuccess || !res.response?.Data) {
    yield put(switchPrivateAccountErrorAction(res.error ?? ""));
    toast.error(`${t(i18Enum.Error_Occurred)}: ${res.error}`, toastErrorStyle);
    return;
  }
  yield put(getUserSuccessAction(res.response.Data));
  if (res.response.Data.IsPrivate) {
    toast.warning(t(i18Enum.User_UserProfile_VisibilityMessage_Private), toastWarningStyle);
  } else {
    toast.success(t(i18Enum.User_UserProfile_VisibilityMessage_Public), toastSuccessStyle);
  }
}
/*SHIPPING ADDRESS SAGAS*/
function* createShippingAddressSaga({ payload }: PayloadAction<CreateUpdateShippingAddressRequest>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress>> = yield createShippingAddressCall(payload);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserShippingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  toast.success(t(i18Enum.User_Shipping_ShippingAddress_Create), toastSuccessStyle);
  yield put(getUserAddressesAction({ isBilling: false, addressId: response.response.Data?.Id }));
}

function* updateShippingAddressSaga({ payload }: PayloadAction<BaseIdRequest<CreateUpdateShippingAddressRequest>>) {
  const response: ApiSimpleResponse<UserShippingAddress> = yield updateShippingAddressCall(payload.id, payload.params);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserShippingAddressErrorAction(response.error ?? t(i18Enum.Error_Occurred)));

    return;
  }
  toast.success(t(i18Enum.User_Shipping_ShippingAddress_Updated), toastSuccessStyle);
  yield put(getUserAddressesAction({ isBilling: false }));
}

function* deleteShippingAddressSaga({ payload: shippingAddressId }: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield deleteAddressCall(shippingAddressId);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserShippingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(getUserAddressesAction({ isBilling: false }));
}

function* setDefaultShippingAddressSaga({ payload: shippingAddressId }: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield setDefaultAddressCall(shippingAddressId);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserShippingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(getUserAddressesAction({ isBilling: false }));
}

/*BILLING ADDRESS SAGAS*/
function* createBillingAddressSaga({ payload }: PayloadAction<CreateUpdateBillingAddressRequest>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress>> = yield createBillingAddressCall(payload);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserBillingAddressErrorAction(response.error ?? ""));
    return;
  }
  toast.success(t(i18Enum.User_Shipping_BillingAddress_Create), toastSuccessStyle);
  yield put(getUserAddressesAction({ isBilling: true, addressId: response.response.Data?.Id }));
}

function* updateBillingAddressSaga({ payload }: PayloadAction<BaseIdRequest<CreateUpdateBillingAddressRequest>>) {
  const response: ApiSimpleResponse<UserShippingAddress> = yield updateBillingAddressCall(payload.id, payload.params);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserBillingAddressErrorAction(response.error ?? t(i18Enum.Error_Occurred)));

    return;
  }
  toast.success(t(i18Enum.User_Shipping_BillingAddress_Updated), toastSuccessStyle);
  yield put(getOrdersAction());
}

function* deleteBillingAddressSaga({ payload: billingAddressId }: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield deleteAddressCall(billingAddressId);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserBillingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(getUserAddressesAction({ isBilling: true }));
}

function* setDefaultBillingAddressSaga({ payload: billingAddressId }: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield setDefaultAddressCall(billingAddressId);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserBillingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(getUserAddressesAction({ isBilling: true }));
}

/* SHARED ADDRESS SAGA*/
function* getAddressSaga({ payload: { isBilling, addressId } }: PayloadAction<{ isBilling: boolean; addressId?: string }>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress[]>> = yield gerUserAddressListCall(isBilling, addressId);

  if (!isBilling) {
    if (!response.isSuccess || !response.response || !response.response.Data) {
      yield put(manageUserShippingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
      return;
    }
    yield put(manageUserShippingAddressSuccessAction(response.response.Data));
    return;
  } else {
    if (!response.isSuccess || !response.response || !response.response.Data) {
      yield put(manageUserBillingAddressErrorAction(response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
      return;
    }
    yield put(manageUserBillingAddressSuccessAction(response.response.Data));
    return;
  }
}

function* deleteAddressSaga({ payload: { isBilling, addressId } }: PayloadAction<{ isBilling: boolean; addressId: string }>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress[]>> = yield deleteAddressCall(addressId);
  console.log("delete", response);

  if (!response.isSuccess || !response.response || !response.response.Data) {
    toast.error(`${t(i18Enum.Error_Occurred)}: ` + response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred), toastErrorStyle);
    yield put(deleteAddressErrorAction({ isBilling: isBilling, error: response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred) }));
    return;
  }

  if (!isBilling) {
    yield put(manageUserShippingAddressSuccessAction(response.response.Data));
  } else {
    yield put(getOrdersAction());
  }
}

function* getWishListSaga() {
  const res: ApiSimpleResponse<BaseResponse<ProductType[]>> = yield getUserWishListCall();
  if (!res.isSuccess || !res.response?.Data) {
    yield put(getWishListErrorAction(res.error ?? t(i18Enum.ErrorPage500_ErrorLabel)));
    toast.error(`${t(i18Enum.Error_Occurred)}: ${res.error}`, toastErrorStyle);
    return;
  }
  yield put(getWishListSuccessAction(res.response.Data));
}

function* getOrdersSaga() {
  yield put(getUserAddressesAction({ isBilling: true }));
  const { success, error } = yield race({
    success: take(userSlice.actions.manageUserBillingAddressSuccessAction.type),
    error: take(userSlice.actions.manageUserBillingAddressErrorAction.type),
  });
  console.log({ success }, { error });
  if (!success) {
    yield put(getOrdersErrorAction(t(i18Enum.Error_Occurred)));
    toast.error(error.payload ?? t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }

  const res: ApiSimpleResponse<BaseResponse<ListOrdersResponse>> = yield getOrdersCall();

  if (!res.isSuccess || !res.response?.Data) {

    yield put(getOrdersErrorAction(res.error ?? t(i18Enum.Error_Occurred)));
    toast.error(`${t(i18Enum.Error_Occurred)}: ${res.error}`, toastErrorStyle);
    return;
  }

  yield put(getOrdersSuccessAction(res.response.Data));
}

function* refreshStripeUrlSaga({ payload }: PayloadAction< {id: string; action: (url: string) => void} >) {

  if (payload.id === ''){
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return
  }
  const stripe: ApiSimpleResponse<BaseResponse<string>> = yield stripeCall(payload.id);
  if (!stripe.isSuccess || !stripe.response?.Data) {

    toast.error(stripe.response?.ErrorMessage, toastErrorStyle);
    yield put(refreshStripeUrlErrorAction(stripe.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)));
    return;
  }

  payload.action(encodeURIComponent(stripe.response.Data));
  yield call(getOrdersSaga);
}

function* deleteOrderSaga({ payload: id }: PayloadAction<string>) {
  const res: ApiSimpleResponse<BaseResponse<OrderType[]>> = yield deleteOrderByIdCall(id);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(deleteOrderErrorAction(res.error ?? t(i18Enum.Error_Occurred)));
    toast.error(`${t(i18Enum.Error_Occurred)}: ${res.error}`, toastErrorStyle);
    return;
  }
   yield call(getOrdersSaga)
}

// Generator function
export function* watchGetUser() {
  yield takeLatest(GET_USER, getUserSaga),
  yield takeLatest(EDIT_USER, editUserSaga);
  yield takeLatest(SEND_USER_PREFERENCE, sendUserPreferenceSaga);
  yield takeLatest(SWITCH_PRIVATE_ACCOUNT, switchPrivateAccountSaga);
  yield takeEvery(GET_USER_SHIPPING_ADDRESSES, getAddressSaga);
  yield takeLatest(CREATE_SHIPPING_ADDRESS, createShippingAddressSaga);
  yield takeLatest(UPDATE_USER_SHIPPING_ADDRESS, updateShippingAddressSaga);
  yield takeLatest(GET_ORDER_PAGE, getOrdersSaga);
  yield takeLatest(DELETE_ADDRESS, deleteAddressSaga);
  yield takeLatest(CREATE_BILLING_ADDRESS, createBillingAddressSaga);
  yield takeLatest(UPDATE_USER_BILLING_ADDRESS, updateBillingAddressSaga);
  yield takeLatest(SET_DEFAULT_USER_BILLING_ADDRESS, setDefaultBillingAddressSaga);
  yield takeLatest(SET_DEFAULT_USER_SHIPPING_ADDRESS, setDefaultShippingAddressSaga);
  yield takeLatest(GET_WHISLIST, getWishListSaga);
  yield takeLatest(DELETE_ORDER, deleteOrderSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(REFRESH_STRIPE_URL, refreshStripeUrlSaga);
}
