import { Resource, ResourceKey } from "i18next";
import fallbackLng from '../i18n/types/resources'
import { getAriesCall } from "./baseAriesCall";
import { BaseResponse } from "./types/responses";


export const getTranslations = async (url:string) => {
   const res = await getAriesCall<BaseResponse<Resource>>(url)
       if(res.isSuccess&&res.response?.Data){
        return res.response.Data as ResourceKey
       }else{
            return fallbackLng.IT.translation as ResourceKey
       }
           
        
}
