import Flex from "components/primitives/Flex";
import { StepperFourthPage, StepperLoginFirsPage, StepperLoginSecondPage, StepperThirdPage } from "pages";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/app/hooks";


export enum Step { "first" , "second" ,"third" , "fourth"}

function Stepper() {
  const initialStep: Step = 0;
  const [currentStep, setCurrentStep] = useState<Step>(initialStep);
  const { data: userPreference } = useAppSelector((state) => state.user.userPreference)
  
  const handleCurrentStep = (step: Step) => {
    setCurrentStep(step);
    window.scroll(0,0)
  };

  return (
    <Flex
      as="section"
      flexDirection={"column"}
      alignItems={"center"}
      backgroundColor={"#000"}
      minWidth={"100vw"}
      marginBottom={5}
    >
      {currentStep === Step.first && <StepperLoginFirsPage handleCurrentStep={handleCurrentStep} />}
      {currentStep === Step.second && <StepperLoginSecondPage handleCurrentStep={handleCurrentStep} />}
      {currentStep === Step.third && <StepperThirdPage handleCurrentStep={handleCurrentStep} />}
      {currentStep === Step.fourth && <StepperFourthPage handleCurrentStep={handleCurrentStep} />}
    </Flex>
  );
}

export default Stepper;
