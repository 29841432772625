import { useContext, useEffect } from "react";
import Flex from "components/primitives/Flex";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import Txt from "components/primitives/Txt";
import { CustomThemeContext } from "context/ThemeContext";
import GridCard from "components/organisms/GridCard";
import { useAppSelector } from "redux/app/hooks";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useDispatch } from "react-redux";
import { getWishListAction } from "redux/features/user/userSlice";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

function WishlistPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const { setIsLoading } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const { data: userData } = useAppSelector((state) => state.user.user);
  const { data: wishList, isLoading: wishListIsLoading, errors: wishListError } = useAppSelector((state) => state.user.userWishList);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getWishListAction());
  }, []);

  useEffect(() => {
    setIsLoading(wishListIsLoading);
  }, [wishListIsLoading]);

  return (
    <Flex padding={["5% 0", "5% 0", "0"]} width={[1]} justifyContent={"center"}>
      {userData && (
        <Flex key={userData.Id} flexDirection={"column"} width={[1]} padding={["0 1em", "0 5em"]} $gap={1.2} maxWidth={["none", 1100]}>
          <MenuNavigateProps userData={userData} />
          <Txt fontSize={["18px"]} marginTop={"0.8em"} as={"h3"} variant='light' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
            {t(i18Enum.Wishlist_Title)}
          </Txt>

          <Txt marginTop={0} as={"p"} variant='light' color={colors.thirdText} textAlign={"left"} $textTransform='inherit' fontSize={[3]}>
            {t(i18Enum.Wishlist_Desc)}
          </Txt>
          {/* TODO creare productList per gridCard */}
          {wishList && <GridCard isHomeCard={false} products={wishList ?? []} />}
        </Flex>
      )}
    </Flex>
  );
}

export default WishlistPage;
