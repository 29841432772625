import Box from "components/primitives/Box";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import React from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface IErrorComponent {
  textToDisplay?: string;
  errors: string | undefined;
  onClickHandler: () => void;
}

export const ErrorComponent = ({ textToDisplay, errors, onClickHandler }: IErrorComponent) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box backgroundColor={"#ff9d00"} width={"100%"} marginBottom={"100px"} borderRadius={"16px"} padding={"16px"} maxWidth={theme.maxWidth}>
      <Txt as='h3' textAlign={["center"]} color='black' marginTop={"5px"}>
        ⚠️
      </Txt>

      <Txt as='h5' textAlign={["center"]} color='black'>
        {textToDisplay ?? t(i18Enum.ErrorPage500_ErrorLabel)}
      </Txt>
      <Txt as='h6' textAlign={["center"]} color='black'>
        {errors ?? t(i18Enum.ErrorPage_PleaseRetry)}
      </Txt>
      <Flex flexDirection={["column", "row"]} justifyContent={"center"} alignItems={["center", "end"]} padding={"20px 0 10px 0"}>
        <Button variant='secondary' width={"fit-content"} padding={"0.5em 1.2em"} onClick={onClickHandler} marginRight={["0px", "10px"]} marginBottom={["10px", "0px"]}>
          {t(i18Enum.ErrorPage_Retry)}
        </Button>
      </Flex>
    </Box>
  );
};
