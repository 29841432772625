import { useState, useEffect } from "react";
import PrimitiveImage from "../primitives/Image";

interface IProgressiveImg extends React.ComponentPropsWithoutRef<"img"> {
  placeholderSrc: string;
}
const ProgressiveImg = ({ placeholderSrc = "", ...props }: IProgressiveImg) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || props.src);
  const [variant, setVariant] = useState<"loaded" | "loading">("loading");

  useEffect(() => {
    const img = new Image();
    img.src = props.src ?? "";
    img.onload = () => {
      setImgSrc(props.src);
      setVariant("loaded");
    };
  }, [props.src]);

  return <PrimitiveImage variant={variant} {...props} src={imgSrc} alt={props.alt || ""} />;
};
export default ProgressiveImg;
