// Define the user type
import { FileUploadDTO } from "utils/generalTypes/shared";
import { ExtendedIState, IState } from "../SharedStateType";
import { ProductType, Tags, VariantDTO } from "../product/ProductsTypes";
import { StatusOrder } from "components/molecules/Order";
import { IdOptionType } from "components/primitives/Select";

export type VerifyUserType = {
  Id: string;
  Code: string;
};

export type UserType = {
  Id: string;
  Bio?: string;
  BirthDate?: Date;
  Email: string;
  EmailVerified?: boolean;
  FamilyName: string;
  FavoriteColors?: number[];
  FollowersCount?: number;
  FollowingCount?: number;
  FreeTime?: any;
  Gender?: number;
  GivenName?: string;
  Interests?: string[];
  IsActive?: boolean;
  IsInfluencer?: boolean;
  Location?: any;
  Name?: string;
  Picture?: FileUploadDTO;
  PictureUrl?: string;
  ShoeSize?: number;
  FavoriteTrips?: string[];
  Website?: string;
  Weight?: number;
  CoverPicture: FileUploadDTO;
  CoverPictureUrl?: string;
  PictureId?: string;
  CoverPictureId?: string;
  LanguageKey?: string;
  IsNewsletterProfile?: boolean;
  AddedDate?: Date;
  Code?: string;
  SocialLinks?: string[];
  ShortDescription?: string;
  CurrencyKey?: string;
  FavoriteTopics?: string[];
  Topics?: string[];
  FavoriteStyles?: string[];
  Preferences?: number[];
  FavoriteHobbies?: number[];
  FavoriteSocials?: number[];
  FavoriteSports?: number[];
  FavoriteVibes?: number[];
  GeoLocationLatitude?: number;
  GeoLocationLongitude?: number;
  Height?: number;
  PantSize?: number;
  ShirtSize?: number;
  EditorChoice?: boolean;
  PublishDate?: Date;
  LastActivityDate?: Date;
  TotalRating?: number;
  Votes?: number;
  IsMarektingPolicyAgree?: boolean;
  IsOnboardingCompleted?: boolean;
  IsPrivacyPolicyAgree?: boolean;
  IsProfilingPolicyAgree?: boolean;
  IsTermsOfUseAgree?: boolean;
  Password?: string;
  IsPrivate?: boolean;
  Username?: string;
  FacebookUrl?: string;
  InstagramUrl?: string;
  TikTokUrl?: string;
  TwitterUrl?: string;
  YouTubeUrl?: string;
  PinterestUrl?: string;
  NotificationWishlistOnStockEnabled?: boolean;
  NotificationOrderShippedEnabled?: boolean;
  NotificationFollowingBirthdayEnabled?: boolean;
  CompiledPreferences?: boolean;
  CoverPictureNavigation?: any;
  CurrencyKeyNavigation?: any;
  LanguageKeyNavigation?: any;
  PictureNavigation?: any;
  JoinInvitationToNavigation?: any;
  Articles?: any[];
  EmailConfirmationInvitationReferrers?: any[];
  EmailConfirmationInvitationToNavigations?: any[];
  FollowUserToShops?: any[];
  FollowUserToUsers?: any[];
  JobApplications?: any[];
  Jobs?: any[];
  JoinInvitationFromNavigations?: any[];
  LikeUserToArticleComments?: any[];
  LikeUserToArticles?: any[];
  LikeUserToOutfitComments?: any[];
  LikeUserToOutfits?: any[];
  LikeUserToProducts?: any[];
  LikeUserToShopComments?: any[];
  LikeUserToShops?: any[];
  LikeUserToStories?: any[];
  LikeUserToUserComments?: any[];
  LikeUserToWardrobeItemComments?: any[];
  LikeUserToWardrobeItems?: any[];
  Newsletters?: any[];
  Orders?: any[];
  Outfits?: any[];
  PollOptions?: any[];
  Polls?: any[];
  ShoppingBasketId?: string;
  ShopOwnerInvitationFromNavigations?: any[];
  ShopOwnerInvitationToNavigations?: any[];
  Shops?: any[];
  Stories?: any[];
  UserBounties?: any[];
  UserClaims?: any[];
  UserComments?: any[];
  UserShippingAddresses?: any[];
  ViewUserToStories?: any[];
  WardrobeItems?: any[];
  WishlistUserToProducts?: any[];
  WishlistUserToWardrobeItems?: any[];
};

export type UserPreference = {
  GivenName?: string;
  FamilyName?: string;
  BirthDate?: Date;
  IsPrivate?: boolean;
  Gender?: number;
  Picture?: FileUploadDTO;
  PictureUrl?: string;
  Height?: number;
  Weight?: number;
  FavoriteColors?: number[];
  FavoriteSocials?: number[];
  FavoriteSports?: number[];
  FavoriteVibes?: number[];
  FavoriteHobbies?: number[];
};
///***************************************REDUX PART***************************/

export type UserShippingAddress = {
  Id?: string;
  UserId?: string;
  Name?: string;
  AddressLine1?: string;
  AddressLine2?: string;
  City?: string;
  ZipCode?: string;
  State?: string;
  PhoneNumber?: string;
  Note?: string;
  IsDefault?: boolean;
  CountryKey?: number;
  CallingCodeKey?: number;
  RegionDto?: IdOptionType;
  Region?: string;
  RegionId?: string;
  ProvinceDto?: IdOptionType;
  Province?: string;
  ProvinceId: string;
  IsBilling?: boolean;
  PI?: string;
  CF?: string;
  LastCreated?: boolean;
};

export type ProvinceDTO = {
  Id: string;
  Label: string;
  Code: string;
  RegionId: string;
  Region: RegionDTO;
};

export type RegionDTO = {
  Id: string;
  Label: string;
  CountryKey: number;
  CountryKeyNavigation: number;
  Provinces: ProvinceDTO[];
};

export type VariantorderCardDTO = {
  Picture: string;
  Id: string;
  Quantity: number;
};

export type OrderType = {
  Id?: string;
  ShopId?: string;
  ShopName?: string;
  Total?: number;
  Products?: VariantorderCardDTO[];
  ItemsTotal?: number;
  Number?: string;
  Status?: number;
  StripeCheckoutUrl?: string;
  ParentNumber: string;
};

export enum PaymentStatus {
  Rejected = -1,
  WaitingForPayment = 0,
  Verified = 1,
}
export type ListOrdersResponse = {
  OrdersList: OrderType[];
  OrdersParentList?: OrderType[];
};

export type OrderDTO = {
  Id?: string;
  ShopId?: string;
  Number?: string;
  Status: StatusOrder;
  AddedDate?: string;
  UpdatedDate?: string;
  Email?: string;
  PaymentDate?: string;
  PaymentStatus?: PaymentStatus;
  TransactionId?: string;
  ShippingAddress?: UserShippingAddress;
  BillingAddress?: UserShippingAddress;
  ShippingAddressessList?: UserShippingAddress[];
  BillingAddressessList?: UserShippingAddress[];
  Shippings?: ShippingDTO[];
  ShippingPrice: number;
  ShopName?: string;
  Total?: number;
  ItemsCount?: number;
  ItemsTotal?: number;
  Canceled?: boolean;
  CanceledDate?: string;
  Orders?: OrderDTO[];
  ParentNumber: string;
};

export type ShippingDTO = {
  Id?: string;
  AddedDate?: string;
  OrderId?: string;
  ShopId?: string;
  Price?: number;
  ShippingOptionAdditionalInformation?: string;
  ShippingOptionId?: string;
  ShippingOptionName?: string;
  Status?: number;
  ShippedDate?: string;
  EstimatedDeliveryDate?: string;
  CustomData?: string;
  ItemCount?: number;
  ProductVariantId?: string;
  ProductVariant?: VariantDTO;
  SelectedTags: Tags[];
};

// The users global state

export type UserStateType = {
  user: IState<UserType>;
  userPreference: IState<UserPreference>;
  userShippingAddresses: ExtendedIState<UserShippingAddress[]>;
  userBillingAddresses: ExtendedIState<UserShippingAddress[]>;
  userWishList: IState<ProductType[]>;
  userOrder: ExtendedIState<OrderType[]>;
  userOrderParent: ExtendedIState<OrderType[]>;
  // Later, we can add other sub-states like:
  // list: IState<UserType[]>;
  // create,
  // update,
  // remove
};

// (1) base per avere accesso al reducer
export const USER = "user";
export type USER = typeof USER; // Typescript line

/* (2) azioni su cui rimarrà in ascolto la saga:
queste sono le rappresentazioni in stringa delle azioni che ci sono nello slice di riferimento
in questo caso dello user. 
Sono SOLO ed UNICAMENTE quelle azioni che servono alla saga, quindi non tutte quelle riportate 
nello slice.

Si formano a coppia, const + type, per garantire che non possano esserci errori.
IMPORTANTISSIMO: sono UNIVOCHE!!! UNA sola costante e un type in tutto il progetto
con quel nome.
*/
export const GET_USER = `${USER}/getUserAction`;
export type GET_USER = typeof GET_USER;

export const EDIT_USER = `${USER}/editUserAction`;
export type EDIT_USER = typeof EDIT_USER;

export const DELETE_USER = `${USER}/deleteUserAction`;
export type DELETE_USER = typeof DELETE_USER;
/*************************** USER PREFERENCE ************************/
export const PUT_USER_PREFERENCE = `${USER}/putUserPreferenceAction`;
export type PUT_USER_PREFERENCE = typeof PUT_USER_PREFERENCE;

export const SEND_USER_PREFERENCE = `${USER}/sendUserPreferenceAction`;
export type SEND_USER_PREFERENCE = typeof SEND_USER_PREFERENCE;
/*************************** PRIVATE ACCOUNT ************************/
export const SWITCH_PRIVATE_ACCOUNT = `${USER}/switchPrivateAccountAction`;
export type SWITCH_PRIVATE_ACCOUNT = typeof SWITCH_PRIVATE_ACCOUNT;

/*************************** USER BILLING ADDRESS ************************/
export const CREATE_SHIPPING_ADDRESS = `${USER}/createUserShippingAddressAction`;
export type CREATE_SHIPPING_ADDRESS = typeof CREATE_SHIPPING_ADDRESS;

export const UPDATE_USER_SHIPPING_ADDRESS = `${USER}/updateUserShippingAddressAction`;
export type UPDATE_USER_SHIPPING_ADDRESS = typeof UPDATE_USER_SHIPPING_ADDRESS;

export const SET_DEFAULT_USER_SHIPPING_ADDRESS = `${USER}/setDefaultUserShippingAddressAction`;
export type SET_DEFAULT_USER_SHIPPING_ADDRESS = typeof SET_DEFAULT_USER_SHIPPING_ADDRESS;

/*************************** SHARED ADDRESS ACTION *************************/
export const GET_USER_SHIPPING_ADDRESSES = `${USER}/getUserAddressesAction`;
export type GET_USER_SHIPPING_ADDRESSES = typeof GET_USER_SHIPPING_ADDRESSES;

export const DELETE_ADDRESS = `${USER}/deleteAddressAction`;
export type DELETE_ADDRESS = typeof DELETE_ADDRESS;

/*************************** USER BILLING ADDRESS ************************/

export const CREATE_BILLING_ADDRESS = `${USER}/createUserBillingAddressAction`;
export type CREATE_BILLING_ADDRESS = typeof CREATE_BILLING_ADDRESS;

export const UPDATE_USER_BILLING_ADDRESS = `${USER}/updateUserBillingAddressAction`;
export type UPDATE_USER_BILLING_ADDRESS = typeof UPDATE_USER_BILLING_ADDRESS;

export const SET_DEFAULT_USER_BILLING_ADDRESS = `${USER}/setDefaultUserBillingAddressAction`;
export type SET_DEFAULT_USER_BILLING_ADDRESS = typeof SET_DEFAULT_USER_BILLING_ADDRESS;

/*************************** USER ORDERS AND BILLING ADDRESS ************************/

export const GET_ORDER_PAGE = `${USER}/getOrdersAction`;
export type GET_ORDER_PAGE = typeof GET_ORDER_PAGE;

export const DELETE_ORDER = `${USER}/deleteOrderAction`;
export type DELETE_ORDER = typeof DELETE_ORDER;

/*************************** USER WISHLIST ************************/

export const GET_WHISLIST = `${USER}/getWishListAction`;
export type GET_WHISLIST = typeof GET_WHISLIST;

export const UPDATE_WHISLIST = `${USER}/updatetWishListAction`;
export type UPDATE_WHISLIST = typeof UPDATE_WHISLIST;

/*************************** REFRESH STRIPE URL ************************/

export const REFRESH_STRIPE_URL = `${USER}/refreshStripeUrlAction`;
export type REFRESH_STRIPE_URL = typeof REFRESH_STRIPE_URL;

export enum ProfileFields {
  fullProfile,
  avatar,
  fullName,
  description,
  social,
  wardrobe,
}
