import React, { useContext, useState } from "react";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import { addressType } from "./AddressCard";
import Txt from "components/primitives/Txt";
import { CustomThemeContext } from "context/ThemeContext";
import Flex from "components/primitives/Flex";
import SelectField from "./SelectField";
import { useDispatch } from "react-redux";
import Button from "components/primitives/Button";
import { useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { PAGE } from "utils/const/pagePath";
import { AddressPageEdit } from "pages/AddressPageEdit";
import { BillingPageEdit } from "pages/BillingPageEdit";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export interface ChangeAddressProps {
  address: UserShippingAddress;
  type: addressType;
  changeAddress: (address: UserShippingAddress | undefined, type:addressType) => void;
  addressesList:UserShippingAddress[],
  disabled:boolean
}

export const ChangeAddress = ({
  address,
  type,
  disabled,
  changeAddress,
  addressesList,
}: ChangeAddressProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { isMobile, openAsideMenu } = useContext(AppGeneralContext);
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const dispatch = useDispatch()
  const [addressSelected, setAddressSelected] = useState<UserShippingAddress | undefined>( address)
  const navigate = useNavigate()
    const { t } = useTranslation();

  function AddNewAddress(){
     if(disabled){
        return
     }

      if(type=== addressType.userAddress){
        if (isMobile) {
            navigate(PAGE.addressEdit.menuPath, { state: { id: undefined } })
          }
          openAsideMenu<string | undefined>(<AddressPageEdit />,  undefined)
         
      }

      if(type=== addressType.userBilling){
        if (isMobile) {
            navigate(PAGE.billing.menuPath, { state: { id: undefined } })
          }
          openAsideMenu<string | undefined>(<BillingPageEdit />,  undefined)
      }

     return

  }


  return isEditView ? (
    <Flex flexDirection={"column"} width={[1]} marginTop={"20px"} paddingBottom={"20px"} borderBottom={"1px solid white"} alignItems={"start"}>
      <Txt padding={"10px 0"} fontFamily={"BauPro"}>
        {type === addressType.userAddress
          ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_ChooseAddress)
          : type === addressType.userBilling
          ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_ChooseBillingAddress)
          : ""}
      </Txt>
      <SelectField<UserShippingAddress>
        zIndex={[1]}
        width={[1]}
        marginTop={"1.2em"}
        id='Addresses'
        placeholder={t(i18Enum.User_Shipping_Label_Addresses)}
        option={addressesList}
        value={addressSelected}
        handleChange={(value) => {
          setAddressSelected(value);
        }}
        handleBlur={() => {}}
        labelExtractor={(x) => (isMobile ? `${x.Name}  |  ${x.AddressLine1} ...` : `${x.Name}  |  ${x.AddressLine1} ${x.AddressLine2} -  ${x.ZipCode} ${x.City}`)}
        valueExtractor={(x) => x.Id ?? ""}
      />

      <Txt width={[1]} padding={"15px 0"} $textTransform='none' fontSize={[3]} textAlign={"right"} variant='link' fontFamily={"BauPro"} color={colors.primaryBase} onClick={AddNewAddress}>
        {type === addressType.userAddress
          ? `+ ${t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddNewAddress)}`
          : type === addressType.userBilling
          ? `+ ${t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddNewBillingAddress)}`
          : ""}
      </Txt>
      <Flex flexDirection={"row"} width={[1]} justifyContent={"end"} flexWrap={"wrap"} alignItems={"center"} padding={["20px 0"]}>
        <Button
          marginRight={[0, "10px"]}
          marginBottom={["20px", 0]}
          variant='register'
          padding={"10px 20px"}
          onClick={() => {
            setIsEditView(false);
          }}
        >
          {t(i18Enum.Common_Cancel)}
        </Button>
        <Button
          variant='login'
          padding={"10px 20px"}
          disabled={addressSelected === undefined || addressSelected.Id === address.Id || disabled ? true : false}
          onClick={() => {
            changeAddress(addressSelected, type);
            setIsEditView(false);
          }}
        >
          {t(i18Enum.Common_Save)}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <Txt
      width={[1]}
      padding={"15px 0"}
      $textTransform='none'
      fontSize={[1]}
      textAlign={"right"}
      variant='link'
      fontFamily={"BauPro"}
      color={colors.primaryBase}
      onClick={() => {
        if (disabled) {
          return;
        }
        setIsEditView(true);
      }}
      opacity={disabled ? 0.3 : 1}
    >
      {type === addressType.userAddress
        ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_ChangeAddress)
        : type === addressType.userBilling
        ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_ChangeBillingAddress)
        : ""}
    </Txt>
  );
};
