import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import ColorGrid from "components/molecules/ColorGrid";
import { useContext, useEffect } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Button from "components/primitives/Button";
import * as Yup from 'yup'
import { useFormik } from "formik";
import Form from "components/primitives/Form";
import Checkbox from "components/primitives/Checkbox";
import { useAppSelector } from "redux/app/hooks";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";


export interface ThirdStepForm {
  colors?: number[],
  vibes?:number[],
  sports?: number[],
  hobbies?:number[]
}

const initialThirdStep:ThirdStepForm = {
  colors:undefined,
  vibes:undefined,
  sports:undefined,
  hobbies:undefined
}



export interface FormField {
  id: number;
  label: string;
  value?:boolean
}

interface StepThirdFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: ThirdStepForm) => void;
}

type FormStepThirdSchemaObject = {
  [key in keyof ThirdStepForm]: Yup.Schema<any>
}

const formStepLoginThirdSchema = Yup.object().shape<FormStepThirdSchemaObject>({
  colors: Yup.array().of(Yup.number()).nullable(),
  vibes: Yup.array().of(Yup.number()).nullable(),
  sports: Yup.array().of(Yup.number()).nullable(),
  hobbies: Yup.array().of(Yup.number()).nullable()
})

function StepThirdForm({ onSubmit, ...props }: StepThirdFormProps) {
  const {isMobile} = useContext(AppGeneralContext);
  const { t } = useTranslation();
  const { data: userPreference } = useAppSelector((state) => state.user.userPreference)


  const formFavoriteVibes: FormField[] = [
    {
      id: 0,
      label: t(i18Enum.DV_Vibes_1),
    },
    {
      id: 1,
      label: t(i18Enum.DV_Vibes_2),
    },
    {
      id: 2,
      label: t(i18Enum.DV_Vibes_3),
    },
    {
      id: 3,
      label: t(i18Enum.DV_Vibes_4),
    },
    {
      id: 4,
      label: t(i18Enum.DV_Vibes_5),
    },
    {
      id: 5,
      label: t(i18Enum.DV_Vibes_6),
    },
    {
      id: 6,
      label: t(i18Enum.DV_Vibes_7),
    },
    {
      id: 7,
      label: t(i18Enum.DV_Vibes_8),
    },
    {
      id: 8,
      label: t(i18Enum.DV_Vibes_9),
    },
    {
      id: 9,
      label: t(i18Enum.DV_Vibes_10),
    },
    {
      id: 10,
      label: t(i18Enum.DV_Vibes_11),
    },
    {
      id: 11,
      label: t(i18Enum.DV_Vibes_12),
    },
    {
      id: 12,
      label: t(i18Enum.DV_Vibes_13),
    },
    {
      id: 13,
      label: t(i18Enum.DV_Vibes_14),
    },
    {
      id: 14,
      label: t(i18Enum.DV_Vibes_15),
    },
    {
      id: 15,
      label: t(i18Enum.DV_Vibes_16),
    },
    {
      id: 16,
      label: t(i18Enum.DV_Vibes_17),
    },
    {
      id: 17,
      label: t(i18Enum.DV_Vibes_18),
    },
  ];

  const formFavoriteSports: FormField[] = [
    {
      id: 0,
      label: t(i18Enum.DV_Sports_1),
    },
    {
      id: 1,
      label: t(i18Enum.DV_Sports_2),
    },
    {
      id: 2,
      label: t(i18Enum.DV_Sports_3),
    },
    {
      id: 3,
      label: t(i18Enum.DV_Sports_4),
    },
    {
      id: 4,
      label: t(i18Enum.DV_Sports_5),
    },
    {
      id: 5,
      label: t(i18Enum.DV_Sports_6),
    },
    {
      id: 6,
      label: t(i18Enum.DV_Sports_7),
    },
    {
      id: 7,
      label: t(i18Enum.DV_Sports_8),
    },
    {
      id: 8,
      label: t(i18Enum.DV_Sports_9),
    },
    {
      id: 9,
      label: t(i18Enum.DV_Sports_10),
    },
    {
      id: 10,
      label: t(i18Enum.DV_Sports_11),
    },
    {
      id: 11,
      label: t(i18Enum.DV_Sports_12),
    },
    // {
    //   id: "otherSports",0
    //   label: "other: ",
    // },
  ];

  const formFavoriteHobbies: FormField[] = [
    {
      id: 0,
      label: t(i18Enum.DV_Hobbies_1),
    },
    {
      id: 1,
      label: t(i18Enum.DV_Hobbies_2),
    },
    {
      id: 2,
      label: t(i18Enum.DV_Hobbies_3),
    },
    {
      id: 3,
      label: t(i18Enum.DV_Hobbies_4),
    },
    {
      id: 4,
      label: t(i18Enum.DV_Hobbies_5),
    },
    {
      id: 5,
      label: t(i18Enum.DV_Hobbies_6),
    },
    {
      id: 6,
      label: t(i18Enum.DV_Hobbies_7),
    },
    {
      id: 7,
      label: t(i18Enum.DV_Hobbies_8)
    },
    // {
    //   id: 9,
    //   label: "other: ",
    // },
  ];

  useEffect(()=>{
    if(userPreference?.FavoriteColors){
      formik.setFieldValue("colors", userPreference?.FavoriteColors)
      formik.setFieldTouched("colors", true , true)
      formik.setFieldError("colors", undefined)
      }
     
      if(userPreference?.FavoriteVibes){
        formik.setFieldValue("vibes", userPreference?.FavoriteVibes)
        formik.setFieldTouched("vibes", true , true)
        formik.setFieldError("vibes", undefined)
        }

      if(userPreference?.FavoriteSports){
        formik.setFieldValue("sports", userPreference?.FavoriteSports)
        formik.setFieldTouched("sports", true , true)
        formik.setFieldError("sports", undefined)
        }

      if(userPreference?.FavoriteHobbies){
        formik.setFieldValue("hobbies", userPreference?.FavoriteHobbies)
        formik.setFieldTouched("hobbies", true , true)
        formik.setFieldError("hobbies", undefined)
        }

  
  },[userPreference, userPreference?.FavoriteColors, userPreference?.FavoriteHobbies, userPreference?.FavoriteSports, userPreference?.FavoriteVibes])

  
  const formik = useFormik<ThirdStepForm>({
    initialValues: initialThirdStep,
    validationSchema: formStepLoginThirdSchema,
    enableReinitialize:true,
    onSubmit: (values, { setSubmitting }) => {
      
      onSubmit(values)
      
      setSubmitting(false)
    }
  })
  
  function addItem(type:"vibes"| "sports" | "hobbies", value:number){
    let oldArr = formik.values[type]!== undefined ?  formik.values[type] : []
    let newArr = oldArr  ? [...oldArr , value]  : [value]
    formik.setFieldValue(type, newArr)
}

function deleteItem(type:"vibes"| "sports" | "hobbies",value:number){
  let oldArr = formik.values[type]!== undefined ?  formik.values[type] : []
  let newArr = oldArr?.filter(x=> x !== value)
   formik.setFieldValue(type, newArr)
}

const Label = ({str}: { str: string}): JSX.Element => {
  return (
    <>
      <Txt variant={"light"} fontSize={[3]}>
        {str}
      </Txt>
    </>
  );
};

  return (
    <>
      <Form width={[1]} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} onSubmit={formik.handleSubmit} {...props}>
        <Flex as='fieldset' width={[1, 1, "500px"]} flexDirection={"column"} maxWidth={"500px"} padding={0} margin={0} border={"none"}>
          <Txt as='legend' variant={"medium"} fontSize={[3]} textAlign={"end"} padding={"0 26px 0"}>
            {t(i18Enum.OnboardingQuiz_Colors_Header)}
          </Txt>
          <ColorGrid
            values={formik.values.colors}
            handleChange={(values: number[]) => formik.setFieldValue("colors", values)}
            onBlur={() => formik.setFieldError("colors", undefined)}
            onFocus={() => formik.setFieldTouched("colors", true)}
          />
        </Flex>

        <Flex as='fieldset' width={[1, 1, "500px"]} flexDirection={"column"} maxWidth={"500px"} marginTop={[4]} padding={"26px"} border={"none"} position={"relative"} $gap={1}>
          <Txt as='legend' variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Vibes_Header)}
          </Txt>
          {formFavoriteVibes.map((field, index) => {
            return (
              <Checkbox
                key={field.id}
                id={field.id.toString() + "vibes"}
                name={field.label}
                spaceProp={{ width: [1], marginTop: index === 0 ? "18px" : "18px" }}
                checked={formik.values.vibes ? formik.values.vibes.includes(field.id) : false}
                onChange={(e) => {
                  if (!e.target.checked) {
                    deleteItem("vibes", field.id);
                    return;
                  }
                  addItem("vibes", field.id);
                }}
                children={<Label str={field.label} />}
              />
            );
          })}
        </Flex>

        <Grid as='fieldset' gridTemplateColumns={["repeat(2, 1fr)"]} width={[1, 1, "500px"]} maxWidth={"500px"} padding={"26px"} margin={0} border={"none"} $gap={1}>
          <Txt as='legend' variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Sports_Header)}
          </Txt>
          {formFavoriteSports.map((field, index) => (
            <Checkbox
              key={field.id}
              name={field.label}
              id={field.id.toString() + "sports"}
              spaceProp={{ width: [1], marginTop: index === 0 ? "18px" : "18px" }}
              checked={formik.values.sports ? formik.values.sports.includes(field.id) : false}
              onChange={(e) => {
                if (!e.target.checked) {
                  deleteItem("sports", field.id);
                  return;
                }
                addItem("sports", field.id);
              }}
              children={<Label str={field.label} />}
            />
          ))}
        </Grid>

        <Grid as='fieldset' gridTemplateColumns={["repeat(2, 1fr)"]} width={[1, 1, "500px"]} maxWidth={"500px"} padding={"26px"} margin={0} border={"none"} $gap={1}>
          <Txt as='legend' variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Hobbies_Header)}
          </Txt>
          {formFavoriteHobbies.map((field, index) => (
            <Checkbox
              key={field.id}
              id={field.id.toString() + "hobbies"}
              spaceProp={{ width: [1], marginTop: index === 0 ? "18px" : "18px" }}
              checked={formik.values.hobbies ? formik.values.hobbies.includes(field.id) : false}
              onChange={(e) => {
                if (!e.target.checked) {
                  deleteItem("hobbies", field.id);
                  return;
                }
                addItem("hobbies", field.id);
              }}
              children={<Label str={field.label} />}
            />
          ))}
        </Grid>

        <Box width={[1]} marginTop={"auto"} height={110} zIndex={[1, 1, 0]}></Box>
        {!isMobile && (
          <Flex width={[1]} justifyContent={"space-between"} alignItems={"center"} maxWidth={"500px"}>
            <Txt variant='light' fontSize={[2]}>
              {t(i18Enum.User_UserProfile_Section_Preferences)}
            </Txt>
            <Button type='submit' variant='primary' padding={"10px 20px"} disabled={!formik.isValid}>
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>

      {isMobile && (
        <MobileNavigationBottom text={t(i18Enum.Common_Next)} description={t(i18Enum.User_UserProfile_Section_Preferences)} disabled={!formik.isValid} buttonAction={formik.handleSubmit} />
      )}
    </>
  );
}

export default StepThirdForm;
