import styled from "styled-components";
import {
  SpaceProps,
  TypographyProps,
  WidthProps,
  space,
  typography,
  width,
} from "styled-system";

interface CTACardProps {
  img?: string;
  grandient?: boolean;
  childTwo?: boolean;
  txt: string;
}

const CTACardButton = styled.div<
  WidthProps & TypographyProps & SpaceProps & CTACardProps
>`
  width: 100%;
  min-height: 65px;
  background-image: ${({ img }) => (img ? `url(${img})` : "none")};
  background-size: cover;
  background-position: center;
  border: solid ${({ theme }) => theme.colors.primaryBg};
  border-width: ${({ childTwo }) => (!childTwo ? "1px" : "0")} 1px 1px 0;
  box-sizing: border-box;
  transform: skewY(-5.5deg);
  cursor: pointer;
  ${width};
  ${space};

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: ${({ grandient }) =>
      grandient
        ? "linear-gradient(80deg,#000 4.94%,rgba(52, 58, 51, 0.19) 93.94%)"
        : "transparent"};
  }

  &::before {
    content: ${({ txt }) => `"${txt}"`};
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.color || props.theme.colors.primaryBg};
    font-family: BauPro;
    font-weight: 500;
    transform: skew(5.5deg);
    text-decoration: underline;
    text-transform: uppercase;
    ${typography};
  }
`;

export default CTACardButton;
/* 
exaple
<CTACardButton
    img={img}
    grandient={true}
    childTwo={false}
    txt={"explore magazine"}
    fontSize={[3]}
/>
*/
