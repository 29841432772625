import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { REGISTER, RegisterStateType } from "./RegisterTypes";
import { RegisterRequest } from "api/types/requests/registerRequest";
import { toast } from "react-toastify";
import { toastErrorStyle } from "utils/const/const";
import { i18Enum } from "i18n/types/translationType";
import { t } from "i18next";

const registerInitialState: RegisterStateType = {
    register: {
        isLoading: false,
        isSuccess:null,
        errors: '',
        data: ""
    }
}

const registerSlice = createSlice({
  name: REGISTER,
  initialState: registerInitialState,
  reducers: {
    getRegisterAction: (state: RegisterStateType, { payload }: PayloadAction<RegisterRequest>) => {
      state.register.isLoading = true;
      state.register.errors = "";
    },
    getRegisterSuccessAction: (state: RegisterStateType, { payload }: PayloadAction<string>) => {
      state.register.isSuccess = true;
      state.register.isLoading = false;
      state.register.errors = "";
      state.register.data = payload
    },
    getRegisterErrorAction: (state: RegisterStateType, { payload: error }: PayloadAction<string>) => {
      state.register.isSuccess = false;
      state.register.isLoading = false;
      state.register.errors = error;
      toast.error(t(i18Enum.Error_Email_InUse), toastErrorStyle);
    },
  },
});

export const RegisterActions = registerSlice.actions

export const {getRegisterAction,getRegisterErrorAction,getRegisterSuccessAction} = registerSlice.actions

export default registerSlice.reducer