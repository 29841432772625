import styled from "styled-components";
import { SpaceProps,space,position,PositionProps,LayoutProps, WidthProps,layout,width,flexbox,FlexboxProps } from "styled-system";


const Form = styled.form<SpaceProps&PositionProps&LayoutProps& WidthProps&FlexboxProps>`
${space}
${layout}
${width}
${flexbox}
${position}
    display: flex;
`

export default Form