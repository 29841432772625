import  styled from 'styled-components' 
import Row from './Row'

const HeaderRow = styled(Row)`
font-weight: bold;
border-bottom: 1px solid ${props=>props.theme.colors.primaryBorder};
position:absolute;
width: 100%;
background-color: ${props=>props.theme.colors.primaryBg};
top:0;
`

export default HeaderRow