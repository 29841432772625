import styled from "styled-components";
import { ColorProps, SpaceProps, WidthProps, HeightProps, compose, variant, space, width, color, height, typography, layout, border, fontSize, position, TypographyProps } from "styled-system";

export type variants = "description" | "comment";
export interface TextareaProps {
  variant: variants;
  private?: "text";
}
const Textarea = styled.textarea<TextareaProps & ColorProps & SpaceProps & WidthProps & HeightProps & TypographyProps>`
  font-family: "BauPro";
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.grayBase};
  color: ${(props) => props.theme.colors.thirdTextContrast};
  border: none;
  margin: 0;
  padding: 0;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
  height: auto;

  &::placeholder {
    text-transform: uppercase;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:disabled:hover {
    opacity: 0.5;
    cursor: default;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: inherit !important;
  }

  ${() =>
    compose(
      variant({
        variants: {
          description: {
            minHeight: "110px",
            borderRadius: "16px",
          },
          comment: {
            minHeight: "36px",
          },
        },
      })
    )}
  ${width};
  ${height};
  ${space};
  ${color};
  ${typography};
  ${layout};
  ${border};
  ${fontSize};
  ${position};
`;

export default Textarea;
