import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderRequest, SetShippingOptionRequest, UpdateBasketRequest } from "api/types/requests/cartRequest";
import { SelectedProduct } from "utils/generalTypes/Frontend";
import { toast } from "react-toastify";
import { CART, CartStateType, CartType } from "./CartType";
import { toastErrorStyle, toastSuccessStyle } from "utils/const/const";
import {t} from "i18next"
import { i18Enum } from "i18n/types/translationType";

const cartInitialState: CartStateType = {
  cart: {
    data: null,
    isLoading: false,
    errors: "",
  },
};


const cartSlice = createSlice({
  name: CART,
  initialState: cartInitialState,
  reducers: {
    buyNowAction: (state: CartStateType, { payload }: PayloadAction<{ item: SelectedProduct; action: () => void }>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
      state.cart.data = {
        ...state.cart.data,
        isBuyNow: true,
      };
    },
    buyNowCancelAction: (state: CartStateType) => {
      state.cart.data = null;
    },

    addItemAction: (state: CartStateType, { payload }: PayloadAction<SelectedProduct>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
    },
    addItemSuccessAction: (state: CartStateType, { payload }: PayloadAction<CartType>) => {
      state.cart.isLoading = false;
      state.cart.data = {
        ...state.cart.data,
        ShoppingBasket: payload.ShoppingBasket,
        RelatedProducts: payload.RelatedProducts,
      };
      state.cart.errors = "";
    },
    addItemErrorAction: (state: CartStateType, { payload }: PayloadAction<string>) => {
      state.cart.isLoading = false;
      state.cart.errors = payload;
    },
    updateShoppingBasketAction: (state: CartStateType, { payload }: PayloadAction<UpdateBasketRequest>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
      const prevArr = state.cart.data?.ShoppingBasket?.processingProducts ?? [];
      if (!payload.Id) {
        return;
      }
      state.cart.data = {
        ...state.cart.data,
        ShoppingBasket: {
          ...state.cart.data?.ShoppingBasket,
          processingProducts: [...prevArr, payload.Id],
        },
      };
    },
    updateShoppingBasketSuccessAction: (state: CartStateType, { payload }: PayloadAction<CartType>) => {
      state.cart.isLoading = false;
      state.cart.data = {
        ...state.cart.data,
        ShoppingBasket: payload.ShoppingBasket,
        RelatedProducts: payload.RelatedProducts,
      };
      state.cart.errors = "";
      toast.success(t(i18Enum.Cart_Updated), toastSuccessStyle);
    },
    updateShoppingBasketErrorAction: (state: CartStateType, { payload }: PayloadAction<string>) => {
      state.cart.isLoading = false;
      state.cart.errors = payload;
      toast.error(payload, toastErrorStyle);
    },
    deleteItemAction: (state: CartStateType, { payload }: PayloadAction<string>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
    },
    getShoppingBasketAction: (state: CartStateType) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
    },
    getShoppingBasketSuccessAction: (state: CartStateType, { payload }: PayloadAction<CartType>) => {
      state.cart.isLoading = false;
      state.cart.errors = "";
      state.cart.data = {
        ...state.cart.data,
        ShoppingBasket: payload.ShoppingBasket,
        RelatedProducts: payload.RelatedProducts,
      };
    },
    getShoppingBasketErrorAction: (state: CartStateType, { payload }: PayloadAction<string>) => {
      state.cart.isLoading = false;
      state.cart.errors = payload;
    },
    getShoppingBasketNoBasketAction: (state: CartStateType) => {
      state.cart.isLoading = false;
      state.cart.errors = "";
      state.cart.data = null;
    },
    setBillingAddressSameAsDeliveryAddress: (state: CartStateType, { payload }: PayloadAction<boolean>) => {
      state.cart.data = {
        ...state.cart.data,
        cartOrderInfo: {
          billingAddressSameAsDeliveryAddress: payload,
        },
      };
    },
    setShippingOptionAction: (state: CartStateType, { payload }: PayloadAction<{ req: SetShippingOptionRequest; action: () => void }>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
    },
    createOrderAction: (state: CartStateType, { payload }: PayloadAction<{ req: OrderRequest; action: (par: string) => void }>) => {
      state.cart.isLoading = true;
      state.cart.errors = "";
    },
    createOrderErrorAction: (state: CartStateType, { payload }: PayloadAction<string>) => {
      state.cart.isLoading = false;
      state.cart.errors = payload;
      if (state.cart.data?.isBuyNow) {
        state.cart.data.isBuyNow = false;
        return;
      }
    },
    createOrderSuccessAction: (state: CartStateType) => {
      state.cart.isLoading = false;
      state.cart.errors = "";
      state.cart.data = null;
    },
  },
});

/* const for easy access in components */
export const cartActions = cartSlice.actions;
/*const for saga/store's  use */
export const {
  addItemAction,
  addItemSuccessAction,
  addItemErrorAction,
  updateShoppingBasketAction,
  updateShoppingBasketErrorAction,
  updateShoppingBasketSuccessAction,
  deleteItemAction,
  getShoppingBasketAction,
  getShoppingBasketErrorAction,
  getShoppingBasketSuccessAction,
  getShoppingBasketNoBasketAction,
  setBillingAddressSameAsDeliveryAddress,
  setShippingOptionAction,
  createOrderAction,
  createOrderErrorAction,
  createOrderSuccessAction,
  buyNowAction,
  buyNowCancelAction
} = cartSlice.actions;

export default cartSlice.reducer;
