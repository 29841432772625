 import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  height,
  width,
  opacity,
  OpacityProps,
} from "styled-system";

interface SVGProps
  extends WidthProps,
    HeightProps,
    SpaceProps,
    PositionProps,
    OpacityProps {
  $transform?: string;
  $transition?: string;
  disabled?:boolean;
  widthCustom?: "s" | "m";
  fill?: string;
  handleClick?: () => void;
}

const widthMapping = {
  s: "31px",
  m: "49px",
};

const StyledSVG = styled.svg<SVGProps>`
  width: ${(props) =>props?.width || "50px"};
  cursor: pointer;
  transform: ${({ $transform }) => $transform || "none"};
  transition: ${({ $transition }) => $transition || "none"};
  cursor: pointer;
  ${space};
  ${position};
  ${height};
  ${width};
  ${opacity}
`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.primaryBase};
`;

function ArrowSvg({ ...props }: SVGProps) {
  return (
    <StyledSVG
       width={"35px"}
      onClick={props.disabled  ? ()=>{} : ()=> {props.handleClick?.(); console.log("click")} }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 12"
      opacity={props.disabled ? 0 : 1}
      {...props}
    >
      <SVGPath
        {...props}
        d="M0 6L10 11.7735V0.226497L0 6ZM31 5L9 5V7L31 7V5Z"
      />
    </StyledSVG>
  );
}

export default ArrowSvg;
