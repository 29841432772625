import Flex from "../components/primitives/Flex";
import Txt from "../components/primitives/Txt";
// import BackgroundVideo from "../components/molecules/VideoBackgroud";
import Button from "../components/primitives/Button";
import Box from "../components/primitives/Box";
import cards from "../api/mock/cards.json";
import GridCard from "../components/organisms/GridCard";
import Image from "../components/primitives/Image";
import { useContext, useEffect, useRef } from "react";
import { CustomThemeContext } from "../context/ThemeContext";
import { Outfits } from "../components/organisms/Outfits";
import magazine from "../assets/img/magazine.jpg";
import { H1, H2, H3, H4, H6 } from "../theme/theme.styled";
import Section from "../components/primitives/Section";
import { Variants, motion } from "framer-motion";
import { AppGeneralContext } from "../context/AppGeneralContext";
import SliderHomepageDesktop from "../components/molecules/SliderHomepageDesktop";
import { useNavigate } from "react-router";
import { PAGE } from "utils/const/pagePath";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { OutfitsActions } from "redux/features/outfits/outfitsSlice";
import AboutUsSvg from "components/atoms/AboutUsSvg";
import FlippingText from "components/atoms/FlippingText";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { RenderHTML } from "components/primitives/RenderHTML";
import { ModalContext } from "context/ModalContext";
import { ModalTypeEnum } from "utils";
import { cookies } from "utils/cookies";
import { PopupSlider } from "components/organisms/PopupSlider";
import { saveCurrentTimestamp } from "utils/functions/saveCurrentTimestamp";
import SectionSlaiderField from "../components/organisms/SectionHomeSlider";
import homeSectionSlider from "../api/mock/homeSectionSlider.json";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import mock from "../api/mock/popup.json";

export const HomePage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { openModalWithChildren } = useContext(ModalContext);
  const { isMobile, parsedSpicyProduct, homepageSliderContent, homepagePopup, isLoading } = useContext(AppGeneralContext);
  const outfit = useAppSelector((state) => state.outfits.homePageOutfit.data);
  const color = customTheme.colors;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const textAnimation: Variants = {
    hidden: (i) => ({
      x: 300,
      opacity: 0,
      transition: {
        dalay: i * 3,
      },
    }),
    visible: (i) => ({
      x: 0,
      opacity: 1,
      transition: {
        dalay: i * 3,
        type: "spring",
        duration: 0.8,
      },
    }),
  };

  const section = useRef<HTMLDivElement | null>(null);
  const sectionsAnimation: Variants = {
    offscreen: {
      y: -300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
      },
    },
  };

  useEffect(() => {
    // if (isLoading) {
    //   return;
    // }
    //  openModalWithChildren(<PopupSlider data={mock} />, ModalTypeEnum.popup, () => saveCurrentTimestamp());

    if (isLoading || !homepagePopup || homepagePopup.length === 0) {
      return;
    }
    const cookieTimestamp = cookies.get("mooore-k-popup");
    const savedTimestamp = new Date(cookieTimestamp);
    const newTimestamp = new Date(homepagePopup[0].DateFrom);
    if (!cookieTimestamp || (cookieTimestamp && savedTimestamp < newTimestamp)) {
      openModalWithChildren(<PopupSlider data={homepagePopup} />, ModalTypeEnum.popup, () => saveCurrentTimestamp());
    } else return;
  }, [isLoading]);

  function showOutfit() {
    if (!outfit) {
      return;
    }
    dispatch(OutfitsActions.getOutfitsListAction());
    dispatch(OutfitsActions.setSelectedOutfitAction(outfit));
    navigate(PAGE.outfit.menuPath);
  }

  function goToShopping() {
    navigate(PAGE.shopList.menuPath);
  }

  function discover() {
    window.open("https://magazine.mooore.com/la-prima-cover-per-iphone-che-si-pianta-e-germoglia/");
  }

  function about() {
    navigate(PAGE.aboutUs.menuPath);
  }

  return (
    <>
      <Flex id='' width={"100%"} height={"100%"} flexDirection={"column"} alignItems={"center"} padding={["15% 0", "5% 0", "0"]}>
        <Box id='' width={"100%"} height={"2vh"} />
        <Section id='' position={"relative"} width={"100%"} height={["100%", "100%"]} marginBottom={"10%"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}>
          <Flex position={"relative"} maxWidth={"1100px"} width={"100%"} height={"100%"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}>
            <Txt
              position={"absolute"}
              variant={"medium"}
              zIndex={20}
              top={["2vh", "5vh", 0]}
              fontSize={isMobile ? H3 : H2}
              as={"h2"}
              padding={"0 5%"}
              color={color.primaryBase}
              $textTransform='default'
              textAlign={"center"}
            >
              {t(i18Enum.Home_Header_Greetings)},
              <Txt variant={"medium"} as={"span"} color={color.whiteA10} $textTransform='default'>
                {" "}
                {`${t(i18Enum.Home_Header_Greetings2)}`}
              </Txt>
              <Flex>
                <Txt variant={"medium"} textAlign={"right"} fontSize={H6} as={"span"} color={color.whiteA10} $textTransform='default'>
                  {t(i18Enum.Home_Header_Desc)}
                </Txt>
              </Flex>
            </Txt>
            {homepageSliderContent && <SliderHomepageDesktop images={homepageSliderContent} />}
          </Flex>
          {/* <BackgroundVideo variant={"right"} video={"second"} height={"100%"} top={"10vh"} /> */}
        </Section>
        {/* TODO: riattivare il blocco dei MOOD */}
        {/* <motion.div style={{ width: "100%", height: "100%" }} initial='offscreen' whileInView='onscreen' viewport={{ once: true, amount: 0.3 }}>
        <Section id='' ref={section} overflow={"hidden"} minHeight={"80vh"} position={"relative"} width={["100%"]} flexDirection={"row"} alignItems={"start"} justifyContent={"center"}>
          <motion.div
            style={{
              maxWidth: "1100px",
              width: "100%",
              minHeight: "80vh",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
            variants={sectionsAnimation}
          >
            <Txt variant={"medium"} fontSize={isMobile ? H4 : H3} padding={"0 5%"} as={"h2"} $textTransform='default' textAlign={"center"}>
              {t(i18Enum.Home_Section_Mood_Title)} 🫧
            </Txt>
            <SectionSlaiderField images={homeSectionSlider} />
          </motion.div>
          <BackgroundVideo variant={"left"} video={"first"} height={"100%"} left={["60%"]} />
        </Section>
      </motion.div> */}

        <Section
          maxWidth={"1100px"}
          position={"relative"}
          padding={"5% 0"}
          id=''
          overflowX={"hidden"}
          width={"100%"}
          height={["auto", "100%"]}
          flexDirection={["column", "row"]}
          alignItems={["center", "start"]}
          justifyContent={"center"}
        >
          <Flex id='' padding={"0  5%"} overflowX={"hidden"} width={["100%", "44%"]} height={["auto", "100%"]} flexDirection={"column"} alignItems={"start"} justifyContent={"center"}>
            <Txt variant={"medium"} fontSize={H2} as={"h2"} $textTransform='default' lineHeight={"120%"} width={["80%", "100%"]} textAlign={"left"}>
              {t(i18Enum.Home_Section_Outfit_Title)}
            </Txt>

            {/* TODO: vecchia formattazione del testo
          
          <Txt variant='light' as={"p"} paddingTop={"20px"} fontSize={[4]} lineHeight={"180%"} textAlign={"left"} width={["70%", "100%"]} $textTransform='default'>
            {outfit?.Description}
          </Txt> */}
            <RenderHTML html={`<div>${outfit?.Description}</div>`} fontSize={"15px"} paddingTop={"10px"} lineHeight={"200%"} width={isMobile ? "70%" : "100%"} />

            <Button variant='option' marginTop={"10px"} width={["50%", "60%"]} padding={"10px 0"} onClick={showOutfit}>
              {t(i18Enum.Home_Section_Outfit_Button)}
            </Button>
          </Flex>
          <Flex id='' overflow={"hidden"} width={["100%", "60%"]} height={"100%"} position={"relative"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <Outfits title={outfit?.Title ?? ""} products={outfit?.Products ?? []} id={outfit?.Title ?? ""} description={outfit?.Description ?? ""} coverImage={""} showOutfit={showOutfit} />
          </Flex>
          {/* <BackgroundVideo variant={"right"} video={"second"} height={"50%"} bottom={0} /> */}
        </Section>

        <Section
          padding={"0 5% 5% 5%"}
          maxWidth={"1200px"}
          id=''
          overflowX={"hidden"}
          width={"100%"}
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={"center"}
          style={{
            transform: `${cards.data.length >= 4 ? "translateY(-10dvh)" : "translateY(-3dvh)"}`,
          }}
        >
          <Flex paddingBottom={"5%"} flexDirection={"row"} width={"100%"} justifyContent={"flex-end"} alignItems={"start"}>
            <Flex flexDirection={"column"}>
              <Txt variant={"medium"} fontSize={H1} textAlign={"right"} lineHeight={"70px"}>
                {t(i18Enum.Home_Section_Shopping_Title)}
              </Txt>
              <Txt variant={"medium"} fontSize={H3} textAlign={"right"}>
                {t(i18Enum.Home_Section_Shopping_Title2)}
              </Txt>
            </Flex>
            <Flex alignItems={"flex-start"} justifyContent={"flex-start"} style={{ transform: "translateY(-15px)" }} height={70}>
              <Txt fontWeight={400} lineHeight={-2} fontSize={75} style={{ transform: "scaleX(-1)" }}>
                ️️💋
              </Txt>
            </Flex>
          </Flex>

          <GridCard isHomeCard={false} products={parsedSpicyProduct} />
          <Flex paddingTop={"20px"} flexDirection={"row"} width={"100%"} justifyContent={"center"}>
            <Button variant='primary' margin={"30px 0"} width={"50%"} padding={"10px 0"} onClick={goToShopping}>
              <Txt>{t(i18Enum.Home_Section_Shopping_Button)}</Txt>
            </Button>
          </Flex>
        </Section>

        <motion.div style={{ width: "100%", height: "100%", maxWidth: "1100px" }} initial='hidden' whileInView='visible' exit={"hidden"} viewport={{ once: !isMobile ? true : false, amount: 0.3 }}>
          <Section
            padding={"5% 5%"}
            id=''
            maxWidth={"1100px"}
            overflow={"hidden"}
            width={"100%"}
            height={["100%", "100%"]}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"start"}
            position={"relative"}
          >
            <motion.div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                justifyContent: "end",
              }}
              variants={textAnimation}
            >
              <Flex position={"absolute"} top={0} left={0} width={"100%"} flexDirection={"column"} alignItems={"start"}>
                <Txt variant={"medium"} fontSize={H1} lineHeight={"62px"} as={"h1"} color={color.primaryBase} textAlign={"left"} marginLeft={0}>
                  {t(i18Enum.Home_Section_Magazine_Title)}
                </Txt>
                <Txt variant={"light"} fontSize={[2]} lineHeight={"12px"} textAlign={"left"}>{`NEWS OF THE WEEK`}</Txt>
                <Txt variant={"bold"} fontSize={isMobile ? H2 : H1} width={["100%"]} color={color.thirdBase} zIndex={2} textAlign={"left"} marginTop={[2]} position={"relative"} right={[10, 20, 30]}>
                  {/* {t(i18Enum.Home_Section_Magazine_Article)} */}
                  iGreen Cover: la cover che pianti e germoglia
                </Txt>
              </Flex>
              <Box style={{ height: "175px" }} />
              <Image src={magazine} height={["auto"]} width={["90%", "100%"]} $aspectRatio='16 / 9' objectFit='cover' marginTop={"5%"} />
              <Flex width={["100%"]} justifyContent={"end"} position={"relative"} marginTop={[-50, -65]} marginRight={[10, 30]}>
                <Button variant='primary' padding={["10px 12px", "10px 20px"]} onClick={discover}>
                  <Txt $cursor='pointer'>{t(i18Enum.Home_Section_Magazine_Button)}</Txt>
                </Button>
              </Flex>
            </motion.div>
          </Section>
        </motion.div>
        {/* TODO: riattivare il GUARDAROBA DIGITALE in v2 */}
        {/* <motion.div style={{ width: "100%", height: "100%", maxWidth: "1100px" }} initial='hidden' whileInView='visible' exit={"hidden"} viewport={{ once: !isMobile ? true : false, amount: 0.3 }}>
        <Section padding={"10% 5%"} id='' maxWidth={"1100px"} overflowX={"hidden"} width={"100%"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}>
          <motion.div style={{ width: "100%", height: "100%" }} variants={textAnimationReverse}>
            <Txt variant={"medium"} color={color.primaryText} lineHeight={isMobile ? "43px" : "55px"} fontSize={H1} as={"h1"} textAlign={"right"}>
              {t(i18Enum.Home_Section_Wardrobe_Title)}
            </Txt>
            <Txt variant={"light"} fontSize={[0, 1, 3]} as={"p"} lineHeight={"12px"} marginTop={"5%"} textAlign={"left"}>
              {t(i18Enum.Home_Section_Wardrobe_CTA)}
            </Txt>
            <Image src={wardrobe} width={"100%"} $aspectRatio='16 / 9' objectFit='cover' borderRadius={10} />
            <Flex paddingTop={"20px"} flexDirection={"row"} width={"100%"} justifyContent={"end"}>
              <Button variant='primary' width={["50%", "30%"]} margin={"30px 0"} padding={"10px 0"} onClick={about}>
                <Txt>{t(i18Enum.Home_Section_Wardrobe_Button)}</Txt>
              </Button>
            </Flex>
          </motion.div>
        </Section>
      </motion.div> */}
        <motion.div style={{ width: "100%", height: "100%", maxWidth: "1100px" }} initial='hidden' whileInView='visible' exit={"hidden"} viewport={{ once: !isMobile ? true : false, amount: 0.3 }}>
          <Section
            padding={["20% 0 0 0", "10% 15% 5% 15%"]}
            id=''
            maxWidth={"1100px"}
            overflow={"hidden"}
            width={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"start"}
            $gap={isMobile ? 0 : 0.5}
          >
            <motion.div
              custom={0}
              style={{
                width: "100%",
                height: "100%",
                alignContent: "flex-start",
                textAlign: "center",
              }}
              variants={textAnimation}
            >
              <FlippingText text={t(i18Enum.Home_Section_AboutUs_Title)} />
            </motion.div>
            <motion.div
              custom={1}
              style={{
                padding: isMobile ? "7% 5% 0" : "5% 10% 0",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Txt variant={"medium"} fontSize={[4, "20px"]} width={"100%"} textAlign={"start"} $textTransform='inherit'>
                {t(i18Enum.Home_Section_AboutUs_Desc1)}
              </Txt>
            </motion.div>
            <motion.div
              custom={2}
              style={{
                padding: isMobile ? "5% 5% 0" : "0 10% ",
                marginBottom: -20,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Txt variant={"bold"} fontSize={["55px", "100px"]} lineHeight={1.2} width={"100%"} textAlign={"start"}>
                {t(i18Enum.Home_Section_AboutUs_Desc2)}
              </Txt>
            </motion.div>
            <motion.div
              custom={3}
              style={{
                padding: isMobile ? "5% 5% 0" : "0 10% ",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Txt variant={"medium"} fontSize={[4, "20px"]} width={"100%"} textAlign={"start"} $textTransform='inherit'>
                {t(i18Enum.Home_Section_AboutUs_Desc3)}
              </Txt>
            </motion.div>
            <motion.div
              custom={4}
              style={{
                padding: isMobile ? "5% 5% 0" : "0 10%",
                marginBottom: -20,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Txt variant={"bold"} fontSize={["55px", "100px"]} lineHeight={1.2} width={"100%"} textAlign={"center"}>
                {t(i18Enum.Home_Section_AboutUs_Desc4)}
              </Txt>
            </motion.div>
            <motion.div
              custom={5}
              style={{
                padding: isMobile ? "5% 5% 0" : "0 10% ",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "end",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Txt variant={"medium"} fontSize={[4, "20px"]} width={"100%"} textAlign={"end"} $textTransform='inherit'>
                {t(i18Enum.Home_Section_AboutUs_Desc5)}
              </Txt>
            </motion.div>

            <motion.div
              custom={6}
              style={{
                padding: "5% 10% ",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              variants={textAnimation}
            >
              <Flex width={["70%", "60%", "50%", "40%"]} height={"100%"}>
                <AboutUsSvg text={t(i18Enum.Home_Section_AboutUs_Button)} colorText='#fff' handleClick={about} height={"100%"} width={"100%"} />
              </Flex>
            </motion.div>
          </Section>
        </motion.div>
      </Flex>
    </>
  );
};
