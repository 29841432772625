import { PaginatedResponse } from "api/types/responses";
import { IState } from "../SharedStateType";
import { BasePaginatedRequest } from "api/types/requests";

export type CommunityUser= {
    Id: string;
    GivenName: string;
    FamilyName: string;
    Username:string
    IsFollowing: boolean;
    ImageUrl: string | null;
  }

export type CommunityStateType = {
    CommunityUser:IState<CommunityUser>
    CommunityUserList: IState<PaginatedResponse<CommunityUser>>
}

export type CommunitySearchRequest= & BasePaginatedRequest & {
    SearchText?:string
    isFiltering?:boolean
}

export const COMMUNITY = 'community';
export type COMMUNITY = typeof COMMUNITY;

export const GET_COMMUNITY_USER_LIST = `${COMMUNITY}/getCommunityUserList`;
export type GET_COMMUNITY_USER_LIST = typeof GET_COMMUNITY_USER_LIST;

export type GET_COMMUNITY_USER = typeof GET_COMMUNITY_USER;
export const GET_COMMUNITY_USER = `${COMMUNITY}/getCommunityUser`



