import { PayloadAction } from "@reduxjs/toolkit";
import { getAuthCall } from "api/authCall";
import { LoginRequest } from "api/types/requests";
import { ApiSimpleResponse, BaseResponse, LoginResponse } from "api/types/responses";
import { askAuthErrorAction, askAuthSuccessAction, removeAuthAction } from "./authSlice";
import { put, takeLatest } from "redux-saga/effects";
import { ASK_AUTH, REMOVE_AUTH } from "./AuthTypes";
import { UserType } from "../user/UserTypes";
import { getUserErrorAction, getUserSuccessAction, userResetDataAction } from "../user/userSlice";
import { removeAuth, setAuth } from "api/authHelpers";
import { getUserCall } from "api/userCalls/userCalls";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toast } from "react-toastify";
import { toastErrorStyle } from "utils/const/const";
import { cookies } from "utils/cookies";
import { getShoppingBasketAction, getShoppingBasketNoBasketAction } from "../cart/cartSlice";

function* getAuthSaga({ payload }: PayloadAction<LoginRequest>) {
  const res: ApiSimpleResponse<LoginResponse> = yield getAuthCall(payload);
  if (!res.isSuccess || !res.response) {
    toast.error(t(i18Enum.Error_Login), toastErrorStyle);
    yield put(askAuthErrorAction(res.error ?? ""));
    return;
  }
  let expiredDate = new Date().getTime() + 4 * 60 * 60 * 1000;
  yield setAuth({ Data: res.response.Data, expiredDate: expiredDate, IsVerified: true });
  const user: ApiSimpleResponse<BaseResponse<UserType>> = yield getUserCall();
  if (!user.isSuccess || !user.response || !user.response.Data) {
    yield put(getUserErrorAction(user.error ?? ""));
    yield put(askAuthErrorAction(res.error ?? t(i18Enum.ErrorPage500_ErrorLabel)));
    yield put(removeAuthAction());
    return;
  }
  yield put(getUserSuccessAction(user.response.Data));
  yield put(askAuthSuccessAction(res.response));
  if (user.response.Data.ShoppingBasketId) {
    yield cookies.set("shoppingBasketId", user.response.Data.ShoppingBasketId);
    yield put(getShoppingBasketAction());
  }
}

function* removeAuthSaga() {
  yield removeAuth();
  yield cookies.remove("shoppingBasketId");
  yield put(getShoppingBasketNoBasketAction());
  yield put(userResetDataAction());
}

export function* watchAuth() {
  yield takeLatest(ASK_AUTH, getAuthSaga);
  yield takeLatest(REMOVE_AUTH, removeAuthSaga);
}
