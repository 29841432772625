/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpaceProps, WidthProps } from "styled-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectField from "components/molecules/SelectField";
import { useContext, useEffect, useState } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import { IdOptionType, OptionNumberType } from "components/primitives/Select";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Form from "components/primitives/Form";
import { useNavigate } from "react-router-dom";
import { UserActions } from "redux/features/user/userSlice";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import { toastType, useDebounce } from "utils";
import { useTheme } from "styled-components";
import { CreateUpdateBillingAddressRequest } from "api/types/requests/shippingAddressRequest";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import TextareaField from "components/molecules/TextareaField";
import Box from "components/primitives/Box";
import { PAGE } from "utils/const/pagePath";
import Button from "components/primitives/Button";
import { i18Enum } from "i18n/types/translationType";
import { t } from "i18next";
import { getProvincesList, getRegionsList } from "api/userCalls/userCalls";

type BillingVariants = "shipping";
type BillingAddressFormFields = {
  Id?: string | undefined;
  AddressLine1?: string;
  AddressLine2?: string;
  City?: string;
  CountryKey?: OptionNumberType;
  Note?: string | undefined;
  Name?: string | undefined;
  PhoneNumber?: string | undefined;
  ZipCode?: string;
  RegionDto?: IdOptionType | undefined;
  ProvinceDto?: IdOptionType | undefined;
  Region?: string;
  RegionId?: string;
  Province?: string;
  ProvinceId?: string;
  // PI?:string;
  // CF?:string
};

type FormShippingAddressSchemaObject = {
  [key in keyof BillingAddressFormFields]: Yup.Schema<any>;
};

interface AddressFormProps extends WidthProps, SpaceProps {
  useThisAddress?: UserShippingAddress;
  data?: UserShippingAddress;
  variant?: BillingVariants;
  isCartForm?: boolean;
  isEdit: boolean;
}
type FormField = {
  id: keyof BillingAddressFormFields;
  placeholder: string;
  type: "input" | "select";
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formAddressSchema = Yup.object().shape<FormShippingAddressSchemaObject>({
  Id: Yup.string(),
  AddressLine1: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  AddressLine2: Yup.string(),
  City: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  Note: Yup.string(),
  Name: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  PhoneNumber: Yup.string().matches(phoneRegExp, t(i18Enum.Validation_Client_Field_Phone)).required(t(i18Enum.Validation_Client_Field_Required)),
  ZipCode: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  RegionDto: Yup.object()
    .shape({
      Id: Yup.string(),
      Label: Yup.string(),
    })
    .required(t(i18Enum.Validation_Client_Field_Required)),
  ProvinceDto: Yup.object()
    .shape({
      Id: Yup.string(),
      Label: Yup.string(),
    })
    .required(t(i18Enum.Validation_Client_Field_Required)),
});

const formInitialValue: BillingAddressFormFields = {
  Id: "",
  AddressLine1: "",
  AddressLine2: "",
  City: "",
  CountryKey: { label: t(i18Enum.DV_Countries_110), value: 110 },
  Note: "",
  Name: "",
  PhoneNumber: "",
  ZipCode: "",
  RegionDto: undefined,
  ProvinceDto: undefined,
  // CF:'',
  // PI:""
};
interface AddressFormProps extends WidthProps, SpaceProps {
  useThisAddress?: UserShippingAddress;
  data?: UserShippingAddress;
  variant?: BillingVariants;
  isCartForm?: boolean;
  isEdit: boolean;
  getSelectedName?: (par: string) => void;
}

export const AddEditBillingForm = ({ data, isEdit, isCartForm = false, useThisAddress, getSelectedName, ...props }: AddressFormProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const colors = useTheme().colors;
  const { addToast, isMobile, setAsideIsOpen, closeAndResetAside, setIsLoading } = useContext(AppGeneralContext);
  const cart = useAppSelector((state) => state.cart.cart.data?.cartOrderInfo);
  const { isSuccess, errors, isLoading } = useAppSelector((state) => state.user.userBillingAddresses);
  const [formikInitialValue, setFormikInitialValue] = useState<BillingAddressFormFields>(
    data ? { ...data, CountryKey: { label: t(i18Enum.DV_Countries_110), value: data.CountryKey ?? 110 } } : formInitialValue
  );
  const [regionsList, setRegionsList] = useState<IdOptionType[] | undefined>(undefined);
  const [searchedRegion, setSearchedRegion] = useState<string>("");
  const [provincesList, setProvincesList] = useState<IdOptionType[] | undefined>(undefined);
  const [searchedProvince, setSearchedProvince] = useState<string>("");
  const debouncedSearchRegion = useDebounce(searchedRegion, 500);
  const debouncedSearchProvince = useDebounce(searchedProvince, 500);
  const [regionsLoader, setRegionsLoader] = useState<boolean>(false);
  const [provincesLoader, setProvincesLoader] = useState<boolean>(false);
  const defaultNation: OptionNumberType[] = [{ label: t(i18Enum.DV_Countries_110), value: 110 }];

  useEffect(() => {
    if (!debouncedSearchRegion) {
      return;
    }
    if (debouncedSearchRegion.length < 3) {
      return;
    }
    fetchRegionsList(debouncedSearchRegion);
  }, [debouncedSearchRegion]);

  useEffect(() => {
    if (!debouncedSearchProvince) {
      return;
    }
    if (debouncedSearchProvince.length < 3) {
      return;
    }
    fetchProvincesList(formik.values.RegionDto?.Id, debouncedSearchProvince);
  }, [debouncedSearchProvince]);

    useEffect(() => {
      if (isEdit && formikInitialValue.RegionDto?.Id) {
        fetchProvincesList(formikInitialValue.RegionDto?.Id);
      }
    }, [formikInitialValue]);

  const fetchRegionsList = async (keyword: string) => {
    setRegionsLoader(true);
    const resp = await getRegionsList(formik.values.CountryKey?.value ?? 110, keyword);
    if (!resp.isSuccess) {
      addToast(toastType.error, t(i18Enum.Error_Occurred));
    } else {
      setRegionsList(resp.response?.Data);
    }
    setRegionsLoader(false);
  };

  const fetchProvincesList = async (id?: string, keyword?: string | null) => {
    if (!id) {
      return;
    }
    setProvincesLoader(true);
    const resp = await getProvincesList(id, keyword ?? null);
    if (!resp.isSuccess) {
      addToast(toastType.error, t(i18Enum.Error_Occurred));
    } else {
      setProvincesList(resp.response?.Data);
    }
    setProvincesLoader(false);
  };

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const submitRequest = async (isEdit: boolean, value: BillingAddressFormFields) => {
    const req: CreateUpdateBillingAddressRequest = {
      Id: value.Id,
      Name: value.Name ?? "",
      AddressLine1: value.AddressLine1 ?? "",
      AddressLine2: value.AddressLine2 ?? "",
      City: value.City ?? "",
      CountryKey: Number(value.CountryKey?.value) ?? 110,
      ZipCode: value.ZipCode ?? "",
      Note: value.Note ?? "",
      PhoneNumber: value.PhoneNumber ?? "",
      RegionId: value.RegionDto?.Id ?? "",
      ProvinceId: value.ProvinceDto?.Id ?? "",
      IsBilling: true,
    };

    if (isEdit && value?.Id) {
      dispatch(UserActions.updateUserBillingAddressAction({ id: value.Id, params: req }));
      return;
    } else {
      req.Id = undefined;
      dispatch(UserActions.createUserBillingAddressAction(req));
      return;
    }
  };

  const formik = useFormik<BillingAddressFormFields>({
    enableReinitialize: true,
    initialValues: formikInitialValue,
    validationSchema: formAddressSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await submitRequest(isEdit, values);
      //error
      if (isSuccess !== null && !isSuccess) {
        setSubmitting(false);
        addToast(toastType.error, errors);
        return;
      }

      if (getSelectedName) {
        getSelectedName(values?.Name ?? "");
      }
      //success
      setSubmitting(false);
      if (isMobile) {
        if (isCartForm) {
          navigate(-1);
          //TODO
        } else {
          navigate(PAGE.orders.menuPath);
          formik.resetForm();
          return;
        }
      }
      if (isCartForm) {
        formik.resetForm();
        return;
      }
      closeAndResetAside();
      setAsideIsOpen(false);
      formik.resetForm();
    },
  });

    const manageRegionDtoFields = async (fieldName: string, opt: IdOptionType) => {
      setProvincesList(undefined);
      await formik.setFieldValue("ProvinceDto", null);
      await formik.setFieldValue(fieldName, opt);
      await fetchProvincesList(opt.Id);
    };

      const isProvinceDisabled = () => {
        if (isEdit) {
          return false;
        }
        return regionsList && regionsList?.length > 0 ? false : true;
      };


  useEffect(() => {
    if (cart?.billingAddressSameAsDeliveryAddress && useThisAddress?.Id) {
      //set initial value as default shipping address
      setFormikInitialValue({ ...useThisAddress, CountryKey: { label: t(i18Enum.DV_Countries_110), value: 110 } });
      return;
    }
  }, [cart?.billingAddressSameAsDeliveryAddress, useThisAddress]);

  const FormFields: FormField[] = [
    {
      id: "Name",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_Name),
    },
    {
      id: "AddressLine1",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddressLine1),
    },
    {
      id: "AddressLine2",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddressLine2),
    },
    {
      id: "PhoneNumber",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_PhoneNumber),
    },
    {
      id: "CountryKey",
      type: "select",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_SelectCountry),
    },
    {
      id: "RegionDto",
      type: "select",
      placeholder: "Regione",
    },
    {
      id: "ProvinceDto",
      type: "select",
      placeholder: "Provincia",
    },
    {
      id: "City",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_City),
    },
    {
      id: "ZipCode",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_ZIPCode),
    },
  ];

  return (
    <Form
      as='form'
      flex={1}
      width={!isMobile && !isCartForm ? "65%" : "100%"}
      flexDirection={"column"}
      onSubmit={formik.handleSubmit}
      alignItems={"end"}
      position={"relative"}
      marginBottom={isMobile ? 140 : 90}
      {...props}
    >
      {FormFields.map((el, i) => {
        const fieldName = el.id as keyof BillingAddressFormFields;
        const placeholder = el.placeholder;
        const value = formik.values[fieldName] as string;
        const error = formik.errors[fieldName] && formik.touched[fieldName] ? String(formik.errors[fieldName]) : undefined;
        if (fieldName === "Id") {
          return null;
        }
        return el.type === "input" ? (
          <InputFieldWardrobe
            {...formik.getFieldProps(fieldName)}
            key={fieldName}
            name={fieldName}
            spaceProp={{
              width: [1],
              marginTop: i > 0 ? [4] : null,
              padding: ["0 27px"],
            }}
            id={fieldName}
            placeholder={placeholder}
            label={placeholder}
            value={value}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched(fieldName, true)}
            error={error}
          />
        ) : fieldName === "CountryKey" ? (
          <SelectField<OptionNumberType>
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={defaultNation}
            value={{ value: formik.values.CountryKey?.value ?? 110, label: t(i18Enum.DV_Countries_110) }}
            handleChange={(opt) => formik.setFieldValue(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => x.value.toString()}
          />
        ) : fieldName === "RegionDto" ? (
          <SelectField<IdOptionType>
            isClearable
            isSearchable
            isLoading={regionsLoader}
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={regionsList ?? []}
            value={formik.values.RegionDto}
            handleChange={(opt) => manageRegionDtoFields(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            onInputChange={(value) => setSearchedRegion(value)}
            labelExtractor={(x) => x.Label}
            valueExtractor={(x) => x.Id}
          />
        ) : fieldName === "ProvinceDto" ? (
          <SelectField<IdOptionType>
            isSearchable
            isLoading={provincesLoader}
            isDisabled={isProvinceDisabled()}
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={provincesList ?? []}
            value={formik.values.ProvinceDto}
            handleChange={(opt) => formik.setFieldValue(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            onInputChange={(value) => setSearchedProvince(value)}
            labelExtractor={(x) => x.Label}
            valueExtractor={(x) => x.Id}
          />
        ) : null;
      })}

      <TextareaField
        {...formik.getFieldProps("Note")}
        id='Note'
        name='Note'
        label={"Note"}
        value={formik.values.Note}
        placeholder={t(i18Enum.User_Shipping_ManageShippingAddress_Label_Note)}
        error={formik.errors.Note && formik.touched.Note ? String(formik.errors.Note) : undefined}
        variant='description'
        spaceProp={{ width: "100%", marginTop: [4], padding: "0 27px" }}
        onChange={formik.handleChange}
        onBlur={() => formik.setFieldTouched("Note", true)}
      />

      {!isCartForm && (
        <Box width={[1]} marginTop={"auto"} height={90} zIndex={isMobile ? 1 : 0}>
          <MobileNavigationBottom
            text={t(i18Enum.Common_Save)}
            description={isEdit ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_EditBillingAddress) : t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddBillingAddress)}
            borderBottom={"50px solid #000"}
            height={90}
            position={isMobile ? "fixed" : "relative"}
          
            variant='address'
          />
        </Box>
      )}
      {isCartForm && (
        <Button width={"50%"} padding={" 10px 0"} margin={"10px 5%"} textAlign={"end"} variant='primary' color={colors.secondaryBase} type='submit'>
          {t(i18Enum.Common_Save)}
        </Button>
      )}
    </Form>
  );
};
