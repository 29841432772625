import { ApiSimpleResponse, BaseResponse } from "api/types/responses";
import { getOrderByIdCall } from "api/userCalls/userCalls";
import Box from "components/primitives/Box";
import Flex from "components/primitives/Flex";
import Grid from "components/primitives/Grid";
import Image from "components/primitives/Image";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { OrdersDetailsPage } from "pages/OrdersPageDetails";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OrderType, OrderDTO} from "redux/features/user/UserTypes";
import { priceNum, toastType } from "utils";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export enum StatusOrder {
   /// cancellato dall'utente'
  "Canceled" = -2,
  /// cancellato dallo shop
  "Deleted" = -1,
  /// ricevuto dallo shop
  "Pending" = 0,
  /// In attesa della risposta del cliente
  "WaitingCustomer" = 1,
  /// Accettato dallo shop
  "Accepted" = 2,
  /// Spedito
  "Delivered" = 3,
}

export function Order({
  Id,
  ShopName,
  Products,
  ItemsTotal,
  Total,
  Number,
  Status,
  ParentNumber,
}: OrderType) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, openAsideMenu, addToast, setIsLoading } = useContext(AppGeneralContext);
  const numberofCard = isMobile ? 5 : 6;
  const handleDetail = async () => {
   //TO DO CHiamata order by Id
   setIsLoading(true)
   if(!Id){
    addToast(toastType.error, t(i18Enum.ErrorPage500_ErrorLabel));
    setIsLoading(false)
    return
   }

   const res: ApiSimpleResponse<BaseResponse<OrderDTO>> = await getOrderByIdCall(Id);
  //  const res:ApiSimpleResponse<OrderDTO> =  {
  //   isSuccess:true,
  //   error:"",
  //   response:order
  //  }


   if(!res.isSuccess || !res.response?.Data){
       addToast(toastType.error, `${t(i18Enum.Error_Occurred)}: ${res.error}`)
       setIsLoading(false)
       return
   }

    if (isMobile) {
      navigate(PAGE.ordersDetail.menuPath, {
        state: {
          ...res.response.Data
        },
      });
      setIsLoading(false)
      return
    }

    openAsideMenu<OrderDTO>(<OrdersDetailsPage />, res.response.Data);
    setIsLoading(false)
  };

  return (
    <Grid
      gridTemplateColumns={"1fr 0.4fr"}
      width={["100%", "420px"]}
      justifyContent={"stretch"}
      position={"relative"}
      padding={"22px 13px 76px 17px"}
      $gap={0.5}
      border={`1px solid ${colors.thirdLine}`}
      borderRadius={"16px 16px 16px 0"}
    >
      <Txt variant='medium' fontSize={[3]} textAlign={"left"} marginBottom={[2]}>
        {`${t(i18Enum.Orders_OrderParent_Number)} ${ParentNumber}`}
      </Txt>

      <Txt fontSize={[2]} variant='linkLight' color={colors.primaryText} textAlign={"right"} style={{ textTransform: "capitalize" }} onClick={handleDetail}>
        {t(i18Enum.Orders_Order_ViewDetail)}
      </Txt>

      <Txt variant='medium' fontSize={[3]} textAlign={"left"} marginBottom={[2]}>
        {`${t(i18Enum.Orders_OrderShop_Number)} ${Number}`}
      </Txt>

      <Txt fontSize={[2]} variant='linkLight' color={colors.primaryText} textAlign={"right"} style={{ textTransform: "capitalize" }} onClick={handleDetail}>
        {" "}
      </Txt>

      <Txt variant='light' color={colors.thirdText} fontSize={[2]} textAlign={"left"}>
        {ShopName}
      </Txt>

      <Txt variant='light' color={colors.primaryText} fontSize={[2]} textAlign={"right"}>
        {`${priceNum(Total ?? 0)}€`}
      </Txt>

      <Txt variant='light' color={colors.thirdText} fontSize={[2]} textAlign={"left"}>
        {t(i18Enum.Orders_Order_Status)}
      </Txt>

      <Txt variant='light' color={colors.primaryText} fontSize={[2]} textAlign={"right"} marginBottom={[2]}>
        {StatusOrder[Status ?? 0]}
      </Txt>
      {/*
      <Txt
        variant="light"
        color={colors.thirdText}
        fontSize={[2]}
        textAlign={"left"}
      >
        {"promocode"}
      </Txt>
 
      <Txt
        variant="light"
        color={colors.primaryText}
        fontSize={[2]}
        textAlign={"right"}
      >
        {promoCode}
      </Txt> */}
      <Flex flexDirection={"row"} position={"absolute"} bottom={-1} left={-1} width={[1]}>
        {Products &&
          Products?.map((product, index) => {
            if (index >= numberofCard) {
              return;
            }
            return (
              <Box
                key={product.Id + index}
                position={"relative"}
                width={[`calc((100% / ${numberofCard + 1}) - 3px)`]}
                $aspectRatio='1 / 1'
                backgroundColor={"white"}
                borderRadius={index === 0 ? "16px 0 0 0" : 0}
                marginLeft={index === 0 ? 0 : "3px"}
                overflow={"hidden"}
                border={`1px solid ${colors.thirdLine}`}
              >
                <Image width={[1]} height={"100%"} objectFit='cover' src={product.Picture} backgroundColor={colors.secondaryBase} />
                <Txt
                  variant='light'
                  color={colors.thirdText}
                  fontSize={[2]}
                  position={"absolute"}
                  bottom={0}
                  right={0}
                  backgroundColor={colors.grayBg}
                  height={"1.5em"}
                  minWidth={"1.5em"}
                  opacity={0.61}
                >
                  {product.Quantity}
                </Txt>
              </Box>
            );
          })}
        {Products && Products?.length >= numberofCard && (
          <Flex
            position={"relative"}
            width={"57px"}
            height={58}
            borderRadius={"0 0 16px 0"}
            marginLeft={"3px"}
            overflow={"hidden"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            border={`1px solid ${colors.thirdLine}`}
          >
            <Txt variant='light' color={colors.thirdText} fontSize={["8px"]}>
              {t(i18Enum.Orders_Order_Others)}
            </Txt>
            <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
              {Products.length - numberofCard}
            </Txt>
          </Flex>
        )}
      </Flex>
    </Grid>
  );
}



