export const capitalize = (str?: string) => {
  if(!str){
    return
  }
  return  str[0].toUpperCase() + str.slice(1)
  };

export const truncateString = (str?: string,maxLength?:number) => {
  let formattedText
  if (!str) {
    return formattedText='...';
  }

  if(!!maxLength)
  {
   return formattedText = str.substring(0, maxLength - 3).trim() + '...';
  }
   formattedText = `...${str.slice(-15)}`;

  return formattedText;
};

export function privateCharacters(str: string) {
  return str.replace(/[^/""]/g, "*");
}

export const getPlural = (str: string, number: number) => {
  if (number > 1) {
     return str + "s";
  } else return str
}; 
