import styled from "styled-components";
import { ColorProps, flexbox, LayoutProps, SpaceProps, layout, space, FlexboxProps, position, PositionProps, border, BorderProps, color } from "styled-system";

const CardWrapperWhite = styled.div<ColorProps & FlexboxProps & LayoutProps & SpaceProps & PositionProps & BorderProps>`

min-width: 300px;
width: 100%;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 10px 15px;
  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`;

export default CardWrapperWhite;
