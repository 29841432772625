import styled, { keyframes } from "styled-components";

export const bounce = keyframes`
to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;

const BouncingDot = styled.div< { color:string , $animationDelay?: string} >`
  width: 8px;
  height: 8px;
  margin: 2px 5px;
  border-radius: 50%;
  background-color: ${(props) => props.color ?? "#ffffff"};
  opacity: 1;
  animation: ${bounce} 0.6s infinite alternate;
  animation-delay: ${(props) => props.$animationDelay?? undefined};
`;

export default BouncingDot;
