import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  display,
  DisplayProps,
} from "styled-system";

interface SVGProps
  extends WidthProps,
    HeightProps,
    SpaceProps,
    PositionProps,
    DisplayProps {
  fill?: string;
  handleClick?: () => void;
}


const StyledSVG = styled.svg<SVGProps>`
  width: ${(props) => props.width || "40px"};
  height: ${(props) => props.height || "30px"};
  ${space}
  ${position}
  ${display}
`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.thirdBase};
`;

function BartsSvg({ ...props }: SVGProps) {
  return (
    <StyledSVG
      onClick={props.handleClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 30"
      {...props}
    >
      <SVGPath
        {...props}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.6 30H0V26.6667H39.6V30ZM39.6 23.2933H0V19.96H39.6V23.2933ZM39.6 16.6667H0V13.3333H39.6V16.6667ZM39.6 10H0V6.66667H39.6V10ZM39.6 0V3.33333H0V0H39.6Z"
      />
    </StyledSVG>
  );
}

export default BartsSvg;
