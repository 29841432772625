import axios, { AxiosError, AxiosResponse } from 'axios'
import { ApiSimpleResponse, CoreResponse, ErrorResponse } from './types/responses';
import { StatusCode } from './statusCode';
import { QueryRequest } from './types/requests';
import { cookies } from 'utils/cookies';

const moooreErrorProcesser=(res:unknown)=>{
let moooreError:{error:string[]}={error:['']}
const newErrorRes= res as any
    moooreError = {...newErrorRes}
   const stringError= moooreError.error[0] 
return stringError
}



//TODO aggiungere withCredential
export const axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_BASE_URL })

/* IMPORTANTE: per l'utilizzo in v1 in questo progetto, le estensioni a CoreResponse sono state modificate con Object*/

const resolveFunc = <TResponse extends Object>(r: AxiosResponse<TResponse>): ApiSimpleResponse<TResponse> => {
    const response = r.data;
    return { isSuccess: true, response }
}

const rejectFunc = (e: AxiosError<ErrorResponse>) => {
    const response = e.response;

    if (!response || response?.status === StatusCode.UNSUPPORTED_MEDIA_TYPE || response?.status >= StatusCode.INTERNAL_SERVER_ERROR) {
        return { isSuccess: false, error: "An unexpected error occurred, retry later" }
    }

    if (response?.status === StatusCode.NOT_AUTHORIZED) {
        return { isSuccess: false, error: "Not Authorized" }
    }
    if (response?.status === StatusCode.NOT_AUTHORIZED_MORE) {
        return { isSuccess: false, error: moooreErrorProcesser(response.data)  }
    }
    if (response?.status === StatusCode.BAD_REQUEST || response?.status === StatusCode.NOT_FOUND) {
        return { isSuccess: false, error: response.data.ErrorMessage }
    }

    const errorDetails = response.data.Details;
    if (!!errorDetails.errors) {
        const [firstError] = Object.keys(errorDetails.errors)

        return { isSuccess: false, error: errorDetails.errors[firstError][0] }
    }
    return { isSuccess: false, error: response.data.Details.detail }
}
/**
* Creates a post call to the endpoint.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const postCall =
    async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return axiosInstance
            .post(endpoint, request, { headers })
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a put call to the endpoint.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const putCall =
    async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return axiosInstance
            .put(endpoint, request, { headers })
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }


/**
* Creates a delete call to the endpoint.
*
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const deleteCall =
    async <TResponse extends CoreResponse>(endpoint: string): Promise<ApiSimpleResponse<TResponse>> => {
        return axiosInstance
            .delete(endpoint)
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

/**
* Creates a get call to the endpoint.
*
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const getCall =
    async <TResponse extends Object, TParams = QueryRequest>(endpoint: string, params?: TParams, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return await axiosInstance
            .get(endpoint, { params, headers })
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }
/**

/**
* Creates a postForm call to the endpoint for uploading file from form.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const postFormCall =
    async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
        return axiosInstance
            .postForm(endpoint, request, { headers })
            .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
            .catch(rejectFunc);
    }

