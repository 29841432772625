import { createGlobalStyle } from 'styled-components';
import BauPro2 from './BauPro.woff2'
import BauPro from './BauPro.woff'
import BauProMedium2 from './BauPro-Medium.woff2'
import BauProBold2 from './BauPro-Bold.woff2'
import BauProMedium from './BauPro-Medium.woff'
import BauProBold from './BauPro-Bold.woff'

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'BauPro';
        src: local('BauPro'), local('BauPro'),
        url(${BauPro2}) format('woff2'),
        url(${BauPro}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'BauPro';
        src: local('BauPro-Medium'), local('BauPro-Medium'),
        url(${BauProMedium2}) format('woff2'),
        url(${BauProMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'BauPro';
        src: local('BauPro-Bold'), local('BauPro-Bold'),
        url(${BauProBold2}) format('woff2'),
        url(${BauProBold}) format('woff');
        font-weight: 600;
        font-style: normal;
    }
`;

export default GlobalFonts