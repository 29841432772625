import ArrowSvg from "../components/atoms/ArrowSvg";
import Flex from "../components/primitives/Flex";
import LoginForm, { FormLogin } from "../components/organisms/LoginForm";
import Txt from "../components/primitives/Txt";
import Button from "../components/primitives/Button";
import { useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "../context/ThemeContext";
import BackgroundVideo from "../components/molecules/VideoBackgroud";
import Box from "../components/primitives/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "redux/features/auth/authSlice";
import { useAppSelector } from "redux/app/hooks";
import { AppGeneralContext } from "context/AppGeneralContext";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { ShippingMethod } from "./ShippingMethod";

export const LoginPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { setIsLoading, setUserId, isMobile, openAsideMenu } = useContext(AppGeneralContext);
  const color = customTheme.colors;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.auth.auth);
  const userIsLoading = useAppSelector((state) => state.user.user.isLoading);
  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);
  const user = useAppSelector((state) => state.user.user.data);
  const [submit, setSubmit] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const { t } = useTranslation();

  const handleBack = () => {
    navigate(-1);
  };

  const forgotPassword = () => {
    navigate("/forgot-password",{state: currentEmail});
  };

  const navigateRegister = () => {
    navigate("/register");
  };

  const onSubmit = (form: FormLogin) => {
    dispatch(authActions.askAuth(form));

    setSubmit(true);
  };

  useEffect(() => {
    setIsLoading(auth.isLoading);
  }, [auth.isLoading]);
  useEffect(() => {
    setIsLoading(userIsLoading);
  }, [userIsLoading]);

  useEffect(() => {
    if (!submit) {
      return;
    }

    if (!auth.data || !user) {
      return;
    }

    if (!auth.data.IsVerified) {
      setUserId(user.Id ?? "");
      navigate("/verify");
      return;
    }

    if (auth.data.IsVerified && !user.CompiledPreferences) {
      navigate(PAGE.firstLogin.menuPath);
      return;
    }

    if (isBuyNow) {
      if (isMobile) {
        navigate(PAGE.shipping.menuPath);
        return;
      }
      openAsideMenu<undefined>(<ShippingMethod />, undefined);
    }
    navigate(PAGE.home.menuPath);
  }, [auth.data, user, submit, isMobile, isBuyNow]);

  useEffect(() => {
    console.log({ currentEmail });
  }, [currentEmail]);

  return (
    <Box width={[1]} height={["100vh"]} maxWidth={customTheme.maxWidth[0]}>
      <Flex flexDirection={"column"} alignItems={"start"} width={[1, 1, 0.7, 0.4]} padding={["0 28px"]} marginTop={"59px"} $gap={1.5}>
        <ArrowSvg handleClick={handleBack} widthCustom='s' />
        <LoginForm onSubmit={onSubmit} width={[1]} setEmail={(value) => setCurrentEmail(value)} />
        <Txt variant='light' $cursor='pointer' $textTransform='inherit' fontSize={[2]} onClick={forgotPassword}>
          {t(i18Enum.Login_ForgotPassword)}
        </Txt>
        <Txt variant='light' fontSize={[3]} color={color.thirdBase}>
          {t(i18Enum.Login_NoAccountCTO)}
        </Txt>
        <Button variant='register' type='button' onClick={navigateRegister}>
          {t(i18Enum.Login_RegisterButton)}
        </Button>
        {/* <Txt
          variant="light"
          $textTransform="inherit"
          $cursor="pointer"
          fontSize={[2]}
          color={color.thirdBase}
          onClick={continueAsGuest}
        >
          {capitalize("continue as guest")}
        </Txt> */}
      </Flex>
      <BackgroundVideo video='first' variant='left' width={[1, 0.6]} height={["100vh"]} top={0} />
    </Box>
  );
};
