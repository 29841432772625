



import styled, { keyframes } from 'styled-components'


export const spin = keyframes`
    0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`





interface LoadingSpinnerProps {
dimension:string
}

const LoadingSpinner = styled.div<LoadingSpinnerProps>`
width: ${props=>props.dimension};
height: ${props=>props.dimension};
border:5px solid transparent;
border-bottom-color: ${props=>props.theme.colors.primarySolid};
border-right-color: transparent;
border-radius: 50%;
display: inline-block;
animation: ${spin} 1s cubic-bezier(0.76, 0.35, 0.2, 0.7) infinite;
  
`

export default LoadingSpinner
