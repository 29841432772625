import { PropsWithChildren, useContext } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import Section from "components/primitives/Section";
import Flex from "components/primitives/Flex";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import { priceNum } from "utils";
import { BasketItem, BasketShopDTO } from "redux/features/cart/CartType";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface ICartRecap {
  items?: BasketItem[];
  shop?: BasketShopDTO;
}

export const CartRecap = ({ shop }: PropsWithChildren<ICartRecap>) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();

  return (
    <Section flexDirection={"column"} borderBottom={`1px solid ${colors.thirdBase}`} padding={"1em 0"} $gap={1}>
      <Txt textAlign={"start"} variant='light' color={colors.primaryText} fontSize={[2]}>
        {shop?.Name}
      </Txt>

      <Box>
        <Flex justifyContent={"space-between"}>
          <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
            {t(i18Enum.Cart_Checkout_Title)}
          </Txt>

          <Box>
            <Txt variant='light' color={colors.primaryText} fontSize={[2]} marginRight={"0.5em"}>
              +
            </Txt>
            <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
              {`${priceNum(shop?.TotalDiscountedPrice)} €`}
            </Txt>
          </Box>
        </Flex>

        <Flex justifyContent={"space-between"}>
          <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
            {t(i18Enum.Orders_Order_Shipping)}
          </Txt>

          <Box>
            <Txt variant='light' color={colors.primaryText} fontSize={[2]} marginRight={"0.5em"}>
              +
            </Txt>
            <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
              {shop?.ShippingOption.FreeShipping && shop.TotalDiscountedPrice >= shop?.ShippingOption.FreeShippingMinAmount! ? "0 €" : `${priceNum(shop?.ShippingOption?.Price)} €`}
            </Txt>
          </Box>
        </Flex>
      </Box>
    </Section>
  );
};
