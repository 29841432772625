import Flex from "components/primitives/Flex"
import Txt from "components/primitives/Txt"
import { CustomThemeContext } from "context/ThemeContext"
import { useContext, useMemo } from "react"
import Slider, { ValueRangeSlider } from "../Slaider"


export interface FilterPriceProp {
  
  selectedMinPrice: number|undefined
  selectedMaxPrice: number|undefined
  range: ValueRangeSlider
  handleRangePrice: (value: ValueRangeSlider) => void
}

export const FilterPrice = (
  {
    selectedMinPrice,
    selectedMaxPrice,
    range,
    handleRangePrice
  }: FilterPriceProp) => {
  const color = useContext(CustomThemeContext).customTheme.colors
 
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex width={[1]} padding={"10px 0"} justifyContent={"space-between"}>
        <Txt
          style={{ cursor: "pointer" }}
          variant={"light"}
          color={color.primaryText}
          textAlign={"left"}
          fontSize={[3]}
          onClick={() => { }}>
          {selectedMinPrice??range.minValue} €
        </Txt>
        <Txt
          style={{ cursor: "pointer" }}
          variant={"light"}
          color={color.primaryText}
          textAlign={"left"}
          fontSize={[3]}
          onClick={() => { }}>
          {selectedMaxPrice ??  range.maxValue} €
        </Txt>
      </Flex>
      <Slider
        rangeMin={0}
        rangeMax={range.maxValue}
        handleValues={handleRangePrice}
      />
    </Flex>
  )
}
