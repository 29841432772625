import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { priceNum, retrieveShopId, retrieveUrlCode, toastType, truncateString  , truncate,
} from "../../utils";
import Flex from "../primitives/Flex";
import CartSvg from "../atoms/CartSvg";
import CardSelected from "./CardSelected";
import CardConfirm from "./CardConfirm";
import Txt from "../primitives/Txt";
import Image from "../primitives/Image";
import Box from "../primitives/Box";
import { IdCard } from "../organisms";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import Skeleton from "components/atoms/Skeleton";
import moment from "moment";
import { AppGeneralContext } from "context/AppGeneralContext";
import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";
import { ICurrentCard, SelectedProduct } from "utils/generalTypes/Frontend";
import { useDispatch } from "react-redux";
import { cartActions } from "redux/features/cart/cartSlice";
import { Cart } from "pages";
import ContainerImage from "components/atoms/ContainerImage";
import { useAppSelector } from "redux/app/hooks";
import { WishListHeartSvg } from "components/atoms/WishListHeartSvg";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";import a from 'assets/img/a.png'

interface CardProps {
  product?: ProductType;
  idShow: IdCard;
  onClick: () => void;
  $gap?: number;
  isHomeCard: boolean;
}
export type StateCard = null | "selected" | "add-to-card" | "navigate";

function Card({ $gap, idShow, onClick, isHomeCard, product }: CardProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const { openAsideMenu, isMobile, addToast } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.auth.auth.data);
  const [stateCard, setStateCard] = useState<StateCard>(null);
  const [currentCard, setCurrentCard] = useState<ICurrentCard | null | undefined>(null);
  const [selected, setSelected] = useState<VariantDTO | undefined>();
  const [productToBasket, setProductToBasket] = useState<SelectedProduct | null>(null);
  const [wishlisted, setWishlisted] = useState<boolean>(currentCard?.productCard?.IsWishlisted ?? false);
  const [showComponent, setShowComponent] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!product || !product.Variants?.length) {
      return;
    }

    const preselectedProduct = product.Variants[0];

    setCurrentCard({
      productCard: preselectedProduct,
      productVariations: product.Variants,
    });
    setSelected(preselectedProduct);
    //setWishlisted(preselectedProduct.isWishlisted)
  }, [product]);

  //TODO verificare

  const isNewProduct = (date: string) => {
    if (!date) {
      return false;
    }
    let addedDate: Date = new Date(date);
    let isNew = moment(addedDate).add(7, "days") >= moment();
    return isNew;
  };

  const handleSelection = useCallback(
    (product?: VariantDTO) => {
      if (!product) {
        return;
      }
      setSelected(product);
    },
    [selected]
  );

  const handleState = useCallback(
    (state: StateCard) => {
      setStateCard(state);

      if (state === "selected") {
        if (!currentCard?.productCard?.Id) {
          return;
        }
        setSelected(currentCard.productCard);
      }
    },
    [stateCard]
  );

  const addToCart = (): void => {
    if (!productToBasket) {
      return;
    }
    dispatch(cartActions.addItemAction(productToBasket));
    handleState("navigate");
  };

  const handleCheckout = (): void => {
    // TODO : navigate to checkout
    if (isMobile) {
      navigate(PAGE.cart.menuPath);
    }
    openAsideMenu<undefined>(<Cart />, undefined);

    handleState(null);
  };

  function handleWishList() {
    setWishlisted((x) => !x);
  }





  function IsSafari() {
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    return isSafari;
  
  }

  return (
    <Box
      width={["100%", "250px"]}
      maxHeight={IsSafari() ? null : ["250px", "30em"]}
      minHeight={["18em"]}
      height={IsSafari() ? ["25em", "30em"] : null}
      marginTop={"1.2em"}
      backgroundColor={colors.secondaryBase}
      position={"relative"}
      overflow={currentCard?.productCard ? "hidden" : "visible"}
      padding={currentCard?.productCard ? "unset" : "10px"}
      $aspectRatio='10 / 18'
    >
      {currentCard?.productCard && (
        <>
          {showComponent ? (
            <ContainerImage>
              <Flex $blur position={"absolute"} zIndex={-1} height={"100%"} width={"100%"}>
                <Image src={currentCard?.productCard?.Images?.[0]?.Url} width={[1]} borderRadius={"16px"} height={"100%"} onClick={onClick} objectFit='cover' />
              </Flex>
              <Image borderRadius={"16px"} src={currentCard?.productCard?.Images?.[0]?.Url} width={[1]} height={"100%"} onClick={onClick} objectFit='cover' />
              <WishListHeartSvg $transform={"rotate(-6deg)"} state={wishlisted} variantId={selected?.Id} handleClick={() => handleWishList()} position={"absolute"} top={"5%"} right={"5%"} />
              {isNewProduct(currentCard.productCard.AddedDate?.toString() ?? "") && (
                <Txt variant='info' position={"absolute"} top={"5%"} left={"5%"} borderRadius={10}>
                  news
                </Txt>
              )}
            </ContainerImage>
          ) : (
            <Skeleton height={"100%"} width={"100%"} borderRadius={10} />
          )}

          <Flex
            position={"absolute"}
            bottom={0}
            height={["30%"]}
            width={[1]}
            backgroundColor={colors.secondaryBase}
            borderTopLeftRadius={[7]}
            borderTopRightRadius={[7]}
            flexDirection={"column"}
            justifyContent='space-between'
            alignItems='start'
            padding={["8% 7%"]}
            borderBottom={"1px solid #1b1b1b"}
            borderRight={"1px solid #1b1b1b"}
          >
            {!!currentCard.productCard.DiscountedPrice && (
              <Txt variant='promo' borderRadius={[10]} position={"absolute"} top={[-7]} left={"4%"} backgroundColor={colors.primaryBg}>
                {t(i18Enum.Shopping_Page_Promo)}
              </Txt>
            )}

            <Txt
              variant='light'
              color={colors.thirdText}
              $cursor={"pointer"}
              fontSize={["12px", 1]}
              textAlign={"left"}
              width={[1]}
              $textOverflowHidden
              onClick={() => {
                //TO DO PASSARE ID
                navigate(`${PAGE.brand.menuPath}/${product?.Shop.Code}`, {
                  state: { id: product?.ShopId },
                });
              }}
            >
              {product?.Shop.Name}
            </Txt>

            <Txt as={"h3"} variant='medium' color={colors.thirdText} fontSize={[2]} textAlign={"left"} width={["100%", "100%"]} onClick={onClick} $cursor="pointer">
              {isMobile ? truncate(product?.Name ?? "", 20) : truncate(product?.Name ?? "", 35)}
            </Txt>

            <Flex $gap={0.5} marginTop={"4%"}>
              <Txt color={colors.thirdText} fontSize={[2]} variant={currentCard.productCard.DiscountedPrice ? "crossed" : "medium"}>
                {currentCard.productCard.Price && `${priceNum(currentCard.productCard.Price)}€`}
              </Txt>

              <Txt variant='medium' color={colors.primaryText} fontSize={[2]}>
                {!!currentCard.productCard.DiscountedPrice && currentCard.productCard.Price && `${priceNum(currentCard.productCard.DiscountedPrice)}€`}
              </Txt>
            </Flex>
          </Flex>
          <ContainerDetails expand={stateCard}>
            {stateCard === null && <CartSvg width='13px' height='17px' handleClick={() => handleState("selected")} />}
            {stateCard === "selected" && (
              <CardSelected
                productVariants={currentCard.productVariations}
                card={currentCard.productCard}
                selected={selected}
                product={product}
                handleClose={() => handleState(null)}
                handleSelection={(product) => handleSelection(product)}
                handleContinue={() => {
                  if (!productToBasket) {
                    addToast(toastType.warning, t(i18Enum.Error_MissingSize));
                    return;
                  }
                  handleState("add-to-card");
                }}
                productToBasket={(product) => setProductToBasket(product)}
              />
            )}

            {(stateCard === "add-to-card" || stateCard === "navigate") && (
              <CardConfirm handleCancel={() => handleState(null)} handleAddToCard={addToCart} handleCheckout={handleCheckout} stateCard={stateCard} />
            )}
          </ContainerDetails>
        </>
      )}
    </Box>
  );
}

export default Card;

const ContainerDetails = styled.div<{ expand: StateCard }>`
  position: absolute;
  z-index: 1;
  right: ${(props) => (props.expand ? " 0" : "-41px")};
  bottom: ${(props) => (props.expand ? " 0" : "-41px")};
  border-radius: ${(props) => (props.expand ? "0 0 0 0" : "50% 0 0 0")};
  height: ${(props) => (props.expand ? "100%" : "82px")};
  width: ${(props) => (props.expand ? "100%" : "82px")};
  padding: ${(props) => (props.expand ? "0 0 0 0" : "16px 0 0 18px")};
  box-sizing: border-box;
  background-color: ${(props) => (props.expand === "add-to-card" || props.expand === "navigate" ? "black" : "#282828")};
  transition: all 0.3s ease-out;
`;
