import styled from "styled-components";

import icon from "../../assets/icons/logoPrincipal.svg";
import iconCollapsed from "../../assets/icons/logoCollapsed.svg";

interface IconProps {
  isLoading: boolean;
}

const Logo = styled.i<IconProps>`

  width: ${(props) => (!props.isLoading ? "83px" : "100%")};
  max-width: 204px;
  height: 41px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
  position: relative;
  left: ${(props) => (!props.isLoading ? "0" : "50%")};
  transform: ${(props) =>
    !props.isLoading ? "translate(0%, 0%)" : "translate(-50%, 130%)"};
  background-image: url(${(props) => (!props.isLoading ? iconCollapsed : icon)});
`;

export default Logo;
