import { getAriesCall } from "api/baseAriesCall";
import { BaseResponse } from "api/types/responses";
import { HomePageCallResponse } from "api/types/responses/HomePageResponse";

export const getHomePageData = async()  =>  await getAriesCall<BaseResponse<HomePageCallResponse>>('Homepage')





// dispatch(DomainValuesActions.getCategoryDomainValues())
// dispatch(DomainValuesActions.getSizeCategories())