import { useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import CartSvg from "../atoms/CartSvg";
import SearchSvg from "../atoms/SearchSvg";
import BartsSvg from "../atoms/BartsSvg";
import CrossSvg from "../atoms/CrossSvg";
import Flex from "../primitives/Flex";
import Grid from "../primitives/Grid";
import Logo from "../molecules/Logo";
import MenuNavbar from "./MenuNavbar";
import { useScrollBlock } from "utils";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { useAppSelector } from "redux/app/hooks";

interface NavbarProps {}

function Navbar({}: NavbarProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const { isLoading, setSearchKeyword } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const path = useLocation();
  const [collapse, setCollapse] = useState(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const navigate = useNavigate();
  const numberOfCartItem = useAppSelector((state) => state.cart.cart.data?.ShoppingBasket?.ItemsCount);

  useEffect(() => {
    if (collapse) {
      blockScroll();
    } else {
      setSearching(false);
      allowScroll();
    }
  }, [collapse]);

  const fnCollapse = () => setCollapse(!collapse);

  const handleCart = () => {
    navigate(PAGE.cart.menuPath);
  };

  useEffect(() => {
    if (path.pathname === PAGE.shopList.menuPath || path.pathname === PAGE.shopFiltered.menuPath || path.pathname === PAGE.shopFilter.menuPath) {
      return;
    } else {
      setSearchKeyword("");
    }
  }, [path]);

  return (
    <Flex
      as={"nav"}
      width={[1]}
      maxHeight={["100vh"]}
      minHeight={[collapse ? "100vh" : "auto"]}
      position={"absolute"}
      zIndex={100000}
      top={"0"}
      backgroundColor={collapse ? colors.secondaryBg : colors.secondaryBgActive}
    >
      <Grid margin={0} padding={0} width={["100%"]} gridTemplateRows={["auto 1fr"]}>
        <Flex padding={["9px 13px"]} alignItems={"center"} justifyContent={"space-between"} width={[1]}>
          <Logo isLoading={isLoading} onClick={() => navigate(PAGE.home.menuPath)} />

          <Flex>
            {!collapse && (
              <>
                <CartSvg number={numberOfCartItem} handleClick={handleCart} />
                <SearchSvg margin={["0 35px"]} width={["29px"]} handleClick={() => navigate(PAGE.shopFilter.menuPath)} />
              </>
            )}

            <BartsSvg handleClick={fnCollapse} width={"40px"} height={"30px"} display={collapse ? "none" : "block"} />

            <CrossSvg handleClick={fnCollapse} width={"40px"} height={"30px"} display={!collapse ? "none" : "block"} />
          </Flex>
        </Flex>
        <Flex width={[1]} height={["100%"]}>
          {collapse && <MenuNavbar handleClick={fnCollapse} searching={searching} />}
        </Flex>
      </Grid>
    </Flex>
  );
}

export default Navbar;
