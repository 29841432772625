import { MutableRefObject, useEffect, useState } from "react";



const useIntersection = (element: MutableRefObject<HTMLDivElement> | any, rootMargin: string) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
      if (element) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setState(entry.isIntersecting);
          }, { rootMargin }
        );

        element?.current && observer.observe(element?.current);
        return () => {
          !!element.current ?? observer.unobserve(element.current)
        };
      }

    }, []);

    return {isVisible};
  };

  export default  useIntersection;
