import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "components/primitives/Button";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface IConfirmDialog {
  description?: string;
  cancelText?: string;
  confirmText?: string;
  customGap?: number;
  hide: () => void;
  deleteCallback: () => void;
}

export const ConfirmDialog = ({ description, cancelText, confirmText, customGap, hide, deleteCallback }: IConfirmDialog) => {
    const { t } = useTranslation();
  return (
    <Flex width={"100%"} justifyContent={"center"} flexDirection={"column"} padding={"10px 0"} $gap={customGap ? customGap : 1}>
      {description && (
        <Txt color={"#fff"} fontSize={"14px"}>
          {description}
        </Txt>
      )}
      <Flex width={"100%"} justifyContent={"center"} $gap={3}>
        <Button variant='cancel' fontSize={[1, 2]} padding={"0 2em"} onClick={() => hide()}>
          {cancelText ? cancelText : t(i18Enum.Common_Cancel)}
        </Button>
        <Button variant='danger' fontSize={[1, 2]} padding={"0 2em"} onClick={() => deleteCallback()}>
          {confirmText ? confirmText : t(i18Enum.Common_Delete)}
        </Button>
      </Flex>
    </Flex>
  );
};
