import React, { useContext } from "react";

import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import HeartSvg from "../atoms/HeartSvg";
import { capitalize, priceNum } from "../../utils";
import { CustomThemeContext } from "../../context/ThemeContext";
import Skeleton from "../atoms/Skeleton";
import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";
import { ReadMoreWrapper } from "components/atoms/ReadMoreWrapper";
import { WishListHeartSvg } from "components/atoms";
interface IProductDetailDescription {
  product?: ProductType | null;
  selectedVariant?: VariantDTO | null;
  wishlisted: boolean;
  handleWishlisted: () => void;
}
export const ProductDetailDescription = ({ product, wishlisted, handleWishlisted, selectedVariant }: IProductDetailDescription) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;

  return (
    <Flex height={"auto"} width={[1]} flexDirection={"column"} alignItems='start' paddingTop={2} position={"relative"} flexGrow={1}>
      <Flex alignItems={"start"} width={"100%"} justifyContent={"space-between"}>
        <Txt variant='light' color={colors.thirdText} fontSize={[2]} textAlign={"left"}>
          {product?.Shop.Name}
        </Txt>
        <WishListHeartSvg variantId={selectedVariant?.Id} state={wishlisted} handleClick={handleWishlisted} right={0} />
      </Flex>
      <Txt as={"h3"} variant='medium' color={colors.thirdText} fontSize={[3]} textAlign={"left"} padding={"4px 0 7px"}>
        {product?.Name}
      </Txt>

      <Flex $gap={0.5}>
        <Txt variant={selectedVariant?.DiscountedPrice ? "crossed" : "medium"} color={colors.thirdText} fontSize={[2]}>
          {selectedVariant?.Price && `${priceNum(selectedVariant.Price)}€`}
        </Txt>

        {!!selectedVariant?.DiscountedPrice && (
          <Txt variant='medium' color={colors.primaryText} fontSize={[2]}>
            {`${priceNum(selectedVariant?.DiscountedPrice)}€`}
          </Txt>
        )}
      </Flex>

      <Txt marginTop={"29px"} variant='light' textAlign={["center", "left"]} color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }}>
        <ReadMoreWrapper lines={2}>{product?.ShortDescription ?? ""}</ReadMoreWrapper>
      </Txt>
    </Flex>
  );
};
