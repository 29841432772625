import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { COMMUNITY, CommunitySearchRequest, CommunityStateType, CommunityUser } from "./CommunityTypes";
import { PaginatedResponse } from "api/types/responses";
import { error } from "console";


const communityInitialState: CommunityStateType = {
    CommunityUser: {
        data: null,
        isLoading: false,
        errors: ''
    },
    CommunityUserList: {
        data: null,
        isLoading: false,
        errors: ''
    },
}
 const communitySlice = createSlice({
    name:COMMUNITY,
    initialState: communityInitialState,
    reducers:{
        getCommunityUser:(state:CommunityStateType,{payload}:PayloadAction<string>)=>{
            state.CommunityUser.isLoading = true;
            state.CommunityUser.errors=''
        },
        getCommunityUserSuccess:(state:CommunityStateType,{payload}:PayloadAction<CommunityUser>)=>{
            state.CommunityUser.isLoading = false;
            state.CommunityUser.data = payload;
            state.CommunityUser.errors=''
        },
        getCommunityUserError:(state:CommunityStateType,{payload}:PayloadAction<string>)=>{
            state.CommunityUser.isLoading = false;
            state.CommunityUser.data = null;
            state.CommunityUser.errors=payload;
        },

        getCommunityUserList:(state:CommunityStateType,{payload}:PayloadAction<CommunitySearchRequest>)=>{
            state.CommunityUserList.isLoading = true;
            state.CommunityUserList.errors = ''
        },
        getCommunityUserListSuccess:(state:CommunityStateType,{payload}:PayloadAction<PaginatedResponse<CommunityUser>>)=>{
            state.CommunityUserList.isLoading = false;
            state.CommunityUserList.errors='';
            const newList = payload.Data
            if(!newList?.length || !payload.Data){
            return
            }
            if (!state.CommunityUserList.data || !state.CommunityUserList.data.Data){
                state.CommunityUserList.data = {
                    Pager: payload.Pager,
                    Data: payload.Data
                }
                return
            }
            const filteredList = newList.filter((item)=>{
                return !state.CommunityUserList.data?.Data?.map((x)=>x.Id).includes(item.Id)
            })
            state.CommunityUserList.data ={
                Data:[...state.CommunityUserList.data.Data,...filteredList],
                Pager:payload.Pager
            }
        },
        getCommunityUserListError:(state:CommunityStateType,{payload}:PayloadAction<string>)=>{
            state.CommunityUserList.isLoading = false;
            state.CommunityUserList.data=null,
            state.CommunityUserList.errors=payload;
        },
        communityResetData:(state:CommunityStateType)=>{
            state.CommunityUserList.data=null;
        }
    }
})

export const communityActions = communitySlice.actions

export default communitySlice.reducer