export type sliderAnimation = {
    [key: string]: sliderFrame
  }
  export type sliderFrame = {
    transform: string,
    zIndex: string,
    aspectRatio: string
  }
  export const transformsMobile: Array<sliderAnimation> = [
    {
      imageFirst: {
        transform: "translate(0%,0%) rotate(8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16"
      },
      imageSecond: {
        transform: "translate(0% , 0%) rotate(-8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16"
      },
      imageThird: {
        transform: "translate(0% , 0%)",
        zIndex: "1",
        aspectRatio: "10 / 16"
      },
    },
    {
      imageFirst: {
        transform: "translate(80%, 0%) rotate(8deg)",
        zIndex: "2",
  
        aspectRatio: "9 / 16"
      },
      imageSecond: {
        transform: "translate(5% , 10%) rotate(-8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16"
      },
      imageThird: {
        transform: "translate(-30% , -20%)",
        zIndex: "1",
        aspectRatio: "10 / 16"
      },
    },
    {
      imageFirst: {
        transform: "translate(30%, 0%) rotate(8deg)",
        zIndex: "1",
  
        aspectRatio: "9 / 16"
      },
      imageSecond: {
        transform: "translate(35% , 10%) rotate(-8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16"
      },
      imageThird: {
        transform: "translate(-40% , 0%)",
        zIndex: "2",
        aspectRatio: "10 / 16"
      },
    },
    {
      imageFirst: {
        transform: "translate(-30%, 0%) rotate(8deg)",
        zIndex: "1",
        aspectRatio: "9 / 16"
      },
      imageSecond: {
        transform: "translate(110% , 10%) rotate(-8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16"
      },
      imageThird: {
        transform: "translate(-40% , 0%)",
        zIndex: "3",
        aspectRatio: "10 / 16"
      },
    },
    {
        imageFirst: {
          transform: "translate(-25%, -10%) rotate(8deg)",
          zIndex: "1",
          aspectRatio: "9 / 16"
        },
        imageSecond: {
          transform: "translate(80% , 5%) rotate(-8deg)",
          zIndex: "2",
          aspectRatio: "9 / 16"
        },
        imageThird: {
          transform: "translate( 0% ,10%)",
          zIndex: "3",
          aspectRatio: "10 / 16"
        },
    },
    {
      imageFirst: {
        transform: "translate(-50%, -10%) rotate(8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16"
      },
      imageSecond: {
        transform:  "translate(100% , 50%) rotate(-8deg)",
        zIndex: "2",
        aspectRatio: "1/ 1"
      },
      imageThird: {
        transform: "translate( 10% ,-20%)",
        zIndex: "1",
        aspectRatio: "10 / 16"
      },
    }
  ];
  
  export const transformsDesktop: Array<sliderAnimation> = [
    {
      imageFirst: {
        transform: "translate(20% , 0%) rotate(8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(15% , -20%) rotate(-8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(15% , 35%)",
        zIndex: "1",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(50% , 0%) rotate(8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(0%, 0%) rotate(-8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(20% , 0%)",
        zIndex: "1",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(20% , 0%) rotate(8deg)",
        zIndex: "1",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(40%, 0%) rotate(-8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(30% , 0%)",
        zIndex: "2",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(50% , 0%) rotate(8deg)",
        zIndex: "1",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(10%, -20%) rotate(-8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(150% , 30%)",
        zIndex: "2",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(30% , 0%) rotate(8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(50%, -20%) rotate(-8deg)",
        zIndex: "1",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(70% , 0%)",
        zIndex: "3",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(70% , -10%) rotate(8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(-50% , -40%) rotate(-8deg)",
        zIndex: "1",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(-40% , 35%)",
        zIndex: "3",
        aspectRatio: "10 / 16",
      },
    },
    {
      imageFirst: {
        transform: "translate(0%,0%) rotate(8deg)",
        zIndex: "3",
        aspectRatio: "9 / 16",
      },
      imageSecond: {
        transform: "translate(0% , 0%) rotate(-8deg)",
        zIndex: "2",
        aspectRatio: "9 / 16",
      },
      imageThird: {
        transform: "translate(0% , 0%)",
        zIndex: "1",
        aspectRatio: "10 / 16",
      },
    },
  ];
  
 