import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CardType } from "../../api/types/responses/cardsRequest";
import { OutfitCard } from "../molecules";
import Flex from "../primitives/Flex";
import Marquee from "react-fast-marquee";
import { CustomThemeContext } from "../../context/ThemeContext";
import Txt from "../primitives/Txt";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export type Outfit = {
  id: string;
  title: string;
  description: string;
  coverImage: string;
  products: CardType[];
  showOutfit?: () => void;
};

export const Outfits = ({ products, title, showOutfit }: Outfit) => {
  const cards: CardType[] = useMemo(() => maxFiveOutfit(products), [products]);
  const { customTheme } = useContext(CustomThemeContext);
  const { t } = useTranslation();
  const color = customTheme.colors;
  const container = useRef<HTMLDivElement | null>(null);
  const [angle, setAngle] = useState<string>("");
  const [bottomAngle, setBottomAngle] = useState<string>("");
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const cardHeight = cards.length >= 4 ? 200 : 250;
  const { isMobile } = useContext(AppGeneralContext);
  const top = wrapperHeight - 0.3 * (cardHeight - 10) * cards.length;
  //min 2 max 5
  function maxFiveOutfit(outfit: CardType[]) {
    if (outfit.length > 5) {
      return outfit.slice(0, 5);
    } else {
      return outfit;
    }
  }

  function getPosition(number: number) {
    let cardNumber: number;
    let position: "first" | "second" | "third" | "fourth";

    if (number >= 4) {
      cardNumber = Math.floor((number + 1) % 4);
    } else {
      cardNumber = number;
    }

    if (cardNumber === 0) {
      position = "first";
    } else if (cardNumber === 1) {
      position = "second";
    } else if (cardNumber === 2) {
      position = "third";
    } else {
      position = "fourth";
    }

    return position;
  }

  const getTotalHeight = useCallback(() => {
    if (container && container.current) {
      const h: number = container.current.offsetHeight;
      setWrapperHeight(h);
    }
  }, [container.current?.offsetHeight]);

  const getAngle = useCallback(() => {
    if (!container || !container.current) {
      return;
    }
    const h: number = wrapperHeight / cards.length;
    const w: number = container?.current?.offsetWidth;
    const angle = Math.atan((0.3 * h) / w);
    const bottomOrientation = (cards.length - 1) % 3 === 1 ? "" : "-";
    setAngle(`${angle}rad`);
    setBottomAngle(`${bottomOrientation}${angle}rad`);
  }, [wrapperHeight, cards.length, container, container.current]);

  useEffect(() => {
    getAngle();
    getTotalHeight();
  }, [getAngle, getTotalHeight]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      getAngle();
      getTotalHeight();
    });
    return () =>
      window.removeEventListener("resize", () => {
        getAngle();
        getTotalHeight();
      });
  }, [window.innerWidth, cards]);

  if (cards.length === 0) {
    return (
      <Flex margin={" 50px 0"}>
        <Txt as={"p"}> {t(i18Enum.Outfit_SelectedOutfit_NoItems)}</Txt>
      </Flex>
    );
  }

  return (
    <Flex
      width={"100%"}
      marginTop={"20px"}
      ref={container}
      height={"100%"}
      flexDirection={["column", "row"]}
      alignItems={["center", "start"]}
      justifyContent={["start", "start"]}
      position={"relative"}
    >
      <Flex width={"100%"} height={"100%"} padding={[null, "0 30px"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
        {isMobile ? (
          <Flex position={"absolute"} top={[2, 0]} left={[null, 0]} zIndex={10} width={"100%"}>
            <Marquee
              direction='right'
              loop={0}
              style={{
                transform: `rotate(-${angle})`,
                transition: "transform 1s",
              }}
            >
              <Txt as={"h2"} color={color.primaryBase}>
                {(" " + title).repeat(5)}
              </Txt>
            </Marquee>
          </Flex>
        ) : (
          <Flex style={{ transform: `translate(-48%) rotate(-90deg) ` }} width={top} top={top / 2 + 50} height={"30px"} position={"absolute"} zIndex={12} left={0} alignItems={"start"}>
            <Marquee direction='left' loop={0} speed={1}>
              <Txt as={"h2"} color={color.primaryBase}>
                {(" " + title).repeat(5)}
              </Txt>
            </Marquee>
          </Flex>
        )}
        {cards.map((card, index) => {
          const image = card.Variants?.[0]?.Images?.[0]?.Url;

          return (
            <OutfitCard
              key={card.Id}
              imageUrl={image ?? ""}
              shop={card.Shop.Name ?? ""}
              productName={card.Name ?? ""}
              price={`${card.Variants?.[0].DiscountedPrice ? card.Variants?.[0].DiscountedPrice : card.Variants?.[0].Price}€`}
              position={getPosition(index) ?? "first"}
              index={index}
              lastIndex={cards.length - 1 === index ? true : false}
              height={cardHeight}
              showOutfit={showOutfit}
            />
          );
        })}

        {isMobile && (
          <Flex position={"absolute"} top={top} width={"100%"} right={0} zIndex={11}>
            <Marquee
              direction='left'
              loop={0}
              style={{
                transform: `rotate(${bottomAngle})`,
                transition: "transform 1s",
              }}
            >
              <Txt as={"h2"} color={color.primaryBase}>
                {title.repeat(5)}
              </Txt>
            </Marquee>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
