import React, { useEffect } from "react";
import Flex from "../primitives/Flex";
import { useScrollBlock } from "../../utils";
import loader from "assets/lotties/loader.json";
import Lottie from "lottie-react";

export const GeneralLoader = () => {
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    blockScroll();
    return () => allowScroll();
  }, []);

  return (
    <Flex position={"absolute"} 
        zIndex={999} 
        top={0} 
        left={0}
        width={"100vw"} 
        height={"100vh"} 
        backgroundColor={"#000000"} 
        justifyContent={"center"} 
        alignItems={"center"}
        overflow={"hidden"}
        >
      <Flex maxWidth={"280px"}>
        <Lottie animationData={loader} autoPlay />
      </Flex>
    </Flex>
  );
};
