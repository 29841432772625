import Txt from "components/primitives/Txt";
import styled from "styled-components";
import { H1 } from "theme/theme.styled";

interface FlippingProps {
  text: string;
}

const FlipContainer = styled.div`
  perspective: 1000px;
`;

const FlipText = styled.div`
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryText};
  transform: rotateX(180deg);

  &:hover {
    transform: rotateX(0deg);
  }
`;

const FlippingText = ({text}: FlippingProps) => {
  return (
    <FlipContainer>
      <FlipText>
        <Txt fontSize={H1}>{text}</Txt>
      </FlipText>
    </FlipContainer>
  );
};

export default FlippingText;
