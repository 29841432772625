import styled from "styled-components";
import { HeightProps, WidthProps, space, SpaceProps, PositionProps, position, height, width, compose, variant } from "styled-system";

interface SVGProps extends WidthProps, HeightProps, SpaceProps, PositionProps {
  fill?: string;
  handleClick?: () => void;
  variant?: "primary" | "contrast";
}

const StyledSVG = styled.svg<SVGProps>`
  aspect-ratio: 1/1;
  ${space};
  ${position};
  ${height};
  ${width}
`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.secondaryBase};
  ${(props) =>
    compose(
      variant({
        variants: {
          primary: {
            fill: "#D9D9D9",
          },
          contrast: {
            fill: props.theme.colors.thirdTextContrast,
          },
        },
      })
    )}
`;

function CalendarSvg({ ...props }: SVGProps) {
  return (
    <StyledSVG onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <SVGPath
        {...props}
        d="M13.3333 13.3333H10.6667V10.6667H13.3333V13.3333ZM9.33333 6.66667H6.66667V9.33333H9.33333V6.66667ZM13.3333 6.66667H10.6667V9.33333H13.3333V6.66667ZM5.33333 10.6667H2.66667V13.3333H5.33333V10.6667ZM9.33333 10.6667H6.66667V13.3333H9.33333V10.6667ZM5.33333 6.66667H2.66667V9.33333H5.33333V6.66667ZM16 1.33333V16H0V1.33333H2V2C2 2.73533 2.598 3.33333 3.33333 3.33333C4.06867 3.33333 4.66667 2.73533 4.66667 2V1.33333H11.3333V2C11.3333 2.73533 11.9313 3.33333 12.6667 3.33333C13.402 3.33333 14 2.73533 14 2V1.33333H16ZM14.6667 5.33333H1.33333V14.6667H14.6667V5.33333ZM13.3333 0.666667C13.3333 0.298667 13.0353 0 12.6667 0C12.298 0 12 0.298667 12 0.666667V2C12 2.368 12.298 2.66667 12.6667 2.66667C13.0353 2.66667 13.3333 2.368 13.3333 2V0.666667ZM4 2C4 2.368 3.702 2.66667 3.33333 2.66667C2.96467 2.66667 2.66667 2.368 2.66667 2V0.666667C2.66667 0.298667 2.96467 0 3.33333 0C3.702 0 4 0.298667 4 0.666667V2Z"
      />
    </StyledSVG>
  );
}

export default CalendarSvg;
