import {
    HeightProps,
    WidthProps,
    SpaceProps,
    PositionProps,
  } from 'styled-system';
  
  interface SocialSVGProps
    extends WidthProps,
      HeightProps,
      SpaceProps,
      PositionProps {
    path: string;
  }
  
  export const SocialIconsSvg = ( props : SocialSVGProps) => {
    return (
      <div style={{ paddingTop: '2px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="21"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d={props.path} />
        </svg>
      </div>
    );
  };