import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListOrdersResponse, OrderDTO, OrderType, USER, UserPreference, UserShippingAddress, UserStateType, UserType } from "./UserTypes";
import { BaseIdRequest, QueryRequest } from "../../../api/types/requests";
import { CreateUpdateBillingAddressRequest, CreateUpdateShippingAddressRequest } from "api/types/requests/shippingAddressRequest";
import { ProductType } from "../product/ProductsTypes";

const userInitialState: UserStateType = {
  user: {
    data: null,
    isLoading: false,
    errors: '',
  },
  userPreference: {
    data: null,
    isLoading: false,
    errors: ''
  },
  userShippingAddresses: {
    data: null,
    isLoading: false,
    errors: '',
    isSuccess: null
    
  },
  userBillingAddresses: {
    data: null,
    isLoading: false,
    errors: '',
    isSuccess: null
  },
  userOrder:{
    data:null,
    isLoading: false,
    errors: '',
    isSuccess: null,
  },
  userOrderParent:{
    data:null,
    isLoading: false,
    errors: '',
    isSuccess: null,
  },
  userWishList: {
    data: null,
    isLoading: false,
    errors: ''
  }

}

export const userSlice = createSlice({
  name: USER,
  initialState: userInitialState,
  reducers: {
    /* This action will trigger our saga middleware
       and set the loader to true and reset error message.
    */
    getUserAction: (state: UserStateType) => {
      state.user.isLoading = true;
      state.user.errors = "";
    },
    getUserSuccessAction: (state: UserStateType, { payload: user }: PayloadAction<UserType>) => {
      state.user.isLoading = false;
      state.user.data = user;
      state.userPreference.data = {
        BirthDate: user.BirthDate,
        Gender: user.Gender,
        FamilyName: user.FamilyName,
        FavoriteColors: user.FavoriteColors,
        FavoriteHobbies: user.FavoriteHobbies,
        FavoriteSocials: user.FavoriteSocials,
        FavoriteSports: user.FavoriteSports,
        FavoriteVibes: user.FavoriteVibes,
        GivenName: user.GivenName,
        Height: user.Height,
        Weight: user.Weight,
        IsPrivate: user.IsPrivate,
        Picture: user.Picture,
        PictureUrl: user.PictureUrl,
      };
    },
    getUserErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.user.isLoading = false;
      state.user.errors = error;
    },
    editUserAction: (state: UserStateType, { payload: user }: PayloadAction<UserType>) => {
      state.user.isLoading = true;
      state.user.errors = "";
    },
    editUserErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.user.isLoading = false;
      state.user.errors = error;
    },
    deleteUserAction: (state: UserStateType) => {
      state.user.isLoading = true;
      state.user.errors = "";
    },
    deleteUserActionSuccess: (state: UserStateType) => {
      state = userInitialState;
    },
    deleteUserActionError: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.user.isLoading = false;
      state.user.errors = error;
    },
    putUserPreferenceAction: (state: UserStateType, { payload: userPreference }: PayloadAction<Partial<UserPreference>>) => {
      state.userPreference.isLoading = false;
      state.userPreference.errors = "";
      state.userPreference.data = { ...state.userPreference.data, ...userPreference };
    },
    sendUserPreferenceAction: (state: UserStateType) => {
      state.userPreference.isLoading = true;
      state.userPreference.errors = "";
    },
    sendUserPreferenceSuccessAction: (state: UserStateType, { payload: user }: PayloadAction<UserType>) => {
      state.userPreference.isLoading = false;
      state.userPreference.errors = "";
      state.user.data = user;
      state.userPreference.data = {
        BirthDate: user.BirthDate,
        Gender: user.Gender,
        FamilyName: user.FamilyName,
        FavoriteColors: user.FavoriteColors,
        FavoriteHobbies: user.FavoriteHobbies,
        FavoriteSocials: user.FavoriteSocials,
        FavoriteSports: user.FavoriteSports,
        FavoriteVibes: user.FavoriteVibes,
        GivenName: user.GivenName,
        Height: user.Height,
        Weight: user.Weight,
        IsPrivate: user.IsPrivate,
        Picture: user.Picture,
        PictureUrl: user.PictureUrl,
      };
    },
    sendUserPreferenceErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userPreference.isLoading = false;
      state.userPreference.errors = error;
    },

    //private
    switchPrivateAccountAction: (state: UserStateType) => {
      state.userPreference.isLoading = true;
      state.userPreference.errors = "";
    },
    switchPrivateAccountErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userPreference.isLoading = false;
      state.userPreference.errors = error;
    },
    //shippingAddress forse da spostare in slice separato??//

    createUserShippingAddressAction: (state: UserStateType, { payload }: PayloadAction<CreateUpdateShippingAddressRequest>) => {
      state.userShippingAddresses.isLoading = true;
      state.userShippingAddresses.errors = "";
      state.userShippingAddresses.isSuccess = null;
      state.userShippingAddresses.isSuccess = null;
    },

    updateUserShippingAddressAction: (state: UserStateType, { payload }: PayloadAction<BaseIdRequest<CreateUpdateShippingAddressRequest>>) => {
      state.userShippingAddresses.isLoading = true;
      state.userShippingAddresses.errors = "";
      state.userShippingAddresses.isSuccess = null;
    },
    getUserAddressesAction: (state: UserStateType, { payload: { isBilling, addressId } }: PayloadAction<{ isBilling: boolean; addressId?: string }>) => {
      if (!isBilling) {
        state.userShippingAddresses.isLoading = true;
        state.userShippingAddresses.errors = "";
        state.userShippingAddresses.isSuccess = null;
        return;
      }
      if (isBilling) {
        state.userBillingAddresses.isLoading = true;
        state.userBillingAddresses.errors = "";
        state.userBillingAddresses.isSuccess = null;
        return;
      }
    },
    setDefaultUserShippingAddressAction: (state: UserStateType, { payload: id }: PayloadAction<string>) => {
      state.userShippingAddresses.isLoading = true;
      state.userShippingAddresses.errors = "";
      state.userShippingAddresses.isSuccess = null;
    },
    manageUserShippingAddressSuccessAction: (state: UserStateType, { payload }: PayloadAction<UserShippingAddress[]>) => {
      state.userShippingAddresses.isLoading = false;
      state.userShippingAddresses.data = payload;
      state.userShippingAddresses.errors = "";
      state.userShippingAddresses.isSuccess = true;
    },
    manageUserShippingAddressErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userShippingAddresses.isLoading = false;
      state.userShippingAddresses.errors = error;
      state.userShippingAddresses.isSuccess = false;
    },
    deleteAddressAction: (state: UserStateType, { payload: { isBilling, addressId } }: PayloadAction<{ isBilling: boolean; addressId: string }>) => {
      if (!isBilling) {
        state.userShippingAddresses.isLoading = true;
        state.userShippingAddresses.errors = "";
        state.userShippingAddresses.isSuccess = null;
        return;
      }
      if (isBilling) {
        state.userOrder.isLoading = true;
        state.userBillingAddresses.isLoading = true;
        state.userBillingAddresses.errors = "";
        state.userBillingAddresses.isSuccess = null;
        return;
      }
    },
    deleteAddressErrorAction: (state: UserStateType, { payload: { isBilling, error } }: PayloadAction<{ isBilling: boolean; error: string }>) => {
      if (!isBilling) {
        state.userShippingAddresses.isLoading = false;
        state.userShippingAddresses.errors = error;
        state.userShippingAddresses.isSuccess = null;
        return;
      }
      if (isBilling) {
        state.userOrder.isLoading = false;
        state.userBillingAddresses.isLoading = false;
        state.userBillingAddresses.errors = error;
        state.userBillingAddresses.isSuccess = null;
        return;
      }
    },
    createUserBillingAddressAction: (state: UserStateType, { payload }: PayloadAction<CreateUpdateBillingAddressRequest>) => {
      state.userBillingAddresses.isLoading = true;
      state.userBillingAddresses.errors = "";
      state.userBillingAddresses.isSuccess = null;
    },

    updateUserBillingAddressAction: (state: UserStateType, { payload }: PayloadAction<BaseIdRequest<CreateUpdateBillingAddressRequest>>) => {
      state.userBillingAddresses.isLoading = true;
      state.userBillingAddresses.errors = "";
      state.userBillingAddresses.isSuccess = null;
    },
    setDefaultUserBillingAddressAction: (state: UserStateType, { payload: id }: PayloadAction<string>) => {
      state.userBillingAddresses.isLoading = true;
      state.userBillingAddresses.errors = "";
      state.userBillingAddresses.isSuccess = null;
    },
    manageUserBillingAddressSuccessAction: (state: UserStateType, { payload }: PayloadAction<UserShippingAddress[]>) => {
      state.userBillingAddresses.isLoading = false;
      state.userBillingAddresses.data = payload;
      state.userBillingAddresses.errors = "";
      state.userBillingAddresses.isSuccess = true;
    },
    manageUserBillingAddressErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userBillingAddresses.isLoading = false;
      state.userBillingAddresses.errors = error;
      state.userBillingAddresses.isSuccess = false;
    },
    getOrdersAction: (state: UserStateType) => {
      state.userOrder.isLoading = true;
      (state.userOrder.errors = ""), (state.userOrder.isSuccess = null);
    },
    getOrdersSuccessAction: (state: UserStateType, { payload: data }: PayloadAction<ListOrdersResponse>) => {
      state.userOrder.isLoading = false;
      state.userOrder.data = data.OrdersList ?? null;
      state.userOrderParent.data = data.OrdersParentList ?? null;
      state.userOrder.errors = "";
      state.userOrder.isSuccess = true;
    },
    getOrdersErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userOrder.isLoading = false;
      state.userOrder.errors = error;
      state.userOrder.isSuccess = false;
      state.userOrder.data = null;
    },
    deleteOrderAction: (state: UserStateType, { payload: id }: PayloadAction<string>) => {
      state.userOrder.isLoading = true;
      (state.userOrder.errors = ""), (state.userOrder.isSuccess = null);
    },
    deleteOrderErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userOrder.isLoading = false;
      state.userOrder.errors = error;
      state.userOrder.isSuccess = false;
      state.userOrder.data = null;
    },
    getWishListAction: (state: UserStateType) => {
      state.userWishList.isLoading = true;
      state.userWishList.errors = "";
    },
    getWishListSuccessAction: (state: UserStateType, { payload: data }: PayloadAction<ProductType[]>) => {
      state.userWishList.isLoading = false;
      state.userWishList.data = data;
      state.userWishList.errors = "";
    },
    getWishListErrorAction: (state: UserStateType, { payload: error }: PayloadAction<string>) => {
      state.userWishList.isLoading = false;
      state.userWishList.errors = error;
    },
    refreshStripeUrlAction: (state: UserStateType, { payload }: PayloadAction<{ id: string; action: (url: string) => void }>) => {
      state.userOrder.isLoading = true;
      state.userOrder.errors = "";
    },
    refreshStripeUrlErrorAction: (state: UserStateType, { payload }: PayloadAction<string>) => {
      state.userOrder.isLoading = false;
      state.userOrder.errors = payload;
    },
    userResetDataAction: (state: UserStateType) => {
      state.user.data = null;
      state.userBillingAddresses.data = null;
      state.userOrder.data = null;
      state.userOrderParent.data = null;
      state.userPreference.data = null;
      state.userShippingAddresses.data = null;
      state.userWishList.data = null;
    }
  },
});

/* errors and success actions will be used inside the saga
  middleware. Only get-**something/somethingById**-Action will be used in a React component.
*/

/* const for easy access in components */
export const UserActions = userSlice.actions

/*const for saga/store's  use */
export const {
  getUserSuccessAction,
  getUserErrorAction,
  getUserAction,
  editUserAction,
  editUserErrorAction,
  deleteUserAction,
  deleteUserActionError,
  deleteUserActionSuccess,
  putUserPreferenceAction,
  sendUserPreferenceAction,
  sendUserPreferenceErrorAction,
  sendUserPreferenceSuccessAction,
  switchPrivateAccountAction,
  switchPrivateAccountErrorAction,
  createUserShippingAddressAction,
  getUserAddressesAction,
  manageUserShippingAddressErrorAction,
  manageUserShippingAddressSuccessAction,
  setDefaultUserShippingAddressAction,
  updateUserShippingAddressAction,
  manageUserBillingAddressErrorAction,
  manageUserBillingAddressSuccessAction,
  getWishListAction,
  getWishListSuccessAction,
  getWishListErrorAction,
  getOrdersAction,
  getOrdersSuccessAction,
  getOrdersErrorAction,
  deleteOrderAction,
  deleteOrderErrorAction,
  deleteAddressAction,
  deleteAddressErrorAction,
  refreshStripeUrlAction,
  refreshStripeUrlErrorAction,
  userResetDataAction,
} = userSlice.actions;
export default userSlice.reducer;