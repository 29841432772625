import ArrowSvg from "components/atoms/ArrowSvg";
import { AddressForm } from "components/organisms/AddressForm";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import React, { useContext,  useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/app/hooks";
import { H6 } from "theme/theme.styled";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
interface AddressState {
  id: string | undefined
}

export const AddressPageEdit = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { isMobile, asideParams, addToast } = useContext(AppGeneralContext);
  const navigate = useNavigate();
  const state: AddressState = useLocation().state;
  const { t } = useTranslation();

  const { data} = useAppSelector((state) => state.user.userShippingAddresses)

  const isEdit = useMemo(() => {
    
    if (isMobile) {
      return state?.id !== undefined ? true : false
    } else {
      return asideParams !== undefined ? true : false;
    }
  }, [isMobile, state, asideParams]);

  const addressToEdit = useMemo(() => {
    if(!isEdit){
      return undefined
    }
    if (isMobile) {
      return data?.find((el)=>el?.Id === state?.id )  
    } else {
      return data?.find((el)=>el?.Id === asideParams ) 
    }
   }, [isEdit, state?.id, asideParams])


  return (
    <Flex width={[1]} flexDirection={"column"} position={"relative"}>
      <Section marginTop={["100px", "0px"]} flexDirection={"row"} alignItems={"center"} padding={"2em 5%"} width={[1]}>
        {isMobile && <ArrowSvg widthCustom='s' top={["10vh"]} handleClick={() => navigate(-1)} marginRight={3} />}
        <Txt as={"h3"} variant='light' fontSize={H6} color={colors.thirdText}>
          {isEdit ? t(i18Enum.User_Shipping_ManageShippingAddress_Label_EditAddress) : t(i18Enum.User_Shipping_ManageShippingAddress_Label_AddAddress)}
        </Txt>
      </Section>
      <Section>
        <AddressForm data={addressToEdit} isEdit={isEdit} />
      </Section>
      {/* { isMobile &&<MobileNavigationBottom
     
        text="save"
        description={isEdit ? "edit address" : "add address"}
      />} */}
    </Flex>
  );
};
