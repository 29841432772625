import { AxiosResponse, AxiosStatic } from 'axios'
import { LocalToken, LoginResponse } from './types/responses'
import { jwtDecode } from "jwt-decode";

export type jwtToken = {
  "primarysid": string,
  "role": string,
  "nbf": number,
  "exp": number,
  "iat": number
}


const AUTH_LOCAL_STORAGE_KEY = 'mooore-k'

const getAuth = (): LoginResponse | undefined => {
  if (!localStorage) {
    return
  }

  const localStorageValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!localStorageValue) {
    return
  }
  
    try {
      const auth: LoginResponse = JSON.parse(localStorageValue) as LoginResponse
      if (auth) {
        var decoded = jwtDecode<jwtToken>(auth.Data);
        var exp = new Date(decoded.exp * 1000);
        if (exp < new Date()) {
          return undefined
        } 
        return auth
      }
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    } 
}

const setAuth = (auth: LocalToken) => {
  if (!localStorage) {
    return
  }

  try {
    const localStorageValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, localStorageValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}



export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }


