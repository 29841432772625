import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  WidthProps,
  width,
  HeightProps,
  height,
  MaxWidthProps,
  maxWidth,
} from "styled-system";

const PopupSlideContainer = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps &
    MaxWidthProps &
    WidthProps &
    HeightProps & { $gap?: number } & { $backgroundImage?: string } & { $aspectRatio?: string }
>`
  display: flex;
  justify-content: center;
  aspect-ratio: ${(props) => props.$aspectRatio};
  transform: rotate(2deg);
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
    ${width}
    ${height}
    ${maxWidth}
`;
export default PopupSlideContainer;
