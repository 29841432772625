import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import Image from "components/primitives/Image";
import { priceNum } from "utils";
import { HiddenCheckbox, StyledCheckbox } from "components/molecules/Checkbox";
import { BasketItem, BasketShopDTO, ShippingOptionDTO } from "redux/features/cart/CartType";
import { UpdateShippingRequest } from "api/types/requests/cartRequest";
import { Link, useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { AppGeneralContext } from "context/AppGeneralContext";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/app/hooks";

interface IShippingCard {
  id: string;
  shopItems?: BasketItem[];
  shop?: BasketShopDTO;
  shippingOptionsList?: ShippingOptionDTO[];
  shippingOptionSelect?: ShippingOptionDTO;
  handleShopTotal?: (_: UpdateShippingRequest) => void;
}

export const ShippingCard = ({ id, shopItems, shop, shippingOptionsList, shippingOptionSelect, handleShopTotal }: PropsWithChildren<IShippingCard>) => {
  const { customTheme } = useContext(CustomThemeContext);
  const { closeAndResetAside, setAsideIsOpen } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(shippingOptionSelect?.Id);
  const [price, setPrice] = useState<number | undefined>(shippingOptionSelect?.Price);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);

  useEffect(() => {
    if (!selectedOptionId) {
      setSelectedOptionId(shippingOptionSelect?.Id);
      return;
    }

    let isFreeShipping = false;
    
    shippingOptionsList?.map((option) => {
      isFreeShipping = option.FreeShipping && (shop?.TotalDiscountedPrice ?? 0) >= option.FreeShippingMinAmount!;
      return isFreeShipping;
    });

    let req: UpdateShippingRequest = {
      ShippingOptionId: selectedOptionId ?? "",
      ShopId: shop?.Id ?? "",
      price: isFreeShipping ? (shop?.TotalDiscountedPrice ?? 0) : (price ?? 0) + (shop?.TotalDiscountedPrice ?? 0),
    };
    handleShopTotal?.(req);
  }, [selectedOptionId, price, shop?.TotalDiscountedPrice]);

  function continueShopping(shopId: string) {
    setAsideIsOpen(false);
    navigate(`${PAGE.brand.menuPath}/${shop?.Name}`, {
      state: { id: shopId },
    });
  }

  function checkShipping(par: UpdateShippingRequest) {
    setSelectedOptionId(par.ShippingOptionId);
    setPrice(par.price);
  }

  return (
    <>
      {shop?.Id && shopItems?.length && (
        <Section key={shop.Id} flexDirection={"column"} $gap={1} padding={["0", "0 10%"]} marginBottom={"10%"} width={[1]}>
          <Txt textAlign={"start"} as={"h3"} variant='medium' color={colors.thirdText} fontSize={[3]} width={[1]} margin={"0 0 0 0%"}>
            {shop?.Name}
          </Txt>

          <Flex flexDirection={"column"}>
            <Flex flexDirection={"row"} width={"100%"} flexWrap={"wrap"}>
              {shopItems.map((item) => (
                <Flex key={item.Id} flexGrow={1} maxWidth={["calc(100% / 7)", "calc(100% / 10)"]} $aspectRatio='1/1' border={`1px ${colors.thirdBase}`} borderStyle={"solid solid none"}>
                  <Image width={"100%"} src={item.Images?.[0]?.Url} />
                </Flex>
              ))}
            </Flex>

            <Section width={"100%"} border={`1px solid ${colors.thirdBase}`}>
              <Flex width={"100%"} flexDirection={"column"} padding={4} $gap={1.5}>
                {shippingOptionsList &&
                  shippingOptionsList?.length > 0 &&
                  shippingOptionsList.map((option) => {
                    let isFreeShipping = option.FreeShipping && shop.TotalDiscountedPrice >= option.FreeShippingMinAmount!;

                    const checkboxId = "check" + id + "_" + option.Id;

                    return (
                      <Flex key={`${shop.Id}_${option.Id}`} justifyContent={"space-between"}>
                        <Flex maxWidth={"80%"} $gap={0.5}>
                          <HiddenCheckbox
                            type='checkbox'
                            checked={selectedOptionId === option.Id}
                            id={checkboxId}
                            name={option.Name}
                            value={selectedOptionId}
                            onChange={() =>
                              checkShipping({
                                ShippingOptionId: option.Id,
                                ShopId: id,
                                price: isFreeShipping ? 0 : option.Price,
                              })
                            }
                          />
                          <StyledCheckbox height={14} htmlFor={checkboxId} checked={selectedOptionId === option.Id} />

                          <Txt textAlign={"start"} variant='light' color={colors.thirdText} fontSize={[2]}>
                            {option.Name}
                          </Txt>
                          {/* {option.FreeShipping && (
                            <Txt textAlign={"start"} variant='linkLight' color={colors.thirdText} opacity={0.6} fontSize={[1]}>
                              {`(${t(i18Enum.Cart_Shipping_Label_FreeShipping)} ${option.FreeShippingMinAmount} €)`}
                            </Txt>
                          )} */}
                        </Flex>
                        <Flex>
                          <Txt variant={option.FreeShipping && shop.TotalDiscountedPrice >= option?.FreeShippingMinAmount! ? "crossed" : "medium"} color={colors.thirdText} fontSize={[2]}>
                            {option?.Price && `${priceNum(option?.Price ?? 0)}€`}
                          </Txt>

                          {option.FreeShipping && shop.TotalDiscountedPrice >= option?.FreeShippingMinAmount! && (
                            <Txt variant='medium' color={colors.primaryText} fontSize={[2]} marginLeft={"5px"}>
                              {`${priceNum(0)}€`}
                            </Txt>
                          )}
                        </Flex>
                        {/* <Txt
                          variant="light"
                          color={colors.primaryText}
                          fontSize={[2]}
                        >
                         {option.FreeShipping && (shop.TotalDiscountedPrice >= option?.FreeShippingMinAmount!)? 0: priceNum(option.Price)} €
                        </Txt> */}
                      </Flex>
                    );
                  })}

                {(shop.TotalDiscountedPrice <= 50) || shop.ShippingOptionsList?.filter(option => !option.FreeShipping) && (
                  <Txt
                    textAlign={"start"}
                    $textTransform='inherit'
                    variant={isBuyNow ? "light" : "linkLight"}
                    color={colors.primaryText}
                    fontSize={[2]}
                    onClick={() => (isBuyNow ? null : continueShopping(shop?.Id))}
                  >
                    {t(i18Enum.Cart_Shipping_FreeShipping_Desc)}
                  </Txt>
                )}
                <Flex flexDirection={["column", "row"]} justifyContent={["start", "space-between"]} $gap={1}>
                  <Flex justifyContent={"start"} $gap={0.2}>
                    <Txt textAlign={"start"} variant='light' $textTransform='inherited' color={colors.primaryText} fontSize={[2]}>
                      {selectedOptionId ? t(i18Enum.Cart_Shipping_TotalWithShipping) : t(i18Enum.Cart_Shipping_Total)}:
                    </Txt>
                    <Txt textAlign={"start"} variant='medium' color={colors.primaryText} fontSize={[2]}>
                      {priceNum((price ?? 0) + shop.TotalDiscountedPrice)}€
                    </Txt>
                  </Flex>
                  <Link target='_blank' to={PAGE.shippings.menuPath}>
                    <Txt textAlign={["start", "end"]} variant='linkLight' color={colors.thirdText} fontSize={[2]}>
                      {t(i18Enum.Cart_Shipping_Terms)}
                    </Txt>
                  </Link>
                </Flex>
              </Flex>
            </Section>
          </Flex>
        </Section>
      )}
    </>
  );
};
