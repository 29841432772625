import Box from "../primitives/Box";
import BackgroundVideo from "../molecules/VideoBackgroud";
import CTACardButton from "../molecules/CTACardButton";

export interface CardsButton {
  name: string;
  value: string;
}
export interface ListCTACardProps {
  handleClick: (value: string) => void;
  cards: CardsButton[];
}

function ListCTACardButton({ cards, handleClick }: ListCTACardProps) {
  return (
    <Box position={"relative"} width={[1]} margin={["4.8vw 0"]}>
      <BackgroundVideo
        variant="center"
        video={"first"}
        width={[1]}
        transform={"skewY(-5.5deg)"}
      />
      {cards.map((card, index) => (
        <CTACardButton
        key={card.name}
          childTwo={!!index}
          grandient={true}
          txt={card.name}
          fontSize={[3]}
          onClick={() => handleClick(card.value)}
        />
      ))}
    </Box>
  );
}

export default ListCTACardButton;
