import styled from "styled-components";
import {
  WidthProps,
  flexDirection,
  FlexDirectionProps,
  FlexboxProps,
  SpaceProps,
  space,
  textAlign,
  TextAlignProps,
  FlexProps,
  flex,
  display,
  DisplayProps,
  HeightProps,
  height,
  width,
  flexbox,
} from "styled-system";

const Ul = styled.ul<
  WidthProps &
    SpaceProps &
    TextAlignProps &
    FlexProps &
    DisplayProps &
    FlexboxProps &
    FlexDirectionProps &
    HeightProps
>`
  ${space}
  ${textAlign}   
 ${flex}
 ${display}
 ${flexDirection}
 ${height}
 ${width}
 ${flexbox}
list-style: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export default Ul;
