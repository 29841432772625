import BackgroundVideo from "components/molecules/VideoBackgroud";
import GridCard, { CardView } from "components/organisms/GridCard";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { H3, H5, H6 } from "theme/theme.styled";
import Grid from "assets/icons/grid.svg?react";
import Column from "assets/icons/column.svg?react";
import useIntersection from "utils/hooks/useIntersection";
import { CardType } from "api/types/responses/cardsRequest";
import Lottie from "lottie-react";
import loader from "assets/lotties/loader.json";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { ProductActions } from "redux/features/product/productSlice";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { PAGE } from "utils/const/pagePath";
import SearchPageDesktop from "./SearchPageDesktop";
import { useNavigate } from "react-router-dom";
import { ScrollToTop } from "components/primitives";
import ArrowSvg from "components/atoms/ArrowSvg";
import { Tags } from "redux/features/product/ProductsTypes";
import { ErrorComponent } from "components/molecules";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import useDataLayer from "utils/hooks/useDataLayer";

interface CardList {
  pageNum: number;
  pageSize: number;
  orderBy: string;
  orderDirection: string;
  data: CardType[];
}
const initialSearchParams: ProductsListRequest = {
  Name: undefined,
  MinPrice: null,
  MaxPrice: null,
  TagsIds: [],
  OrderBy: "",
  OrderDirection: "desc",
  PageNum: 1,
  PageSize: 12,
  IsFiltering: false,
  IsPromo: false,
};

const Shop = () => {
  //context data
  const { customTheme } = useContext(CustomThemeContext);
  const { setResetShopFilter, resetShopFilter, isMobile, filterList, openAsideMenu, shopParamsBackup, setShopParamsBackup } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const { t } = useTranslation();
  //hook assignment
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const genderSection = useRef<HTMLDivElement | null>(null);
  const inViewGenderSection = useIntersection(genderSection, "5px");
  const bottom = useRef<HTMLDivElement | null>(null);
  const inViewport = useIntersection(bottom, "500px");
  //redux selectors
  const { isLoading, errors } = useAppSelector((state) => state.product.productList);
  const productsList = useAppSelector((state) => state.product.productList.data?.Data);
  const pager = useAppSelector((state) => state.product.productList.data?.Pager);
  //useState data
  const [categoryIsOpen, setCategoryIsOpen] = useState<boolean>(false);
  const [cardView, setCardView] = useState<CardView>(CardView.card);
  const [searchParams, setSearchParams] = useState<ProductsListRequest>(initialSearchParams);

  useEffect(() => {
    const viewItemList = productsList?.map((product) => {
      return {
        item_id: product.Id,
        item_name: product.Name,
        item_brand: product.Shop.Name,
        index: product.Order,
        item_variant: product.Variants?.[0].Sku,
      };
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: viewItemList,
      },
    });
    console.log({ viewItemList})
  }, [productsList]);

  useEffect(() => {
    if (!filterList?.OnHeader?.[0]?.Id) {
      return;
    }
    if (resetShopFilter) {
      setSearchParams({ ...initialSearchParams, TagsIds: [filterList.OnHeader[0].Id] });
      setShopParamsBackup({ ...initialSearchParams, TagsIds: [filterList.OnHeader[0].Id] });
      setResetShopFilter(false);
    }

    return () => {
      setShopParamsBackup(searchParams);
    };
  }, [searchParams, resetShopFilter]);

  //set filters
  const headerFilters: Tags[] | undefined = useMemo(() => {
    return filterList?.OnHeader;
  }, [filterList?.OnHeader?.length]);

  const exploreCategoriesList: Tags[] | undefined = useMemo(() => {
    return filterList?.ExploreByCategory;
  }, [filterList?.ExploreByCategory?.length]);

  //setSearchParams to default Value
  useEffect(() => {
    if (!filterList?.OnHeader?.[0]?.Id) {
      return;
    }
    if (resetShopFilter) {
      return;
    }
    if (shopParamsBackup) {
      setSearchParams(shopParamsBackup);
      return;
    }

    setSearchParams({ ...searchParams, TagsIds: [filterList.OnHeader[0].Id] });
  }, [filterList?.OnHeader, resetShopFilter]);

  //default call on mount
  useEffect(() => {
    if (resetShopFilter) {
      dispatch(ProductActions.searchProductResetData());
      reloadShopPage();
    }
    if (productsList && productsList.length > 0) {
      return;
    }
    if (!filterList?.OnHeader.length) {
      return;
    }

    dispatch(ProductActions.searchProductAction({ ...initialSearchParams, TagsIds: filterList?.OnHeader?.[0].Id ? [filterList?.OnHeader?.[0].Id] : [] }));
  }, [filterList?.OnHeader.length, resetShopFilter]);

  const reloadShopPage = () => {
    dispatch(ProductActions.searchProductAction({ ...initialSearchParams, TagsIds: filterList?.OnHeader?.[0].Id ? [filterList?.OnHeader?.[0].Id] : [] }));
  };

  const filterTagIds = useCallback(
    (id?: string) => {
      const onHeaderTags = ["1e79cf13-41c3-4440-83fd-243bbf2985a0", "71474e0c-d77d-4525-91bb-a8176dba7b97", "c591aab6-4f1c-4b6b-8935-ed1eefc27a07"];
      if (!id) {
        return [];
      }

      if (!searchParams.TagsIds || !searchParams.TagsIds.length) {
        return [id];
      }
      if (onHeaderTags.includes(id)) {
        const updatedTags = searchParams.TagsIds.filter((tagId) => !onHeaderTags.includes(tagId));
        updatedTags.push(id);
        return updatedTags;
      } else {
        const filteredTags = searchParams.TagsIds.filter((tagId) => onHeaderTags.includes(tagId));
        const updatedTags = filteredTags.includes(id) ? filteredTags.filter((tagId) => tagId !== id) : [...filteredTags, id];
        return updatedTags;
      }
    },
    [searchParams.TagsIds]
  );

  //fast filter Calls
  const handleFastFilters = useCallback(
    (isPromoBtn: boolean, id?: string, isPromo?: boolean) => {
      if (isPromoBtn) {
        dispatch(ProductActions.searchProductAction({ ...searchParams, IsPromo: isPromo, IsFiltering: true }));
        setSearchParams({ ...searchParams, IsFiltering: false, IsPromo: isPromo });
        return;
      }
      const req: ProductsListRequest = {
        MinPrice: null,
        MaxPrice: null,
        TagsIds: filterTagIds(id),
        OrderBy: "",
        OrderDirection: "desc",
        PageNum: 1,
        PageSize: 12,
        IsFiltering: true,
        IsPromo: searchParams.IsPromo,
      };
      dispatch(ProductActions.searchProductAction(req));
      setSearchParams({ ...req, IsFiltering: false });
      window.scrollTo(0, 0);
    },
    [searchParams.IsPromo, searchParams?.TagsIds]
  );

  //infinite scroll
  useEffect(() => {
    if (!!inViewport?.isVisible && !isLoading) {
      if (!pager?.TotalPages || pager?.PageNum >= pager?.TotalPages) {
        return;
      }
      const newPage = pager?.PageNum + 1;
      dispatch(ProductActions.searchProductAction({ ...searchParams, PageNum: newPage }));
      setSearchParams((prev) => ({ ...prev, PageNum: newPage }));
    }
  }, [inViewport.isVisible]);

  //navigation function
  function openFilter() {
    if (isMobile) {
      navigate(PAGE.shopFilter.menuPath);
      return;
    }
    openAsideMenu<undefined>(<SearchPageDesktop />, undefined);
  }

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
      <Section ref={genderSection} minHeight={"200px"} padding={"0 5%"} width={[1]} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} position={"relative"}>
        <Flex maxWidth={[maxW[0]]} width={[1]} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          <Txt as={"h5"} width={[1]} fontFamily={"BauPro"} fontWeight={"500"} fontSize={H3}>
            {t(i18Enum.Shopping_Page_Title)}
          </Txt>

          <Flex width={["100%", "60%", "40%"]} marginTop={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} $gap={1}>
            {headerFilters?.map((filter) => (
              <Button
                key={filter.Id}
                padding={"0.5em 1.5em"}
                variant={filter.Id && searchParams.TagsIds?.includes(filter.Id) ? "option" : "switcherGenre"}
                onClick={() => {
                  handleFastFilters(false, filter.Id);
                }}
              >
                {filter.Label}
              </Button>
            ))}
          </Flex>
        </Flex>
        {/* <BackgroundVideo variant={"left"} video={"second"} width={"100%"} height={"100%"} bottom={0} opacity={0.4} /> */}
      </Section>
      <Section
        width={[1]}
        flexDirection={"column"}
        alignItems={"center"}
        padding={["3% 5%", "2% 5%"]}
        position={"sticky"}
        top={[0, "80px"]}
        zIndex={30}
        backgroundColor={colors.secondaryBase}
        borderTop={"1px solid white"}
        borderBottom={"1px solid white"}
      >
        <Flex width={[1]} maxWidth={[maxW[0]]} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Txt as={"h5"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={H6}>
            {t(i18Enum.Shopping_Page_ExploreByCategory)}
          </Txt>

          {isMobile && exploreCategoriesList?.length && exploreCategoriesList?.length > 2 && (
            <Txt onClick={() => setCategoryIsOpen((x) => !x)} variant='linkDanger' color={colors.primaryBase} as={"span"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={[2]}>
              {categoryIsOpen ? t(i18Enum.Shopping_Page_ViewLess) : t(i18Enum.Shopping_Page_ViewMore)}
            </Txt>
          )}
        </Flex>
        <Flex width={["100%"]} paddingTop={3} maxWidth={[maxW[0]]} flexDirection={"row"} justifyContent={["end", "start"]} flexWrap={"wrap"} alignItems={"center"} $transition='all 2s' $gap={0.5}>
          <Button
            padding='10px 16px'
            variant={
              searchParams.TagsIds?.every((x) => {
                const categoriesIds = exploreCategoriesList?.map((t) => t.Id) ?? [];
                return !categoriesIds.includes(x);
              })
                ? "option"
                : "switcherGenre"
            }
            onClick={() => handleFastFilters(false, undefined)}
          >
            {t(i18Enum.Shopping_Page_ViewAllButton)}
          </Button>
          {exploreCategoriesList?.map((category) => {
            if (!categoryIsOpen && exploreCategoriesList.length > 2 && isMobile) {
              return;
            }
            return (
              <Button
                key={category.Id}
                padding='10px 20px'
                margin={"5px 0 5px 5px"}
                variant={category.Id && searchParams.TagsIds?.includes(category.Id) ? "option" : "switcherGenre"}
                onClick={() => {
                  handleFastFilters(false, category.Id);
                }}
              >
                {category.Label}
              </Button>
            );
          })}
          <Flex $gap={0.5}>
            {filterList?.IsPromo && (
              <Button
                selected={searchParams.IsPromo}
                padding='10px 20px'
                margin={"5px 0 5px 5px"}
                variant={"promo"}
                onClick={() => {
                  handleFastFilters(true, undefined, !searchParams.IsPromo);
                }}
              >
                {t(i18Enum.Shopping_Page_Promo)}
              </Button>
            )}
            <Button style={{ borderRadius: "50px" }} padding='10px 20px' margin={"5px 0 5px 5px"} variant='addToCart' onClick={() => navigate(PAGE.brandsList.menuPath)}>
              {t(i18Enum.Brands_BrandsList_Button)}
            </Button>
          </Flex>
        </Flex>
        {/* MULTI SELCTION */}
        {/* <Flex width={["100%"]} paddingTop={3} maxWidth={[maxW[0]]} flexDirection={"row"} justifyContent={["end", "start"]} flexWrap={"wrap"} alignItems={"center"} $transition='all 2s'>
                    <Button padding="10px 16px" variant={categoryIDs.length === 0 ? "option" : "switcherGenre"}
                        onClick={() => SwitchCategoryId(undefined)}>VIEW ALL</Button>
                    {categoriesList?.map((category, index) => {
                        if(!category.key) {return<></>}
                        let isPromo: boolean = category.value.includes("PROMO") && !categoryIDs.includes(category.key)
                        if (!categoryIsOpen && index > 2 && isMobile) {
                            return
                        }
                        return <Button key={category.key} padding="10px 20px" margin={"5px 0 5px 5px"} variant={categoryIDs.includes(category.key) ? "option" : "switcherGenre"}
                            onClick={() => SwitchCategoryId(category.key)} style={isPromo ? { boxShadow: colors.yellowShadow } : {}} color={isPromo ? colors.secondaryBase : null} backgroundColor={isPromo ? colors.yellow : null}>{category.value}</Button>
                    })}
                </Flex> */}
      </Section>
      <Section width={[1]} maxWidth={[null, maxW[0]]} padding={["3% 5%", "2% 5%", "2% 5%", "2% 0"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
        {errors !== undefined && errors !== "" && <ErrorComponent errors={errors} onClickHandler={reloadShopPage} />}
        {!errors && !isLoading && productsList && productsList.length > 0 && (
          <Flex paddingBottom={["3%", "2%"]} maxWidth={[maxW[0]]} width={[1]} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Txt as={"h6"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={H5}>
              {t(i18Enum.Shopping_Page_MostViewed)}
            </Txt>
            <Flex flexDirection={"row"} justifyContent={"end"} alignItems={"center"}>
              <Column
                stroke={cardView === CardView.images ? colors.primaryBase : colors.thirdBase}
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => {
                  setCardView((x) => (x === CardView.images ? CardView.card : CardView.images));
                }}
              />
              <Grid
                stroke={cardView === CardView.card ? colors.primaryBase : colors.thirdBase}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCardView((x) => (x === CardView.card ? CardView.images : CardView.card));
                }}
              />
            </Flex>
          </Flex>
        )}
        {!errors && !isLoading && !productsList?.length && <Txt>{t(i18Enum.Shopping_Page_Error_NoResults)}</Txt>}
      </Section>

      {productsList && productsList.length > 0 && (
        <Section width={[1]} maxWidth={[null, maxW[0]]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
          <GridCard isHomeCard={false} products={productsList} variant={cardView} />
        </Section>
      )}
      <Section ref={bottom} width={["50%", "20%"]} maxWidth={[null, maxW[0]]} padding={["5% 0"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"} />
      {isLoading && (
        <Section width={["50%", "20%"]} maxWidth={[null, maxW[0]]} padding={["5% 0"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
          <Lottie animationData={loader} autoPlay />
        </Section>
      )}
      {isMobile && (
        <Section
          position={"fixed"}
          zIndex={20}
          bottom={inViewGenderSection.isVisible ? "-80px" : 0}
          backgroundColor={colors.secondaryBase}
          height={"80px"}
          $transition={"bottom 2s"}
          width={1}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ boxShadow: "-8px 0 10px #0b0b0bb8" }}
        >
          <Flex width={["70%", "60%", "40%"]} marginTop={2} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} style={{ transition: "opacity 1s" }}>
            {headerFilters?.map((filter) => (
              <Button
                key={filter.Id}
                padding='10px 16px'
                margin={"0 5px"}
                variant={filter.Id && searchParams.TagsIds?.includes(filter.Id) ? "option" : "switcherGenre"}
                onClick={() => {
                  handleFastFilters(false, filter.Id);
                }}
              >
                {filter.Label}
              </Button>
            ))}
          </Flex>
        </Section>
      )}
      <ScrollToTop scrollY={400}>
        <ArrowSvg $transform='rotate(90deg);'></ArrowSvg>
      </ScrollToTop>
    </Flex>
  );
};
export default Shop;
