import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/routes";
import { ToastContainer } from "react-toastify";
import { AppGeneralContext } from "./context/AppGeneralContext";
import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { CustomThemeContext } from "./context/ThemeContext";
import GlobalStyle from "./theme/globalStyle";
import GlobalFonts from "./assets/fonts/fonts";
import "react-toastify/dist/ReactToastify.css";
import { getHomePageData } from "api/contentCalls/homePageCall";
import { getFilterTagsCall } from "api/categoriesCall/filterCall";
import { toastType } from "utils";
import { getAuth } from "api/authHelpers";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { askAuthSuccessAction } from "redux/features/auth/authSlice";
import { getUserAction } from "redux/features/user/userSlice";
import { cartActions } from "redux/features/cart/cartSlice";
import { OutfitsActions } from "redux/features/outfits/outfitsSlice";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import Modal from "components/organisms/Modal/Modal";

Sentry.init({
  dsn: "https://3d323ac3db146efa04acc8a2129cbb7b@us.sentry.io/4506699041472512",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/apimooore.ariestech\.it\//],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [/^https:\/\/apimooore.ariestech\.it\//],
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const {
    filterList: categoriesTag,
    isMobile,
    isLoading,
    addToast,
    setIsLoading,
    setParsedSpicyProduct,
    setSizeCategories,
    setFilterList: setCategories,
    setHomepageSliderContent,
    setHomepagePopup,
  } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const [initializing, setInitializing] = useState<boolean>(true);
  const outfit = useAppSelector((state) => state.outfits.homePageOutfit.data);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const InitializeApp = async () => {
    const jwt = getAuth();
    if (!!jwt) {
      dispatch(askAuthSuccessAction(jwt));
      dispatch(getUserAction());
    }
    const homeDataResp = await getHomePageData();
    dispatch(cartActions.getShoppingBasketAction());
    const catResp = await getFilterTagsCall();
    if (!catResp.isSuccess || !homeDataResp.isSuccess || !homeDataResp.response?.Data) {
      setInitializing(false);
      addToast(toastType.error, t(i18Enum.Error_Occurred));
      return;
    } else {
      setParsedSpicyProduct(homeDataResp.response?.Data?.Products);
      setHomepageSliderContent(homeDataResp.response.Data.Slider);
      setHomepagePopup(homeDataResp.response.Data.Popup);
      // setOutfits(homeDataResp.response?.Data?.Outfits)
      dispatch(OutfitsActions.setOutfitsHomePageAction(homeDataResp.response.Data.Outfits[0]));
      setCategories(catResp.response?.Data);
      setInitializing(false);
    }
  };
  useEffect(() => {
    setIsLoading(initializing);
  }, [initializing]);

  useEffect(() => {
    InitializeApp();
  }, []);
  

  return (
    <ThemeProvider theme={customTheme}>
      <GlobalFonts />
      <GlobalStyle theme={customTheme} />

      <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />

      <ToastContainer
        position={isMobile ? "bottom-right" : "top-right"}
        theme='dark'
        icon={({ type }) => {
          if (type === "success") return "✌🏻";
          if (type === "error") return "🚩";
          if (type === "warning") return "⚠️";
          else return "🫶🏼";
        }}
      />

      <Modal />
    </ThemeProvider>
  );
}

export default App;
