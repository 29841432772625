import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WARDROBE,  WardrobeStateType } from "./WardrobeTypes";

import { toast } from "react-toastify";
import { SearchKey } from "utils";
import { WardrobeItem } from "utils/generalTypes/wardrobe";
import { PaginatedResponse } from "api/types/responses";

const wardrobeInitialState: WardrobeStateType = {
    wardrobe: {
        data: null,
        isLoading: false,
        errors: '',
    }
}
export type getWardrobeActionPayload = {
    isFirstLoad: boolean,
    isRefinement: boolean,
    usage: SearchKey
}
export const wardrobeSlice = createSlice({
    name: WARDROBE,
    initialState: wardrobeInitialState,
    reducers: {
        getWardrobeAction: (state: WardrobeStateType, {payload}: PayloadAction<getWardrobeActionPayload>) => {
            state.wardrobe.isLoading = true,
                state.wardrobe.errors = ''
        },
        getWardrobeSuccessAction: (state: WardrobeStateType, { payload }: PayloadAction<PaginatedResponse<WardrobeItem>>) => {
            state.wardrobe.isLoading = false;
            const newItems = payload.Data
            if(!newItems?.length || !state.wardrobe.data?.Data){
                return
            }
            state.wardrobe.data.Data = [ ...state.wardrobe.data.Data ,...newItems]
          
        },
        getWardrobeErrorAction: (state: WardrobeStateType, { payload: error }: PayloadAction<string>) => {
            state.wardrobe.errors = error,
            state.wardrobe.isLoading = false
        
        },
        wardrobeResetData: (state: WardrobeStateType) => {
            state.wardrobe.data = {
                Data:[],
                Pager:{
                    PageNum: 0,
                    PageSize: 0,
                    TotalItems: 0,
                    TotalPages: 0,
                    Links: []
                },
            };
         

        }

    }
})
/* const for easy access in components */
export const WardrobeActions= wardrobeSlice.actions
/*const for saga/store's  use */
export const {
    getWardrobeAction,
    getWardrobeErrorAction,
    getWardrobeSuccessAction,
    wardrobeResetData
} = wardrobeSlice.actions;
export default wardrobeSlice.reducer