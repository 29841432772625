import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { priceNum, toastType } from "utils";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import { H6 } from "theme/theme.styled";
import Section from "components/primitives/Section";
import { OrderSentCard } from "components/molecules/OrderSentCard";
import { useLocation, useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import Box from "components/primitives/Box";
import Button from "components/primitives/Button";
import ArrowSvg from "components/atoms/ArrowSvg";
import { ErrorNoItems } from "components/molecules/ErrorNoItems";
import { OrderDTO, UserShippingAddress } from "redux/features/user/UserTypes";
import { StatusOrder } from "components/molecules/Order";
import moment from "moment";
import { PAGE } from "utils/const/pagePath";
import { useDispatch } from "react-redux";
import { deleteOrderAction } from "redux/features/user/userSlice";
import { ChangeAddress } from "components/molecules/ChangeAddress";
import { updateOrderAddressCall } from "api/userCalls/userCalls";
import { UpdateOrderAddressReq } from "api/types/requests/shippingAddressRequest";
import CardWrapperWhite from "components/primitives/CardWrapperWhite";
import { DotsLoader } from "components/molecules/DotsLoader";
import Accordion from "components/molecules/Accordion";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export const OrdersParentDetailsPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const {
    setAsideIsOpen,
    isMobile,
    asideParams,
    setIsLoading,
    closeAndResetAside,
    addToast,
    setAsideParams,
  } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const orderDetail: OrderDTO | null = useLocation().state;
  const [routeParams, setRouteParams] = useState<OrderDTO | null>(orderDetail);
  const dispatch = useDispatch();
  const [SACardIsLoading, setSACardIsLoading] = useState<boolean>(false);
  const [BACardIsLoading, setBACardIsLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId]= useState<string>()
  const order: OrderDTO | null = useMemo(
    () => (isMobile ? routeParams : asideParams),
    [isMobile, routeParams, asideParams]
  );
      const { t } = useTranslation();

  useEffect(() => {
    setRouteParams(orderDetail);
  }, [orderDetail, orderDetail?.Status]);

  // const [counter, setCounter] = useState<number>(5)
  //const sortedItemsArray = sortItemsByShop(shops, orderData?.Shippings);

  // const promoCode = 10;

  // useEffect(() => {
  //   if (sortedItemsArray.length !== 0) {
  //     return
  //   }

  //   if (counter > 0) {
  //     setTimeout(() => { setCounter(x => x - 1) }, 1000)
  //     return
  //   }

  //   if (isMobile) {
  //     navigate(-1)
  //   } else {
  //     setAsideIsOpen(false)
  //   }

  // }, [counter, sortedItemsArray.length])
  function changeAddress(
    address: UserShippingAddress | undefined,
    type: addressType
  ) {
    if (!order?.Shippings?.[0].OrderId) {
      return;
    }
    if (address === undefined) {
      addToast(toastType.error, t(i18Enum.Error_MissingAddress));
      return;
    }

    if (
      address.Id === order?.ShippingAddress?.Id ||
      address.Id === order?.BillingAddress?.Id
    ) {
      addToast(toastType.error, t(i18Enum.Error_SameAddress));
      return;
    }

    let req: UpdateOrderAddressReq | undefined;

    if (type === addressType.userAddress) {
      req = {
        ShippingAddressId: address.Id,
        OrderId: order?.Shippings?.[0].OrderId,
      };
    } else if (type === addressType.userBilling) {
      req = {
        BillingAddressId: address.Id,
        OrderId: order?.Shippings?.[0].OrderId,
      };
    } else {
      req = undefined;
    }

    if (!req) {
      addToast(toastType.error, t(i18Enum.ErrorPage500_ErrorLabel));
      return;
    }

    UpdateAddress(req);
  }

  async function UpdateAddress(req: UpdateOrderAddressReq) {
    req.BillingAddressId ? setBACardIsLoading(true) : setSACardIsLoading(true);
    let resp = await updateOrderAddressCall(req);

    if (!resp.isSuccess || !resp.response?.Data) {
      addToast(toastType.error, resp?.response?.ErrorMessage ?? t(i18Enum.ErrorPage500_ErrorLabel));
      req.BillingAddressId
        ? setBACardIsLoading(false)
        : setSACardIsLoading(false);
      return;
    }

    addToast(toastType.success, t(i18Enum.Message_AddressUpdated));
    if (isMobile) {
      setRouteParams(resp.response.Data);
    }
    setAsideParams(resp.response.Data);
    req.BillingAddressId
      ? setBACardIsLoading(false)
      : setSACardIsLoading(false);
  }

  function orderAction(status: StatusOrder, orderId: string) {
    if (!orderId || orderId === "") {
      addToast(toastType.error, t(i18Enum.Error_MissingOrder));
      return;
    }

    if (
      status === StatusOrder.Delivered ||
      status === StatusOrder.Canceled ||
      status === StatusOrder.Deleted
    ) {
      //TODO ask returnalert(`ASK FOR A RETURN id:${orderId}`);
      setAsideIsOpen(false);
      navigate(PAGE.returnedGoods.menuPath);
      return;
    }

    if (status === StatusOrder.Pending) {
      //TODO delete order

      dispatch(deleteOrderAction(orderId));

      if (isMobile) {
        navigate(PAGE.orders.menuPath);
      }
      setAsideIsOpen(false);
      return;
    }

    return;
  }

  const renderButton = (status: StatusOrder, orderId: string) => {
    if (
      status === StatusOrder.Delivered ||
      status === StatusOrder.Canceled ||
      status === StatusOrder.Deleted
    ) {
      return (
        <Button
          variant='register'
          padding={"10px 20px"}
          onClick={() => {
            orderAction(status, orderId);
          }}
        >
          {t(i18Enum.Orders_Order_Button_AskForAReturn)}
        </Button>
      );
    }

    if (status === StatusOrder.Pending) {
      return (
        <Button
          variant='danger'
          padding={"10px 20px"}
          onClick={() => {
            orderAction(status, orderId);
          }}
        >
          {" "}
          {t(i18Enum.Orders_Order_Button_DeleteOrder)}
        </Button>
      );
    }

    return;
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      {isMobile && (
        <Section padding={["100px 5% 20px 5%"]} width={[1]} justifyContent={"space-between"}>
          <Flex flexDirection={"row"} onClick={() => navigate(-1)}>
            <ArrowSvg width={[40, 30]} />{" "}
            {!isMobile && (
              <Txt paddingLeft={10} variant='link' color={colors.primaryBase}>
                {t(i18Enum.Common_Back)}
              </Txt>
            )}
          </Flex>
        </Section>
      )}
      <Section padding={["10px 5%"]} width={[1]} justifyContent={"space-between"}>
        <Txt fontSize={H6} as={"h6"} fontFamily={"BauProMedium"}>
          {t(i18Enum.Orders_Order_OrderDate)}
        </Txt>
        <Txt fontSize={H6} as={"h6"} fontFamily={"BauPro"} fontWeight={"300"} color={colors.primaryBase}>
          {`${moment(order?.AddedDate).format("lll")}`}
        </Txt>
      </Section>
      <Section padding={["10px 5%"]} width={[1]} flexDirection={"column"} alignItems={"start"}>
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_ShippingAddress)}
        </Txt>
        {order?.ShippingAddress && (
          <>
            {SACardIsLoading ? (
              <CardWrapperWhite style={{ borderColor: colors.thirdBase }} minHeight={"70px"} alignItems={"center"} justifyContent={"center"}>
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={order.Status === StatusOrder.Pending ? addressType.detail : addressType.disabled}
                Name={order?.ShippingAddress.Name ?? ""}
                AddressLine1={order?.ShippingAddress.AddressLine1 ?? ""}
                AddressLine2={order?.ShippingAddress.AddressLine2 ?? ""}
                ZipCode={order?.ShippingAddress.ZipCode ?? ""}
                City={order?.ShippingAddress.City ?? ""}
                CountryKey={order?.ShippingAddress.CountryKey ?? 110}
                isDefault={order?.ShippingAddress.IsDefault ?? false}
                isBilling={!!order?.ShippingAddress.IsBilling}
                handleEdit={() => {}}
              />
            )}
          </>
        )}
      </Section>

      <Section padding={["10px 5%"]} width={[1]} flexDirection={"column"} alignItems={"start"}>
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_BillingAddress)}
        </Txt>
        {order?.BillingAddress && (
          <>
            {BACardIsLoading ? (
              <CardWrapperWhite style={{ borderColor: colors.thirdBase }} minHeight={"70px"} alignItems={"center"} justifyContent={"center"}>
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={order.Status === StatusOrder.Pending ? addressType.detail : addressType.disabled}
                Name={order.BillingAddress.Name ?? ""}
                AddressLine1={order.BillingAddress.AddressLine1 ?? ""}
                AddressLine2={order.BillingAddress.AddressLine2 ?? ""}
                ZipCode={order.BillingAddress.ZipCode ?? ""}
                City={order.BillingAddress.City ?? ""}
                CountryKey={order.BillingAddress.CountryKey ?? 110}
                isDefault={!!order.BillingAddress.IsDefault}
                isBilling={!!order.BillingAddress.IsBilling}
                handleEdit={() => {}}
              />
            )}
          </>
        )}
      </Section>
      {order?.Orders && order?.Orders.length !== 0 && (
        <Section padding={["10px 5%"]} width={[1]} flexDirection={"column"} alignItems={"start"}>
          <Txt padding={"10px 0"} fontFamily={"BauPro"}>
            {t(i18Enum.Orders_Title)}
          </Txt>
          {order.Orders.map((orderChild, index) => {
            return (
              <Accordion key={orderChild.Id + "_" + index} width={[1]} variant={"card"} title={`${t(i18Enum.Orders_Order_Number)} ${orderChild.Number}`} fullHeight>
                <Flex>
                  {orderChild?.Shippings && orderChild.Shippings.length !== 0 ? (
                    <Box width={[1]} paddingTop={"20px"} height={"100%"}>
                      <OrderSentCard items={orderChild?.Shippings} shop={orderChild} variants={"order"} />
                    </Box>
                  ) : (
                    <ErrorNoItems message={t(i18Enum.Orders_Order_ErrorNoItems)} countDownText={t(i18Enum.Orders_Order_OrderDetail)} arrayLenght={orderChild?.Shippings?.length} hasCountDown />
                  )}
                </Flex>
              </Accordion>
            );
          })}
        </Section>
      )}
      <Section padding={["10px 5%"]} width={[1]} flexDirection={"column"} alignItems={"start"}></Section>
      <Section padding={["0 5% 25% 5%"]} width={[1]} flexDirection={"column"}>
        {/* <Flex justifyContent={"flex-end"} padding={"20px 0"} alignItems={"center"} width={[1]} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
          <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
            Promo
          </Txt>
          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
            {`${priceNum(order?.)}€`}
          </Txt>
        </Flex> */}
        {/* TODO: modificare con lo stato dell'ordine che arriva da back-end */}
        <Flex justifyContent={"flex-end"} padding={"20px 0"} borderBottom={"1px solid white"}></Flex>
        <Flex justifyContent={"flex-end"} alignItems={"center"} width={[1]} padding={"20px 0"} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
          <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
            {t(i18Enum.Common_Total)}
          </Txt>

          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
            {`${priceNum(order?.Total)}€`}
          </Txt>
        </Flex>
      </Section>
    </Flex>
  );
};
