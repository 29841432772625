import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import Navbar from "../components/organisms/Navbar";
import Footer from "../components/organisms/Footer";
import { AppGeneralContext } from "../context/AppGeneralContext";
import { useContext, useEffect, useState } from "react";
import { GeneralLoader } from "../components/molecules/GeneralLoader";
import { DesktopNavbar } from "../components/organisms/DesktopNavbar";
import Sidebar from "../components/organisms/Sidebar";
import { PAGE } from "utils/const/pagePath";
import CookieConsent from "react-cookie-consent";
import Txt from "components/primitives/Txt";
import { CustomThemeContext } from "context/ThemeContext";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export const Layout = () => {
  const path = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isLoading, asideIsOpen } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const color = customTheme.colors;
  const pathToMatch = ["/product", "/login", "/user", "/forgot-password"];
  const hideNavigation = pathToMatch.find((el) => el === path.pathname);
  const [showFooter, setShowFooter] = useState<boolean>(true);

  useEffect(() => {
    if (path.pathname.includes("product") || path.pathname.includes("brand") || path.pathname.includes("home")) {
      return;
    }

    const asArray = Object.entries(PAGE);

    const isValidPath = asArray.filter(([key, value]) => value.menuPath === path.pathname);
    if (isValidPath.length > 0) {
      return;
    }
    
    navigate(PAGE.notFound.menuPath);
  }, []);

  useEffect(() => {
    if (path.pathname.includes("product") || path.pathname.includes("filter")) {
      setShowFooter(false);
      return;
    }
    setShowFooter(true);
  }, [path.pathname]);

  useEffect(() => {
    const pathVisible = Object.values(PAGE).find((x) => x.menuPath === path.pathname);
    if (pathVisible?.viewInDesktop) {
      return;
    }

    if (!isMobile && asideIsOpen) {
      navigate(-1);
    }
  }, [isMobile, path, asideIsOpen]);

  useEffect(() => {
    if (path.pathname.includes("magazine")) {
      // navigate(PAGE.home.menuPath);
      window.open("https://magazine.mooore.com/", "_self");
    }
  }, [path]);

  return (
    <Flex justifyContent={"center"} flexDirection={"column"} height={"100%"} width={"100%"} alignItems={"center"} position={"relative"}>
      <TooltipProvider>
        {/* TODO valutare il blockScroll all'open della navigation */}
        {isMobile && !hideNavigation && <Navbar />}
        {!isMobile && (
          <>
            <DesktopNavbar />
            <Sidebar />
          </>
        )}
        <ScrollRestoration />
        {isLoading && <GeneralLoader />}
        <Outlet />
        {showFooter && !isLoading && <Footer />}
      </TooltipProvider>

      <CookieConsent
        location='bottom'
        buttonText={t(i18Enum.Cookie_Consent_Button)}
        cookieName='mooore-cookie-consent'
        style={{ background: "#282828", justifyContent: "center", alignItems: "center", textAlign: isMobile ? "justify" : "center" }}
        buttonStyle={{ backgroundColor: color.primaryBase, color: color.primaryTextContrast, borderRadius: "20px", padding: "10px 20px", fontSize: "13px", justifyContent: "center" }}
        expires={150}
      >
        <Txt variant='light' fontSize={[3]} $textTransform='inherit'>
          {t(i18Enum.Cookie_Consent_Desc)}{" "}
          <Txt fontSize={[3]} color={color.primaryText} $textTransform='inherit' $cursor='pointer' variant='link' onClick={() => navigate(PAGE.cookiePolicy.menuPath)}>
            {t(i18Enum.Footer_CookiePolicy)}
          </Txt>
          .
        </Txt>
        🫶🏼
      </CookieConsent>
    </Flex>
  );
};
