import Flex from "components/primitives/Flex";
import { AppGeneralContext } from "context/AppGeneralContext";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { H3, H4, H6 } from "theme/theme.styled";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import { useAppDispatch } from "redux/app/hooks";
import { getOrdersAction } from "redux/features/user/userSlice";
import { CustomThemeContext } from "context/ThemeContext";
import { i18Enum } from "i18n/types/translationType";
import { cookies } from 'utils/cookies'
import { useTranslation } from "react-i18next";

type responsePageProps = {
  isSuccess: boolean;
};

export const ResponsePage = ({ isSuccess }: responsePageProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const { t } = useTranslation();
  const colors = customTheme.colors;
  const { setAsideIsOpen, addToast, setAsideChildren, isMobile, openAsideMenu } = useContext(AppGeneralContext);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    setAsideIsOpen(false)
  },[isSuccess])

  useEffect(()=>{
   cookies.remove("shoppingBasketId")
  },[])



  return (
    <Flex width={[1]}>
      <Section id='' overflow={"hidden"} minHeight={"80vh"} position={"relative"} width={["100%"]} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
        <Txt
          variant={isSuccess ? "medium" : "error"}
          color={isSuccess ? colors.primaryBase : colors.dangerBase}
          fontSize={isMobile ? H4 : H3}
          padding={"0 5%"}
          as={"h2"}
          $textTransform='default'
          textAlign={"center"}
        >
          {isSuccess ? t(i18Enum.Payment_Message_Success) : t(i18Enum.Payment_Message_Error)}
        </Txt>

        <Txt fontSize={isMobile ? H6 : H6} padding={"0 5%"} as={"h2"} $textTransform='default' textAlign={"center"} marginBottom={[4]}>
          {isSuccess ? t(i18Enum.Payment_Message_Success2) : t(i18Enum.Payment_Message_Error2)}
        </Txt>

        <Link
          to={PAGE.orders.menuPath}
          onClick={() => {
            dispatch(getOrdersAction());
          }}
        >
          <Txt variant='link'>{t(i18Enum.Payment_GoBackToOrder)}</Txt>
        </Link>
        <BackgroundVideo variant={"left"} video={"first"} height={"100%"} left={["60%"]} />
      </Section>
    </Flex>
  );
};
