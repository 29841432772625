import styled, { keyframes } from "styled-components";
import { HeightProps, WidthProps, height, width, border, BorderProps, space, SpaceProps, compose, variant } from "styled-system";

interface SkeletonProps {
  variant?: "popup";
}

const skeletonKeyframe = keyframes`
   to {
    background-position-x: -20%;
  }
`;

const Skeleton = styled.div<SkeletonProps & HeightProps & WidthProps & BorderProps & SpaceProps>`
  height: 100%;
  width: 100%;
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.413) 50%, rgba(255, 255, 255, 0) 60%) #000000;
  background-size: 200% 200%;
  background-position-x: 170%;
  animation: 1s ${skeletonKeyframe} ease-in-out infinite;

  ${() =>
    compose(
      variant({
        variants: {
          popup: {
            background: "linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.413) 50%, rgba(255, 255, 255, 0) 60%) #17181c",
            backgroundSize: "200% 200%",
            backgroundPositionX: "170%",
          },
        },
      })
    )}

  ${height};
  ${width};
  ${border};
  ${space};
`;

export default Skeleton;
