import  styled from 'styled-components' 


const TableCell = styled.div`
padding: 15px 0px;
overflow: hidden;
text-overflow: ellipsis;



` 

export default TableCell