import { setBillingAddressCall, setShippingAddressCall } from "../../utils";
import { ListOrdersResponse, OrderDTO, UserPreference, UserShippingAddress, UserType, VerifyUserType } from "../../redux/features/user/UserTypes";
import { getCall } from "../baseCall";
import { BaseResponse, CoreResponse, PaginatedResponse } from "../types/responses";
import { deleteAriesCall, getAriesCall, patchAriesCall, postAriesCall, putAriesCall } from "../baseAriesCall";
import { CreateUpdateBillingAddressRequest, CreateUpdateShippingAddressRequest, UpdateOrderAddressReq } from "api/types/requests/shippingAddressRequest";
import { ProductType } from "redux/features/product/ProductsTypes";
import { CommunitySearchRequest, CommunityUser } from "redux/features/community/CommunityTypes";
import { IdOptionType } from "components/primitives/Select";

const USER_PROFILE_CONTROLLER = "User";
const EDIT_USER_CONTROLLER = "User/ProfileEdit";
const USER_PREFERENCE_CONTROLLER = "User/Preferences";
const ADDRESS_CONTROLLER = "Address";
const DELETE_USER_CONTROLLER = "User/Delete";
const SWITCH_PRIVATE = "User/Private";
const WISHLIST_CONTROLLER = "Product/Wishlist";
const ORDERS_CONTROLLER = "Order";
const ORDERS_PARENTS_CONTROLLER = "Order/Parent";
const DELETE_ORDER_CONTROLLER = "Order/DeleteOrderByCustomer";
const UPDATE_ORDER_ADDRESS = "Order/EditAddressess";
const VERIFY_REGISTER_CONTROLLER = "Auth/VerifyRegister";
const REGIONS_CONTROLLER = "Regions";
const PROVINCES_CONTROLLER = "Provinces";


// DON'T USE!
// export const getUserList = async (params: BasePaginatedRequest) =>
//   await getCall<PaginatedResponse<UserType>, BasePaginatedRequest>(`, params);

export const getUserCardByCodeCall = async (code: string) => await getCall<UserType>("api/user/" + code + "/GetByCode");

export const verifyRegisterCall = async (req: VerifyUserType) => await patchAriesCall<VerifyUserType, CoreResponse>(VERIFY_REGISTER_CONTROLLER, req);

export const getUserCall = async () => await getAriesCall<UserType>(`User/GetUser`);

export const postEditUserCall = async (req: UserType) => await postAriesCall<UserType, BaseResponse<UserType>>(EDIT_USER_CONTROLLER, req);

export const deleteUserCall = async () => await patchAriesCall<Object, CoreResponse>(DELETE_USER_CONTROLLER, {});

export const postUserPreferenceCall = async (req: UserPreference) => await postAriesCall<UserPreference, BaseResponse<UserType>>(USER_PREFERENCE_CONTROLLER, req);

/************************* USER ACCOUNT  **************************/

export const switchPrivateAccountCall = async () => await patchAriesCall<Object, BaseResponse<UserType>>(SWITCH_PRIVATE, {});

/************************* USER SHIPPING ADDRESS MANAGEMENT **************************/

//shippings
export const createShippingAddressCall = async (req: CreateUpdateShippingAddressRequest) => {
  setShippingAddressCall(req);
  return await postAriesCall<CreateUpdateShippingAddressRequest, BaseResponse<UserShippingAddress>>(ADDRESS_CONTROLLER, req);
};
export const updateShippingAddressCall = async (shippingAddressId: string, req: CreateUpdateShippingAddressRequest) => {
  setShippingAddressCall(req);
  return await putAriesCall<CreateUpdateShippingAddressRequest, CoreResponse>(ADDRESS_CONTROLLER + "/" + shippingAddressId, req);
};

//billings
export const createBillingAddressCall = async (req: CreateUpdateBillingAddressRequest) => {
  setBillingAddressCall(req);
  return await postAriesCall<CreateUpdateBillingAddressRequest, BaseResponse<UserShippingAddress>>(ADDRESS_CONTROLLER, req);
};
export const updateBillingAddressCall = async (billingAddressId: string, req: CreateUpdateBillingAddressRequest) => {
  setBillingAddressCall(req);
  return await putAriesCall<CreateUpdateBillingAddressRequest, CoreResponse>(ADDRESS_CONTROLLER + "/" + billingAddressId, req);
};

//shared address call
export const deleteAddressCall = async (addressId: string) => await deleteAriesCall<BaseResponse<UserShippingAddress[]>>(ADDRESS_CONTROLLER + "/" + addressId);

export const setDefaultAddressCall = async (addressId: string) => await patchAriesCall<Object, CoreResponse>(ADDRESS_CONTROLLER + "/" + addressId + "/Default", {});

export const gerUserAddressListCall = async (isBilling: boolean, addressId?: string) => await getAriesCall<BaseResponse<UserShippingAddress[]>>(ADDRESS_CONTROLLER, { isBilling, addressId });

export const getRegionsList = async (id: number, searchText: string) => await getAriesCall<BaseResponse<IdOptionType[]>>(`${ADDRESS_CONTROLLER}/${REGIONS_CONTROLLER}/${id}/${searchText}`);

export const getProvincesList = async (id: string, searchText: string|null) => await getAriesCall<BaseResponse<IdOptionType[]>>(`${ADDRESS_CONTROLLER}/${PROVINCES_CONTROLLER}/${id}/${searchText}`);

/************************* ORDERS **************************/

export const getOrdersCall = async () => await getAriesCall<BaseResponse<ListOrdersResponse>>(ORDERS_CONTROLLER);
export const getOrderByIdCall = async (id: string) => await getAriesCall<BaseResponse<OrderDTO>>(`${ORDERS_CONTROLLER}/${id}`);
export const getOrderParentByIdCall = async (id: string) => await getAriesCall<BaseResponse<OrderDTO>>(`${ORDERS_PARENTS_CONTROLLER}/${id}`);
export const deleteOrderByIdCall = async (id: string) => await patchAriesCall<Object, BaseResponse<OrderDTO>>(`${DELETE_ORDER_CONTROLLER}/${id}`, {});
export const updateOrderAddressCall = async (req: UpdateOrderAddressReq) => await patchAriesCall<UpdateOrderAddressReq, BaseResponse<OrderDTO>>(`${UPDATE_ORDER_ADDRESS}`, req);

/************************* USER WISHLIST **************************/

export const getUserWishListCall = async () => await getAriesCall<ProductType[]>(WISHLIST_CONTROLLER);

/************************* COMMUNITY **************************/
export const getUserByIDCall = async (id: string) => await getAriesCall<BaseResponse<UserType>>(`${USER_PROFILE_CONTROLLER}/${id}`);
export const getCommunityUserListCall = async (req: CommunitySearchRequest) =>
  await postAriesCall<CommunitySearchRequest, PaginatedResponse<CommunityUser>>(`${USER_PROFILE_CONTROLLER}/Community`, req);
export const toggleFollowingCall = async (id: string) => await patchAriesCall(`${USER_PROFILE_CONTROLLER}/Follow/${id}`, {});
//MOCK REQUEST
// export const getUserInfoCall = async () => {
//   await waitNSeconds(1);
//   return user
//};
