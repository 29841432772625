/*
The image will be sent in Base64 format;
it's possible to change the configuration at the end of the CropImage component so that the image format is Blob.
*/

import React, { useEffect, useState } from "react";
import { Point, Area } from "react-easy-crop";
import Box from "./Box";
import Cropper from "react-easy-crop";
import imageInitial from "../../assets/img/image-cropper.png";

interface CropProps {
  image: React.ChangeEvent<HTMLInputElement> | undefined;
  zoom: number;
  handleCropImg: (img: string | undefined) => void;
  submit: boolean;
}

function CropImage({ image, zoom, submit, handleCropImg }: CropProps) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    if (image) onFileChange(image);
    else {
      setImageSrc("");
      setCroppedImage(null);
    }
  }, [image]);

  useEffect(() => {
    showCroppedImage();
  }, [croppedAreaPixels, zoom, crop]);

  useEffect(() => {
    if (submit) handleCropImg(croppedImage!);
  }, [submit]);

  const onCropComplete = (cropedAres: Area, croppedAreaPixels: Area) => {
    cropedAres = cropedAres;
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = (point: Point) => {
    setCrop(point);
    showCroppedImage();
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        imageSrc,
        croppedAreaPixels!,
        0
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.log(e);
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: any = e.target.files[0];
      let imageDataUrl: string = await readFile(file);
      setImageSrc(imageDataUrl!);
    }
  };

  function readFile(file: string): Promise<string> {
    return new Promise((resolve) => {
      const reader: any = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  return (
    <Box width={[1]}>
      <Cropper
        image={imageSrc || imageInitial}
        crop={imageSrc ? crop : { x: 0, y: 0 }}
        zoom={imageSrc ? zoom : 1}
        aspect={1 / 1}
        showGrid={false}
        onCropChange={imageSrc ? handleCrop : () => {}}
        onCropComplete={onCropComplete}
      />
    </Box>
  );
}

export default CropImage;

//      The following line of code was extracted from the official
//      library at https://www.npmjs.com/package/react-easy-crop.

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: any) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  //As Base64 string
  return croppedCanvas.toDataURL("image/jpeg");

  // As a blob
  /* return new Promise((resolve) => {
    croppedCanvas.toBlob((file: any) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });  */
}
