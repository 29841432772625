import StepperHeader from "components/molecules/StepperHeader";
import { Step } from "components/organisms";
import Flex from "components/primitives/Flex";
import StepFourthForm, { FormStepFourth } from "components/organisms/StepFourthForm";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { UserPreference } from "redux/features/user/UserTypes";

import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { getUserAction, putUserPreferenceAction, sendUserPreferenceAction } from "redux/features/user/userSlice";
import { useContext, useEffect, useState } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import { toastType } from "utils";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface StepperFourthPageProps {
  handleCurrentStep: (step: Step) => void;
}

export function StepperFourthPage({ handleCurrentStep }: StepperFourthPageProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { setIsLoading, addToast } = useContext(AppGeneralContext)
  const { data, isLoading, errors } = useAppSelector((state) => state.user.userPreference)
  const [sendRequest, setSendRequest] = useState<boolean>(false)

  useEffect(() => {

    if (errors) {
      setSendRequest(false)
      return
    }

    if (sendRequest && !isLoading && !errors) {
      navigate(PAGE.account.menuPath)
      return
    }

  }, [sendRequest, isLoading, errors])


  const onSubmit = (value: FormStepFourth) => {
    if (sendRequest) {
      return
    }
    let req: Partial<UserPreference> = {
      FavoriteSocials: value.social
    }
    dispatch(putUserPreferenceAction(req));
    dispatch(sendUserPreferenceAction());
    setSendRequest(true)
  };

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])




  return (
    <>
      <Flex flex={1} padding={"28px 5%"} flexDirection={"column"} alignItems={"center"} $gap={1.5} width={[1, 1, "900px"]}>
        <StepperHeader title={t(i18Enum.User_UserProfile_AboutYou)} backToStep={() => handleCurrentStep(Step.third)} />
      </Flex>
      <StepFourthForm onSubmit={onSubmit} />
    </>
  );
}
