import styled from "styled-components";
import {
  HeightProps,
  PositionProps,
  WidthProps,
  compose,
  variant,
  width,
} from "styled-system";

export interface BackgroundVideoProps
  extends PositionProps,
    WidthProps,
    HeightProps {
  variant: "left" | "center" | "right";
  video: "first" | "second";
  transform?: string;
  opacity?:number
}

const BackgroundVideo = ({ ...props }: BackgroundVideoProps) => {
  const uriFirst =
    "/videos/bigMetal.mp4"
  const uriSecond =
  "/videos/metaldropsbg.mp4"
  return (
    <Video autoPlay loop muted webkit-playsinline playsInline  controlsList="nofullscreen" {...props} style={{opacity:props.opacity ?? 1}}>
      <source
        src={props.video === "first" ? uriFirst : uriSecond}
        type="video/mp4"
      />
    </Video>
  );
};

export default BackgroundVideo;

const Video = styled.video<BackgroundVideoProps>`
  position: absolute;
  z-index: -20;
  top: ${(props) => props.top ?? null};
  left: ${(props) => props.left ?? null};
  bottom: ${(props) => props.bottom ?? null};
  right: ${(props) => props.right ?? null};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  transform: ${(props) => props.transform || "none"};
  object-fit: cover;

  ${() =>
    compose(
      variant({
        variants: {
          left: {
            right: "0",
            objectPosition: "left",
          },
          center: {
            right: "0",
            objectPosition: "center",
          },
          right: {
            left: "0",
            objectPosition: "right",
          },
        },
      }),
      width
    )}
`;
{
  /* 
Example

<BackgroundVideo
top="100px"
height="675px"
width="80%"
variant="left"
video="first"
/>
*/
}
