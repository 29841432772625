import { all, fork } from "redux-saga/effects";
import { watchGetUser } from "../features/user/userSaga";
import { watchGetProduct } from "redux/features/product/productSaga";
import { watchCart } from "redux/features/cart/cartSaga";
import { watchGetBrand } from "redux/features/brand/brandSaga";
import { watchAuth } from "redux/features/auth/authSaga";
import { watchGetRegister } from "redux/features/register/registerSaga";
import { watchGetOutfits } from "redux/features/outfits/outfitsSaga";
import { watchCommunity } from "redux/features/community/communitySaga";



const rootSaga = function* () {
  yield all([
    fork(watchAuth),
    fork(watchGetUser),
    fork(watchGetProduct),
    fork(watchCart),
    fork(watchGetBrand),
    fork(watchGetRegister),
    fork(watchGetOutfits),
    fork(watchCommunity)
    // Considering we have the watcher below
    // fork(watchGetUsersList),
  ]);
};
export default rootSaga;