import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  compose,
  variant,
  height,
  width,
} from "styled-system";

interface SVGProps extends WidthProps, HeightProps, SpaceProps, PositionProps {
  fill?: string;
  handleClick?: () => void;
  variant?: "searchable" | "navbar";
}

const StyledSVG = styled.svg<SVGProps>`
  cursor: pointer;
  width: ${(props) => props.width || "29px"};
  height: ${(props) => props.height || "30px"};
  ${space};
  ${position};
  ${height};
  ${width}
`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.thirdBase};
  ${(props) =>
    compose(
      variant({
        variants: {
          searchable: {
            fill: props.theme.colors.primaryBase,
          },
          navbar: {
            fill: props.theme.colors.thirdBg,
          },
        },
      })
    )}
`;

function SearchSvg({ ...props }: SVGProps) {
  return (
    <StyledSVG
      onClick={props.handleClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 30"
      {...props}
    >
      <SVGPath
        {...props}
        d="M20.1168 22.5465C17.987 24.4035 15.2438 25.524 12.253 25.524C5.48928 25.524 0 19.806 0 12.762C0 5.718 5.48928 0 12.253 0C19.0138 0 24.5045 5.718 24.5045 12.762C24.5045 15.879 23.4288 18.738 21.6461 20.9535L28.4846 28.0785C28.6949 28.2975 28.8 28.5855 28.8 28.875C28.8 29.7555 28.0267 30 27.72 30C27.4435 30 27.167 29.8905 26.9554 29.67L20.1168 22.5465ZM12.253 2.2515C6.68304 2.2515 2.16288 6.9615 2.16288 12.762C2.16288 18.5625 6.68304 23.2725 12.253 23.2725C17.82 23.2725 22.343 18.5625 22.343 12.762C22.343 6.9615 17.82 2.2515 12.253 2.2515Z"
      />
    </StyledSVG>
  );
}

export default SearchSvg;
