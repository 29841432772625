import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Flex from "../primitives/Flex";
import Image from "../primitives/Image";
import { CustomThemeContext } from "../../context/ThemeContext";

import { useScrollDirection } from "../../utils/hooks/useScrollDirection";
import Box from "../primitives/Box";
import { scrollParentToChild } from "../../utils/functions/scrollInsideParent";
import ProgressiveImg from "../atoms/ProgressiveImg";
import Skeleton from "../atoms/Skeleton";


export interface VerticalImageProps {
  urlImages?: string[];
  isMobile: boolean;
  isLoading: boolean;
}

function VerticalImageSlider({ ...props }: VerticalImageProps) {
  const [currentItem, setCurrentItem] = useState({
    currentIndex: 0,
    isMoving: false,
  });
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const direction = useScrollDirection("sliderDesktop");
  const ref = useRef<HTMLDivElement | null>(null);
  const [moveOnClick, setMoveOnClick]= useState(false)

  useEffect(() => {
    
    if (currentItem.isMoving|| moveOnClick) {

      return;
    }
   
    moveOnScroll(direction);
  }, [direction]);



  useEffect(() => {
    const imageList = ref.current;
    let imageNode =
      imageList?.querySelectorAll("img")[currentItem.currentIndex];
    if (imageList && imageNode) {
      scrollParentToChild(imageList, imageNode);
    } else {
      return;
    }
    setTimeout(() => {
      setCurrentItem((prev) => ({ ...prev, isMoving: false }));
    }, 500);
  }, [currentItem.currentIndex]);

  const moveOnScroll = useCallback(
    (dir: typeof direction) => {
      if (!props.urlImages?.length) {
        return;
      }

      if (dir === "up") {
        if (currentItem.currentIndex === 0) {
          return;
        } else {
          setCurrentItem((prev) => {
            return {
              currentIndex: prev.currentIndex - 1,
              isMoving: true,
            };
          });
          return;
        }
      }
      if (dir === "down") {
        if (currentItem.currentIndex >= props?.urlImages.length - 1) {
          return;
        } else {
          setCurrentItem((prev) => {
            return {
              currentIndex: prev.currentIndex + 1,
              isMoving: true,
            };
          });

          return;
        }
      }
    },
    [currentItem.currentIndex, currentItem.isMoving, props.urlImages]
  );

  const renderHeight = useCallback(
    (array: string[]) => {
      let singleElementHeightValue = 100 / array.length;
      return singleElementHeightValue * (currentItem.currentIndex + 1);
    },
    [currentItem.currentIndex]
  );
  const renderOpacity = (currentIndex: number, index: number) => {
    if (currentIndex === index) {
      return 1;
    }
    return 0.6;
  };

  if (!props.isMobile) {
    return (
      <Flex   flexDirection={"row"}>
        <Box
        onMouseEnter={()=>setMoveOnClick(false)}
          id="sliderDesktop"
          position={"relative"}
          ref={ref}
          display={"block"}
          width={"90%"}
          style={{ scrollBehavior: "smooth" }}
          overflowY={"scroll"}
          overflowX={"hidden"}
          minHeight={"500px"}
          maxHeight={"600px"}
        >
          {!props.isLoading ? (
            props?.urlImages?.map((url, index) => {
              return (
                <ProgressiveImg
                  placeholderSrc=""
                  style={{
                    cursor:
                      'url("https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/external-Scroll-arrows-tanah-basah-basic-outline-tanah-basah-2.png"),auto',
                  }}
                  id={index.toString()}
                  key={url + index}
                  width={"100%"}
                  height={"100%"}
                  src={url}
                />
              );
            })
          ) : (
            <Skeleton height={"100%"} width={"100%"} />
          )}
        </Box>

        <Flex
          width={"5%"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
        >
          <Flex
            height={"100%"}
            flexDirection={"column"}
            width={"3px"}
            borderRadius={3}
            backgroundColor={colors.thirdBase}
          >
            <Flex
              width={"100%"}
              height={`${renderHeight(props?.urlImages ?? [])}%`}
              borderRadius={3}
              backgroundColor={colors.primaryBgActive}
            ></Flex>
          </Flex>
        </Flex>
        <Flex  width={"10%"} justifyContent={"end"} flexDirection={"column"}>
          {" "}
          {props?.urlImages?.map((url, index) => {
            return (
              <Image
                style={{ cursor: "pointer" }}
                onClick={() =>{
                  setMoveOnClick(true);
                  setCurrentItem({ isMoving: true, currentIndex: index })}
                }
                $aspectRatio="3/4"
                opacity={renderOpacity(currentItem.currentIndex, index)}
                maxHeight={"100%"}
                minWidth={"100%"}
                objectFit="cover"
                key={index}
                src={url}
              />
            );
          })}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex
      flexDirection={["column", "column", "column"]}
      paddingTop={"59px"}
      height={["100vh", "100vh", "100%"]}
      maxWidth={["100vw", "100vw", "100%"]}
      overflowX='hidden'
      overflowY='scroll'
    >
      {props?.urlImages?.map((url, index) => (
        <Image key={index} src={url} alt={"product"} width={["100%", "100%", "100%"]} height={["100vh", "100vh", "100%"]} minHeight={["100%", "100%", "70%"]} />
      ))}
    </Flex>
  );
}

export default VerticalImageSlider;

/* 
example
<VerticalImageSlider
        urlImages={[
          "https://s3-alpha-sig.figma.com/img/afc7/1f03/65d1a7878d79826858ec5a74486c95c4?Expires=1698019200&Signature=Qmn14e8EYK5whP9cDUhqU9JS4PDZX7VMw3788Vg4-iUaXGLHAt7JlctcmHolJ9C580Ffski9vLkdEnsP1WmX07Wr~M5FoRrhxK5f4cqJYXCYCJ8GP0oO1hHz88XYJs0SQsKdyPl9LhX9HweinDCGp~Io1YESusiS7ypai4kE35e4sEdopdE0PuYHx4fsuugz4brJgKRtmlGu541ia7tukr8yd7oez6lfcXIygnPBFDCWYrbNBZB68l2wp95bI4ZQlA9PTEzKYAX2S7RuPjYzNHlrLlOnOcF~GAt1dibEm-cH2WMUHN0YwCDpH6hLnGITVXZ43wjpMBnkymp5esWV~g__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
        ]}
      />
*/
