import { ToastOptions } from "react-toastify";
import { Tags } from "redux/features/product/ProductsTypes";
export interface Options {
  name: string;
  value: string;
}

export interface OptionsValue<T> {
  name: string;
  value: T;
}

export enum Role {
  user = "operator",
  guest = "manager",
  admin = "admin",
}

export const toastErrorStyle: ToastOptions = {
  style: {
    fontFamily: "BauPro",
    fontSize: "12px",
    textTransform: "uppercase",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.4)",
    border: "1px solid #e74c3c",
  },
};

export const toastSuccessStyle: ToastOptions = {
  style: {
    fontFamily: "BauPro",
    fontSize: "12px",
    textTransform: "uppercase",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.4)",
    border: "1px solid #07bc0c",
  },
};

export const toastWarningStyle: ToastOptions = {
  style: {
    fontFamily: "BauPro",
    fontSize: "12px",
    textTransform: "uppercase",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.4)",
    border: "1px solid #f1c40f",
  },
};

export const allRoles = [Role.admin, Role.guest, Role.admin];

export const filterGenres: Options[] = [
  {
    name: "woman",
    value: "woman",
  },
  {
    name: "man",
    value: "man",
  },
];

export const filter: Options[] = [
  {
    name: "dress",
    value: "dress",
  },
  {
    name: "accessories",
    value: "accessories",
  },
  {
    name: "boots",
    value: "boots",
  },
  {
    name: "bag",
    value: "bag",
  },
  {
    name: "promo",
    value: "promo",
  },
];

export enum colorsKeyEnum {
  Orange = "#F77F00", //Orange
  Silver = "#BAAA9E", //Silver
  "Light blue" = "#9BC4E2", //LightBlue
  Beige = " #C4B796", //Beige
  White = "#fff", //White
  Blue = "#003F77", //Blue
  Ecru = "#E2D8BF", //Ecru
  Neon = "#f0fd53", //Neon
  Yellow = "#F9E04C", //Yellow
  Grey = "#30383A", //Grey
  Brown = "#995905", //Brown
  Multicolour = "Multicolour", //Multicolour
  Black = "#000", //Black
  Gold = "#DDCE11", //Gold
  Pink = "#F26877", //Pink
  Red = "#D81E05", //Red
  Printed = "#444444", //Printed
  Green = "#608E3A", //Green
  Purple = "#664975", //Purple
  Burgundi = "#64323e", //Burgundi
  Cream = "#00A0BA", //Cream
  Navy = "#2D338E", //Navy
  Neutral = "#deccbe", //Neutral
  Fuchsia = "#9E2387", //Fuchsia
  Violet = "#7f00ff", //Violet
  "Military green" = "#4b5320", //MilitaryGreen
  Camel = "#D18E54", //Camel
  Fluor = "#CCE226", //Fluor
  "Blue neon" = "#00AACC", //Blue neon
  "Green neon" = "#60DD49", //Green neon
  "Orange neon" = "#FF9338", //Orange neon
  "Pink neon" = "#FF0093", //Pink neon
  "Yellow neon" = "#FFED38", //Yellow neon
  "Dark green" = "#0C3026", //Dark green
  Olive = "#AA930A", //Olive
  Mint = "#00B59B",
  Emerald = "#004F6D",
  Ash = "#ADAFAA",
  "Sky blue" = "#5E82A3",
  "Dark blue" = "#353F5B",
  "Light pink" = "#FCC9C6",
  Lavender = "#A5AFD6",
  Coral = "#FC8744",
  Peach = "#EAAA7A",
  Salmon = "#F95951",
  Rust = "#B55400",
  Butter = "#F4ED7C",
  Burgundy = "#7C1E3F",
  Turquoise = "#6BC9D8",
  Sand = "#EDA04F",
  Nude = "#E0D1C6",
  Chocolate = "#633A11",
  Khaki = "#82750F",
}
export const MultiColorKeyGradient = "linear-gradient(90deg, rgba(238, 130, 238, 1) 0%, rgba(0, 139, 255, 1) 100%)";

export const PORTAL_PAGE_SECTION_ITEM_PRODUCT = "PortalPageSectionItemProduct";
export const PORTAL_PAGE_SECTION_TYPE_7 = "PortalPageManageType7";
export const PORTAL_PAGE_SECTION_TYPE_2 = "PortalPageManageType2";

export const orderByTag: Tags = {
  Id: "OrderBy",
  Label: "Order By",
  ParentId: "orderBy",
  Tags: [
    {
      Id: "Price",
      Label: "Price",
      ParentId: "orderBy",
      Tags: [
        {
          Id: "asc-Price",
          Label: "Increasing price",
          ParentId: "orderBy",
          Tags: [],
        },
        {
          Id: "desc-Price",
          Label: "Decreasing price",
          ParentId: "orderBy",
          Tags: [],
        },
      ],
    },
    {
      Id: "Name",
      Label: "Name",
      ParentId: "orderBy",
      Tags: [
        {
          Id: "asc-Name",
          Label: "A - Z",
          ParentId: "orderBy",
          Tags: [],
        },
        {
          Id: "desc-Name",
          Label: "Z - A",
          ParentId: "orderBy",
          Tags: [],
        },
      ],
    },
    {
      Id: "AddedDate",
      Label: "Published Date",
      ParentId: "orderBy",
      Tags: [
        {
          Id: "asc-AddedDate",
          Label: "Oldest First",
          ParentId: "orderBy",
          Tags: [],
        },
        {
          Id: "desc-AddedDate",
          Label: "Newest First",
          ParentId: "orderBy",
          Tags: [],
        },
      ],
    },
  ],
};
