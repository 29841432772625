import { PayloadAction } from "@reduxjs/toolkit";
import {  SET_PRODUCT, GET_PRODUCT_LIST_BY_BRAND_ID, GET_SIMILAR_PRODUCT, ProductType, SEARCH_PRODUCTS, GET_PRODUCT_BY_ID } from "./ProductsTypes";
import { put, select, takeLatest } from "redux-saga/effects";
import {  getProductByIdCall, getSimilarProductCall, productSearchCall } from "api/contentCalls/productCalls";
import {  setProductErrorAction, setProductSuccessAction, getProductListByBrandIdErrorAction, getProductListByBrandIdSuccessAction, getSimilarProductsErrorAction, getSimilarProductsSuccessAction, productListByBrandIdResetData, searchProductErrorAction, searchProductResetData, searchProductSuccessAction, getProductByIdErrorAction, getProductByIdSuccessAction } from "./productSlice";
import { ApiSimpleResponse, BaseResponse, PaginatedResponse } from "api/types/responses";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";

import { BaseIdRequest } from "api/types/requests";


function* setProductSaga({ payload }: PayloadAction<ProductType>) {
    if(!payload){
       yield put(setProductErrorAction(t(i18Enum.Error_Occurred))); 
        return
    }
    yield put(setProductSuccessAction(payload))
}

function* getProductByIdSaga({ payload }: PayloadAction<string>){
    const productResponse:ApiSimpleResponse<BaseResponse<ProductType>> = yield getProductByIdCall(payload)
    if(!productResponse.isSuccess || !productResponse.response?.Data){
        yield put(getProductByIdErrorAction(productResponse.error ?? t(i18Enum.Error_Occurred)));
        return
    }
    yield put(getProductByIdSuccessAction(productResponse.response.Data))
    return
}

function* searchProductSaga ({payload}: PayloadAction<ProductsListRequest>){
    if(payload.IsFiltering){
        yield put(searchProductResetData())
    }
    const searchProductCallResponse: ApiSimpleResponse<PaginatedResponse<ProductType>> = yield productSearchCall(payload)
      
    if (!searchProductCallResponse.isSuccess || !searchProductCallResponse.response) {
        yield put(searchProductErrorAction(searchProductCallResponse.error ?? ''));
        return
    }
    yield put(searchProductSuccessAction(searchProductCallResponse.response));
}
// function* getProductListByBrandIdSaga ({payload}: PayloadAction<BaseIdRequest<ProductsListRequest>>){
//     if(payload.params.IsFiltering){
//         yield put(productListByBrandIdResetData())
//     }
   
    
//     const productListCallResponse: ApiSimpleResponse<PaginatedResponse<ProductType>> = yield getProductListByShopIdCall(payload.id,payload.params)
   
//     if (!productListCallResponse.isSuccess || !productListCallResponse.response) {
//         yield put(getProductListByBrandIdErrorAction(productListCallResponse.error ?? ''));
//         return
//     }
//     yield put(getProductListByBrandIdSuccessAction(productListCallResponse.response));
// }

function* getSimilarProductSaga({payload:id}: PayloadAction<string>){
    const getSimilarCallResponse: ApiSimpleResponse<PaginatedResponse<ProductType>> = yield getSimilarProductCall(id)
    if (!getSimilarCallResponse.isSuccess || !getSimilarCallResponse.response?.Data) {
        yield put(getSimilarProductsErrorAction(getSimilarCallResponse.error ?? ''));
        return
    }
   
    yield put(getSimilarProductsSuccessAction(getSimilarCallResponse.response.Data));
}



// Generator function
export function* watchGetProduct() {
    yield takeLatest(GET_PRODUCT_BY_ID,getProductByIdSaga)
    yield takeLatest(SET_PRODUCT, setProductSaga);
    yield takeLatest(SEARCH_PRODUCTS, searchProductSaga);
    yield takeLatest(GET_SIMILAR_PRODUCT,getSimilarProductSaga);
    // yield takeLatest(GET_PRODUCT_LIST_BY_BRAND_ID,getProductListByBrandIdSaga)
}  

