import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  gridGap,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  GridProps,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  GridGapProps,
  gridColumnGap,
  gridRowGap,
} from "styled-system";

const Grid = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps &
    GridProps &
    GridGapProps&{ $gap?: number }
>`
  display: grid;
  gap: ${(props) => props.$gap}em;
  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
    ${gridGap}
    ${gridTemplateColumns}
    ${gridTemplateRows}
    ${gridTemplateAreas}
    ${gridColumnGap}
    ${gridRowGap}
`;
export default Grid;
