import { useEffect } from "react";

const useOutsideClick = (ref: React.MutableRefObject<HTMLElement | null>, callback: () => void, isOpen: boolean | null) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const handleClick = (e: { target: any }) => {
      if (ref.current && !ref.current.contains(e.target)) {
        console.log(e.target);
        callback();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, isOpen]);
};

export default useOutsideClick;
