
import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
} from "styled-system";




interface SVGProps extends WidthProps, HeightProps, SpaceProps, PositionProps {
  fill?: string;
  handleClick?: () => void;
  number?: number;
}

const StyledSVG = styled.svg<SVGProps>`
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "30px"};
  ${space}
  ${position}
  cursor: pointer;


  & > circle{
    fill: ${(props) =>  props.theme.colors.primaryBase};
  }

  & > text{
    fill: ${(props) =>  props.theme.colors.secondaryBase};
    font-size: 9px;
    font-family: "BauProBold";
  }

  & > rect {
    fill:none
  }

`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.thirdBase};
`;

 const CartSvg = ({ ...props }: SVGProps) => {

  return (
    <StyledSVG
      onClick={()=>props.handleClick?.()}
      version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
       x="0px" y="0px"
       viewBox="0 0 28.7 36.1"     
      {...props}
    >
      <SVGPath
        {...props}
        d="M6.17405 6.97328C6.00916 4.82909 6.52214 2.96457 8.09771 1.51025C9.21527 0.503418 10.516 0 12 0C13.7954 0 15.2794 0.72716 16.4519 2.12554C17.6244 3.50528 17.9359 5.1647 17.8076 6.97328C17.9542 6.97328 18.0824 6.97328 18.1924 6.97328C19.2733 6.97328 20.3542 6.97328 21.4351 6.97328C22.974 6.97328 24 7.99876 24 9.56495C24 15.5127 24 21.4605 24 27.3897C24 28.9745 22.9924 29.9814 21.4534 30C15.1511 30 8.84886 30 2.54657 30C0.989313 30 0 28.9745 0 27.4083C0 21.4605 0 15.5127 0 9.58359C0 8.0174 1.02595 6.99192 2.56489 6.99192C3.62748 6.99192 4.7084 6.99192 5.77099 6.99192C5.88092 6.99192 6.00916 6.99192 6.17405 6.99192V6.97328ZM6.15573 9.06153H5.77099C4.72672 9.06153 3.70076 9.06153 2.65649 9.06153C2.12519 9.06153 2.03359 9.13611 2.03359 9.67682C2.03359 15.55 2.03359 21.4232 2.03359 27.3151C2.03359 27.8372 2.10687 27.9117 2.60153 27.9117C8.86718 27.9117 15.1145 27.9117 21.3802 27.9117C21.8565 27.9117 21.9481 27.8372 21.9481 27.3337C21.9481 21.4419 21.9481 15.5314 21.9481 9.63953C21.9481 9.15475 21.8565 9.06153 21.3802 9.06153C20.3176 9.06153 19.2366 9.06153 18.174 9.06153C18.0641 9.06153 17.9725 9.06153 17.8443 9.06153C17.8443 9.21069 17.8443 9.32256 17.8443 9.43443C17.8443 9.90056 17.8443 10.348 17.8443 10.8142C17.826 11.4295 17.3679 11.8769 16.8 11.8769C16.2321 11.8769 15.8107 11.4108 15.7924 10.7955C15.7924 10.5158 15.7924 10.2548 15.7924 9.97514C15.7924 9.67682 15.7924 9.39714 15.7924 9.11746H8.22595C8.22595 9.73275 8.22595 10.3108 8.22595 10.9074C8.22595 11.3735 7.9145 11.7278 7.47481 11.8583C7.07176 11.9702 6.59542 11.821 6.37557 11.4481C6.26565 11.243 6.19237 10.982 6.19237 10.7396C6.15573 10.2175 6.19237 9.67682 6.19237 9.09882L6.15573 9.06153ZM15.774 6.95463C15.774 6.60037 15.774 6.24612 15.774 5.89186C15.6824 3.15103 13.0626 1.37974 10.5893 2.38658C8.84886 3.11374 8.00611 4.75451 8.2626 6.95463H15.774Z"
      />
      {props.number !== undefined && props.number > 0 && (
        <>
          <circle  cx="20.6" cy="28" r="8"/>
          <text transform={props.number >=  10  ?  "matrix(1 0 0 1 14.5 31.5)" : "matrix(1 0 0 1 17.5 31.5)"} >
            {props.number >=  10  ?  ">9" : props.number}
          </text>
        </>
      )}
    </StyledSVG>
  );
}


export default CartSvg