import { useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { CartContext } from "context/CartContext";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import { ShopGroupType } from "utils/generalTypes/cartItems";
import { sortItemsByShop } from "utils/functions/helpers";
import { priceNum } from "utils/functions/stringPrice";
import Button from "components/primitives/Button";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { H5, H6 } from "theme/theme.styled";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export const OrderSent = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { cartItems, totalCartPrice } = useContext(CartContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const [shops, setShops] = useState<ShopGroupType[]>([]);
  const orderId = "AAA120";
  const { t } = useTranslation();
  // const promoCode = 10;

  useEffect(() => {
    if (cartItems?.shopGroupTotals) {
      setShops(cartItems?.shopGroupTotals);
    }
  }, [cartItems]);

  const sortedItemsArray = sortItemsByShop(shops, cartItems?.data);

  return (
    <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginTop={["10%", "10%", "5%"]}>
      <Box maxWidth={1100}>
        <Section flexDirection={"column"} alignItems={"center"} padding={["20% 0 5%", "5% 0 5%", "0"]}>
          <Flex width={["80%", "100%"]} justifyContent={"center"}>
            <Txt fontSize={H5} variant='medium' color={colors.thirdBase}>
              ✌🏻 {t(i18Enum.Orders_Order_OrderSuccess_Title)} 🫶🏼
            </Txt>
          </Flex>
          <BackgroundVideo variant={"right"} video={"first"} height={["40%"]} top={"10vh"} opacity={0.3} />
        </Section>

        <Section flexDirection={"column"} width={"100%"}>
          {sortedItemsArray &&
            sortedItemsArray.map((items, index) => {
              const singleShop = shops.find((brand) => brand.shopId === items?.[0].shopId);

              return (
                <Box key={index}>
                  <Txt textAlign={"start"} as={"h6"} width={"90%"} variant='medium' color={colors.thirdText} fontSize={H6} margin={"8% 0 5% 5%"}>
                    {/* TODO: modificare con il numero dell'ordine arrivato da back-end */}
                    {`${t(i18Enum.Orders_Order_Number)} ${orderId}`}
                  </Txt>
                  {/* TODO scommentare e fixare */}
                  {/* <OrderSentCard items={items} shop={singleShop} variants={"cart"} /> */}
                </Box>
              );
            })}

          {/* {promoCode > 0 && (
            <Flex  justifyContent={"flex-end"} padding={"5%"} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
              <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
                Promo
              </Txt>
              <Txt variant='light' color={colors.primaryText} fontSize={[2]}>
                AUTUMNFALL
              </Txt>
              <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
                {`-${priceNum(promoCode)}€`}
              </Txt>
            </Flex>
          )} */}

          <Flex justifyContent={"flex-end"} padding={"5%"} borderBottom={`1px solid ${colors.thirdBase}`} marginBottom={"10%"} $gap={2}>
            <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
              {t(i18Enum.Common_Total)}
            </Txt>

            <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
              {`${priceNum(totalCartPrice)}€`}
            </Txt>
          </Flex>
        </Section>

        <Section flexDirection={"column"} alignItems={"center"} width={"100%"} $gap={1} margin={"10% 0"}>
          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.thirdText} fontSize={H5}>
            ✉️ {t(i18Enum.Orders_Order_OrderSuccess_Email)} ✉️
          </Txt>
          <Button variant='login' onClick={() => navigate(PAGE.home.menuPath)}>
            {t(i18Enum.Orders_Order_Button_GoBackHome)}
          </Button>
        </Section>
      </Box>
    </Flex>
  );
};
