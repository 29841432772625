import { useContext } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { AppGeneralContext } from "context/AppGeneralContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { PAGE } from "utils/const/pagePath";

import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import Grid from "components/primitives/Grid";
import Button from "components/primitives/Button";
import Section from "components/primitives/Section";
import { UserType } from "redux/features/user/UserTypes";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { removeAuthAction } from "redux/features/auth/authSlice";
import { toastType } from "utils";
import { UserActions, getOrdersAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface MenuNavigateProps {
  userData: UserType;
}

function MenuNavigate({ userData }: MenuNavigateProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const path = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.auth.auth);
  const { t } = useTranslation();

  const buttonLinks = [
    {
      id: 1,
      text: t(i18Enum.Navigation_UserMenu_AboutMe),
      navigateTo: `${PAGE.account.menuPath}`,
    },
    {
      id: 2,
      text: t(i18Enum.Navigation_UserMenu_Address),
      navigateTo: `${PAGE.address.menuPath}`,
      onClick: () => dispatch(UserActions.getUserAddressesAction({ isBilling: false })),
    },
    {
      id: 3,
      text: t(i18Enum.Navigation_UserMenu_Orders),
      navigateTo: `${PAGE.orders.menuPath}`,
      onClick: () => dispatch(getOrdersAction()),
    },
    {
      id: 4,
      text: t(i18Enum.Navigation_UserMenu_Wishlist),
      navigateTo: `${PAGE.wishlist.menuPath}`,
    },
    // {
    //   id: 5,
    //   text:  t(i18Enum.Navigation_UserMenu_Wardrobe),
    //   navigateTo: `${PAGE.wardrobe.menuPath}`,
    // },
    {
      id: 6,
      text: t(i18Enum.Navigation_UserMenu_MyProfile),
      navigateTo: `${PAGE.profile.menuPath}`,
    },
  ];

  function Logout() {
    dispatch(removeAuthAction());
    addToast(toastType.success, t(i18Enum.Navigation_Menu_Logout));
    navigate(PAGE.home.menuPath);
  }

  return (
    userData && (
      <Flex flexDirection={"column"} paddingTop={["15%", "5%", "0"]} $gap={1.2}>
        <Section justifyContent={"space-between"} alignItems={"center"}>
          <Flex $gap={0.5}>
            <Txt>{t(i18Enum.Navigation_Menu_Greeting)}</Txt>
            {/* <Txt color={colors.primaryBase}>{userData?.Name??'Mooored'}</Txt> */}
          </Flex>

          <Txt variant='error' $cursor='pointer' onClick={Logout}>
            {t(i18Enum.Navigation_Menu_Logout)}
          </Txt>
        </Section>

        <Grid
          gridTemplateColumns={["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"]}
          gridTemplateRows={["repeat(2, 1fr)", "1fr"]}
          style={{
            gridColumnGap: isMobile ? "10px" : "15px",
            gridRowGap: "15px",
          }}
        >
          {buttonLinks.map((button) => (
            <Link key={button.id} to={button.navigateTo}>
              <Button width={"100%"} type='button' variant={button.navigateTo == path.pathname ? "option" : "switcherGenre"} padding={["0.5em 0", "0.5em"]} onClick={() => button.onClick?.()}>
                <Txt $cursor='pointer' fontSize={["10px", "12px"]}>
                  {button.text}
                </Txt>
              </Button>
            </Link>
          ))}
        </Grid>
      </Flex>
    )
  );
}

export default MenuNavigate;
