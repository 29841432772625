import { IState } from "../SharedStateType";
import { UserShippingAddress } from "../user/UserTypes";
import { ProductImageDTO, ProductType, Tags } from "../product/ProductsTypes";

export type CartType = {
  ShoppingBasket?: ShoppingBasket;
  RelatedProducts?: ProductType[];
  cartOrderInfo?: {
    useDefaultShippingAddress?: boolean;
    billingAddressSameAsDeliveryAddress?: boolean;
    shippingAddress?: UserShippingAddress;
    billingAddress?: UserShippingAddress; //TODO diventerà UserBillingAddress??
  };
  defaultShippingAddress?: UserShippingAddress;
  paymentSessionId?: string;
  //frontend purpose
  isBuyNow?: boolean;
};

// export type Cart = {
//     id: string;
//     items?: ShoppingBasketItem[];
//     total: number;
//     itemsTotal: number;
//     itemsCount: number;
//     addedDate: Date;
//     shippingPrice: number;
//     shopGroupTotals: ShopGroupTotals[];
//     currencyKey?: number;
// }
//////////////////////////////type da integrare sostituendo Cart//////////////////////

export type ShoppingBasket = {
  Id?: string;
  Shops?: BasketShopDTO[];
  ItemsCount?: number;
  TotalPrice?: number;
  //frontend Purpose
  processingProducts?: string[];
};

export type BasketShopDTO = {
  Id: string;
  Name: string;
  Variants: BasketItem[];
  TotalNetPrice: number;
  TotalDiscountedPrice: number;
  ShippingOption: ShippingOptionDTO;
  ShippingOptionsList?: ShippingOptionDTO[];
  TotalPrice: number;
};

export type BasketItem = {
  Id: string;
  BasketItemId: string;
  ProductName: string;
  Tags: Tags[];
  Price: number;
  DiscountedPrice?: number;
  ShippingOption?: ShippingOptionDTO;
  Quantity: number;
  Images: ProductImageDTO[];
};

export type ShippingOptionDTO = {
  Id: string;
  FreeShipping: boolean;
  FreeShippingMinAmount?: number;
  Name?: string;
  Price: number;
};

//////////////////////////////////////////////
export type ShippingInfo = {
  email?: string;
  shippingName?: string;
  shippingAddressLine?: string;
  shippingZIPCode?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingCountryKey?: number;
  shippingPhoneNumber?: string;
  shippingCallingCodeKey?: number;
  shippingNote?: string;

  billingName?: string;
  billingAddressLine?: string;
  billingZIPCode?: string;
  billingCity?: string;
  billingState?: string;
  billingCountryKey?: number;

  currencyKey?: number;
};

export type ShoppingBasketItem = {
  productId: string;
  productName: string;
  shippingOptionId?: string;
  shippingOptionPrice?: number;
  shippingOptionName?: string;
  shopId?: string;
  shopName: string;
  shopCode: string;
  shopPicture: string;
  shopLocation: string;
  colorKey?: number;
  sizeKey?: number;
  sellingPrice?: number;
  qty: number;
  lineTotal: number;
  shippingOptions: ShopShippingOption[];
  unitsInStock: number;
  attributes: {
    ColorKey: number;
    CategoryKey: number;
    MaterialKey: number;
    FeatureKey: number;
    SizeKey: number;
  };
  sku: string;
  shortDescription: string;
  code: string;
  editorChoice: boolean;
  entityId?: string;
  picture?: string;
  name?: string;
  location?: string;
  coverPicture?: string;
  followersCount?: number;
  addedDate?: Date;
};

export type ShopShippingOption = {
  id?: string;
  addedby?: string;
  addedDate?: Date;
  additionalInformation?: string;
  availableTo?: any;
  daysMax?: number;
  daysMin?: number;
  freeShipping?: boolean;
  freeShippingMinAmount?: number;
  name?: string;
  price?: number;
  shopId?: string;
};
export type ShopGroupTotals = {
  shopId: string;
  total: number;
  itemsTotal: number;
  itemsCount: number;
  shippingPrice: number;
};

//reducer

export type CartStateType = {
  cart: IState<CartType>;
};

export const CART = "cart";
export type CART = typeof CART;

export const GET_SHOPPING_BASKET = `${CART}/getShoppingBasketAction`;
export type GET_SHOPPING_BASKET = typeof GET_SHOPPING_BASKET;

export const ADD_ITEM_BASKET = `${CART}/addItemAction`;
export type ADD_ITEM_BASKET = typeof ADD_ITEM_BASKET;

export const BUY_NOW_ITEM = `${CART}/buyNowAction`;
export type BUY_NOW_ITEM = typeof BUY_NOW_ITEM;

export const UPDATE_CART_BASKET = `${CART}/updateShoppingBasketAction`;
export type UPDATE_CART_BASKET = typeof UPDATE_CART_BASKET;

export const DELETE_BASKET_ITEM = `${CART}/deleteItemAction`;
export type DELETE_BASKET_ITEM = typeof DELETE_BASKET_ITEM;

export const SET_SHIPPING_OPTION = `${CART}/setShippingOptionAction`;
export type SET_SHIPPING_OPTION = typeof SET_SHIPPING_OPTION;

export const CREATE_ORDER = `${CART}/createOrderAction`;
export type CREATE_ORDER = typeof CREATE_ORDER;

export const BUY_NOW_CANCEL = `${CART}/buyNowCancelAction`;
export type BUY_NOW_CANCEL = typeof BUY_NOW_CANCEL;
