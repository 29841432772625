import { useContext, useEffect, useRef, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import styled from "styled-components";

import { capitalize } from "../../utils";
import { TypeCalendar } from "./CalendarField";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import ArrowSvg from "../atoms/ArrowSvg";
import Grid from "../primitives/Grid";

interface Calendar {
  show: TypeCalendar;
  handleShow: (value: TypeCalendar) => void;
  value: Date | null;
  onChange: (value?: Date) => void;
}

function Calendar({ show, value, handleShow, onChange }: Calendar) {
  const { customTheme } = useContext(CustomThemeContext);
  const color = customTheme.colors;
  const [calendar, setCalendar] = useState<Date[]>([]);
  const [date, setDate] = useState<Date>( value ? value : new Date());
  const [year, setYear] = useState<number>(date.getFullYear());

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setCalendar(fnCalendar(date.getMonth(), date.getFullYear()));
  }, [date]);

  const changeDay = (value: Date) => {
    let newDate = new Date(date.setDate(value.getDate()));
    setDate(newDate)
    onChange(newDate)
    handleShow("default");
  };

  const changeMonth = (value: number) => {
    let newDate = new Date(date.setMonth(value));
    setDate(newDate);
    onChange(newDate)
    handleShow("date");
  };

  const changeYear = (value: number) => {
    let newDate = new Date(date.setFullYear(value));
    setDate(newDate);
    onChange(newDate)
    handleShow("date");
  };







  const verifyDate = () => !fnRangeAge(year).includes(new Date().getFullYear());

  const handleYear = (value: number) => setYear(value);

  return (
    <Flex
      width={[1]}
      minHeight={"180px"}
      flexDirection={"column"}
      backgroundColor={color.grayBase}
      position={"absolute"}
      zIndex={10}
      top={0}
      padding={18}
      borderRadius={18}
      $gap={1.5}
      tabIndex={0}
      onBlur={() => {
        handleShow("default");
      }}
      ref={divRef}
    >
      <Flex
        as="nav"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        width={[1]}
      >
        {show === "date" && (
          <Txt
            variant="linkLight"
            fontSize={[3]}
            color={color.primaryBg}
            onPointerUp={() => handleShow("month")}
          >
            {capitalize(dateMonth[date.getMonth()].name)}
          </Txt>
        )}
        {(show === "month" || show === "year") && (
          <ArrowSvg widthCustom={"s"} handleClick={() => handleShow("date")} />
        )}
        {show !== "year" && (
          <Txt
            variant="linkLight"
            fontSize={[3]}
            color={color.primaryBg}
            onPointerUp={() => handleShow("year")}
          >
            {date.getFullYear()}
          </Txt>
        )}
      </Flex>
      <Grid
        as={"ul"}
        gridTemplateColumns={`repeat(${show === "date" ? 7 : 5},1fr)`}
        alignItems={"center"}
        justifyItems={"center"}
        width={[1]}
        style={{ userSelect: "none" }}
      >
        {/* Calendar */}
        {show === "date" &&
          days.map((name) => (
            <Txt
              key={name}
              as={"li"}
              fontSize={[2]}
              variant="medium"
              $textTransform="capitalize"
              color={color.thirdTextContrast}
              marginBottom={[2]}
            >
              {name}
            </Txt>
          ))}
        {show === "date" &&
          calendar?.map((day, i) => (
            <Txt
              style={{
                gridColumnStart: i === 0 ? `${day.getDay() + 1}` : "auto",
                cursor: "pointer",
              }}
              key={i}
              as={"li"}
              fontSize={[2]}
              variant="light"
              lineHeight={"normal"}
              color={
                value && day.toDateString() === date.toDateString()
                  ? color.primaryBase
                  : color.thirdTextContrast
              }
              onPointerUp={() => changeDay(day)}
            >
              {day ? day.getDate() : null}
            </Txt>
          ))}
        {/* Month*/}
        {show === "month" &&
          dateMonth?.map((month, i) => (
            <Txt
              as={"li"}
              key={i}
              style={{ cursor: "pointer" }}
              fontSize={[2]}
              variant="light"
              lineHeight={"300%"}
              color={
                month.value === date.getMonth()
                  ? color.primaryBase
                  : color.thirdText
              }
              onClick={() => changeMonth(month.value)}
            >
              {month.name}
            </Txt>
          ))}
        {/* Year */}
        {show === "year" && (
          <>
            {fnRangeAge(year).map((year, index) => (
              <>
                {index === 0 && (
                  <Arrow
                    onClick={() => handleYear(year - 1)}
                    key={"arrow-left"}
                  />
                )}
                {index === 3 && verifyDate() && (
                  <Arrow
                    key={"arrow-right"}
                    deg={180}
                    onClick={() => handleYear(fnRangeAge(year)[17] + 1)}
                  />
                )}
                <Txt
                  as={"li"}
                  key={year}
                  style={{ cursor: "pointer" }}
                  fontSize={[2]}
                  variant="light"
                  lineHeight={"300%"}
                  color={
                    year === date.getFullYear()
                      ? color.primaryBase
                      : color.thirdText
                  }
                  onClick={() => changeYear(year)}
                >
                  {year}
                </Txt>
              </>
            ))}
          </>
        )}
      </Grid>
    </Flex>
  );
}

export default Calendar;

const Arrow = styled.i<{ deg?: number }>`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 10 12' fill='none'%3E%3Cpath d='M0 6L10 11.7735V0.226497L0 6Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: content;
  width: 10px;
  height: 12px;
  cursor: pointer;
  padding: 0 12px;
  transform: rotate(${({ deg }) => deg || 0}deg);
`;

/* 

UTILS

 */
export function parseDate(date: string, format = "dd/mm/yyyy"): Date {
  // Validate the date.
  const dateRegExp = new RegExp(`^(${format})$`);
  if (!dateRegExp.test(date)) null;

  // Split the date into its components.
  const [day, month, year] = date.split("/");
  // Convert the date to a Date object.
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
}

export const fnCalendar = (month: number, year: number): Date[] => {
  let calen = [];
  const days = new Date(year, month + 1, 0).getDate();
  for (let day = 1; day <= days; day++) {
    calen.push(new Date(year, month, day));
  }
  return calen;
};

export const fnRangeAge = (year: number): number[] => {
  const initial = new Date().getFullYear() - 1;
  const resta = initial - year;
  const resto = resta % 18;
  const startInterval = year - (17 - resto);
  const interval = Array.from(
    { length: startInterval + 18 - startInterval },
    (_, index) => index + startInterval
  );
  if (interval.includes(initial)) interval.push(initial + 1);
  return interval;
};

export const days: string[] = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
export const dateMonth: { name: string; value: number }[] = [
  {
    name: "jan",
    value: 0,
  },
  {
    name: "feb",
    value: 1,
  },
  {
    name: "mar",
    value: 2,
  },
  {
    name: "apr",
    value: 3,
  },
  {
    name: "may",
    value: 4,
  },
  {
    name: "jun",
    value: 5,
  },
  {
    name: "jul",
    value: 6,
  },
  {
    name: "aug",
    value: 7,
  },
  {
    name: "sep",
    value: 8,
  },
  {
    name: "oct",
    value: 9,
  },
  {
    name: "nov",
    value: 10,
  },
  {
    name: "dec",
    value: 11,
  },
];
