import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import SliderSingle from "./SlaiderSingle";
import { useState, useCallback, ChangeEvent, useEffect, useRef } from "react";
import Box from "components/primitives/Box";
import CropImage from "components/primitives/CropImage";
import { FileUploadDTO } from "utils/generalTypes/shared";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface UploadImage {
  onClose: () => void;
  chooseItAction: (img: FileUploadDTO) => void;
  choosedImage?: (img:string)=>void
}

function UploadImage({ onClose,chooseItAction, choosedImage }: UploadImage) {
  const [zoom, setZoom] = useState(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [imgCropper, setImgCropper] = useState<FileUploadDTO | undefined>(undefined);
  const [image, setImage] = useState<ChangeEvent<HTMLInputElement> | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (imgCropper) onSubmit(imgCropper);
  }, [imgCropper]);

  const handleCropImg = useCallback((img: string | undefined) =>{ 
    if(!img)
    {return}
   const avatar:FileUploadDTO = {
    Name:image?.target.files![0].name,
    Base64:img?.split(';base64,')[1],
    Type:image?.target.files![0].type
   }
    setImgCropper(avatar)
    choosedImage?.(img)
  }, [image]);

  const handleZoom = (zoom: number) => setZoom(1 + zoom / 100); //scale zoom 1%

  const handleSubmit = () => image && setSubmit(true);

  const handleClose = () => handleSubmit();

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0];

    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convertir a megabytes

      if (fileSizeInMB > 4.0) {
        image && setImage(undefined);
        setError(true);
      } else {
        error && setError(false);
        setImage(e);
      }
    }
  };

  const handleDelete = () => {
    setError(false);
    if (!imageRef.current) {
      return;
    }
    imageRef.current.value = null!;
    setImage(undefined);
  };

  const onSubmit = (img:FileUploadDTO) => {
    
    //submit image type: Base64,
    if (img.Base64) {
      chooseItAction(img)
      onClose();
      
    }
  };

  return (
    <Flex width={["100%", "60%"]} height={["100%"]} flexDirection={"column"} padding={["4em 1.5em", "2em 0"]}>
      <Txt as='h5' variant='light' fontSize={["18px"]} textAlign={"left"} position={"absolute"} top={[80, 25]}>
        {t(i18Enum.CropImageModal_Title)}
      </Txt>

      <Box position={"relative"} width={[1]} height={240}>
        <CropImage image={image} zoom={zoom} submit={submit} handleCropImg={handleCropImg} />
      </Box>

      <SliderSingle margin={"20px 0"} rangeMax={300} handleValues={(num) => handleZoom(num.minValue)} />

      <Txt variant='light' textAlign={"left"} fontSize={[3]} $textTransform='inherit'>
        {t(i18Enum.CropImageModal_FormatValidation)}
      </Txt>
      {error && (
        <Txt variant='error' textAlign={"left"} fontSize={[3]} $textTransform='inherit'>
          {t(i18Enum.CropImageModal_Error)}
        </Txt>
      )}
      <Flex $gap={1.5} marginTop={42}>
        <Button as='label' htmlFor='update-image' variant='register' fontSize={[2]} paddingLeft={0} paddingRight={0} width={"100%"} minWidth={"none"}>
          {t(i18Enum.CropImageModal_Button_UploadPhoto)}
        </Button>
        <input id='update-image' ref={imageRef} accept='.png, .jpg,' type='file' multiple={false} style={{ display: "none" }} onChange={(e) => handleImage(e)} />
        <Button variant='danger' fontSize={[2]} width={"100%"} onClick={handleDelete}>
          {t(i18Enum.CropImageModal_Button_DeletePhoto)}
        </Button>
      </Flex>

      <Flex marginTop={4} justifyContent={"flex-end"} $gap={1.5}>
        <Flex width={"100%"} />
        <Button disabled={!!imgCropper?.Base64} variant='login' fontSize={[2]} width={"100%"} minWidth={0} onClick={handleClose}>
          {t(i18Enum.CropImageModal_Button_ChoosePhoto)}
        </Button>
      </Flex>
    </Flex>
  );
}

export default UploadImage;
