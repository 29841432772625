import { ApiSimpleResponse, BaseResponse } from "api/types/responses";
import { getOrderParentByIdCall } from "api/userCalls/userCalls";
import { XLg } from "react-bootstrap-icons";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { OrdersParentDetailsPage } from "pages/OrdersParentPageDetails";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderType, OrderDTO } from "redux/features/user/UserTypes";
import { priceNum, toastType } from "utils";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { UserActions, deleteOrderAction } from "redux/features/user/userSlice";
import { useAppDispatch } from "redux/app/hooks";
import Tooltip from "components/primitives/Tooltip";
import { ConfirmDialog } from "./ConfirmDialog";
import { debug1 } from "theme/theme.styled";

export enum StatusOrder {
  /// cancellato dall'utente'
  "Canceled" = -2,
  /// cancellato dallo shop
  "Deleted" = -1,
  /// ricevuto dallo shop
  "Pending" = 0,
  /// In attesa della risposta del cliente
  "WaitingCustomer" = 1,
  /// Accettato dallo shop
  "Accepted" = 2,
  /// Spedito
  "Delivered" = 3,
}

export function OrderNoPayment({ Id, ShopId, ShopName, Total, Products, ItemsTotal, Number, Status, StripeCheckoutUrl }: OrderType) {
  const { customTheme } = useContext(CustomThemeContext);
  const { t } = useTranslation();
  const colors = customTheme.colors;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile, openAsideMenu, addToast, setIsLoading } = useContext(AppGeneralContext);
  const numberofCard = isMobile ? 5 : 6;
  const [showConfirm, setShowConfirm] = useState(false);
  const handleDetail = async () => {
    //TO DO CHiamata order by Id
    setIsLoading(true);
    if (!Id) {
      addToast(toastType.error, t(i18Enum.ErrorPage500_ErrorLabel));
      setIsLoading(false);
      return;
    }

    const res: ApiSimpleResponse<BaseResponse<OrderDTO>> = await getOrderParentByIdCall(Id);

    if (!res.isSuccess || !res.response?.Data) {
      addToast(toastType.error, res.response?.ErrorMessage ?? t(i18Enum.Error_Occurred));
      setIsLoading(false);
      return;
    }

    if (isMobile) {
      navigate(PAGE.ordersParentDetail.menuPath, {
        state: {
          ...res.response.Data,
        },
      });
      setIsLoading(false);
      return;
    }

    openAsideMenu<OrderDTO>(<OrdersParentDetailsPage />, res.response.Data);
    setIsLoading(false);
  };

  function PayNow() {
    if (Id) {
      dispatch(UserActions.refreshStripeUrlAction({ id: Id, action: (url: string) => navigateToPayment(url) }));
    }
  }

  function navigateToPayment(url: string) {
    navigate(`${PAGE.paymentPending.menuPath}/${encodeURIComponent(url)}`);
  }

  function cancelOrder() {
    if (Id) {
      dispatch(deleteOrderAction(Id));
      return;
    }
  }

  return (
    <Flex
      width={["100%", "420px"]}
      justifyContent={"space-between"}
      position={"relative"}
      flexDirection={"column"}
      padding={"20px"}
      $gap={2}
      border={`1px solid ${colors.thirdLine}`}
      borderRadius={"16px 16px 16px 0"}
      marginTop={[10]}
    >
      {!showConfirm ? (
        <>
          <Flex position={"absolute"} top={3} right={3}>
            <Tooltip content={t(i18Enum.Orders_Order_Confirm_DeleteOrder)}>
              <XLg fontSize={"20px"} cursor={"pointer"} onClick={() => setShowConfirm(true)} />
            </Tooltip>
          </Flex>
          <Flex marginTop={4} width={[1]} justifyContent={"space-between"}>
            <Txt variant='light' color={colors.thirdText} fontSize={[2]} textAlign={"left"}>
              {t(i18Enum.Orders_OrderParent_Number)}
            </Txt>

            <Txt variant='light' color={colors.primaryText} fontSize={[2]} textAlign={"right"}>
              {`${Number}`}
            </Txt>
          </Flex>

          <Flex width={[1]} justifyContent={"space-between"}>
            <Txt variant='light' color={colors.thirdText} fontSize={[2]} textAlign={"left"}>
              {t(i18Enum.Common_Total)}
            </Txt>

            <Txt variant='light' color={colors.primaryText} fontSize={[2]} textAlign={"right"}>
              {`${priceNum(ItemsTotal ?? 0)}€`}
            </Txt>
          </Flex>
          <Flex width={[1]} justifyContent={"space-between"}>
            <Button variant='cancel' fontSize={[3]} width={"fit-content"} padding={"0.5em 1.2em"} onClick={handleDetail} marginRight={["0px", "10px"]} marginBottom={["10px", "0px"]}>
              {t(i18Enum.Orders_Order_ViewDetail)}
            </Button>
            <Button variant='primary' width={"fit-content"} padding={"0.5em 1.2em"} onClick={PayNow}>
              <Txt variant='medium' fontSize={[3]} color={"black"} $cursor='pointer'>
                {t(i18Enum.Orders_Order_PayNow)}
              </Txt>
            </Button>
          </Flex>
        </>
      ) : (
        <Flex width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
          <ConfirmDialog
            description={t(i18Enum.Orders_Order_Confirm_DeleteOrder)}
            cancelText={t(i18Enum.Common_Back)}
            confirmText={t(i18Enum.Orders_Order_Button_DeleteOrder)}
            customGap={3.5}
            hide={() => setShowConfirm(false)}
            deleteCallback={cancelOrder}
          />
        </Flex>
      )}

      {/*
      <Txt
        variant="light"
        color={colors.thirdText}
        fontSize={[2]}
        textAlign={"left"}
      >
        {"promocode"}
      </Txt>
 
      <Txt
        variant="light"
        color={colors.primaryText}
        fontSize={[2]}
        textAlign={"right"}
      >
        {promoCode}
      </Txt> */}
    </Flex>
  );
}
