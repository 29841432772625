import { PayloadAction } from "@reduxjs/toolkit";
import { ApiSimpleResponse, BaseResponse, PaginatedResponse } from "api/types/responses";
import { BrandType, GET_BRANDS_LIST, GET_BRAND_BY_ID } from "./BrandTypes";
import { getBrandByShopIdCall, getBrandsListCall } from "api/brandCalls/brandCall";
import { getBrandByIdSuccessAction, manageBrandErrorAction, getBrandsListErrorAction, getBrandsListSuccessAction } from "./brandSlice";
import { put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle } from "utils/const/const";
import { BrandsListRequest } from "api/types/requests/brandRequest";

function* getBrandByIdSaga({ payload }: PayloadAction<string>) {
  const brandCallResponse: ApiSimpleResponse<BaseResponse<BrandType>> = yield getBrandByShopIdCall(payload);
  if (!brandCallResponse.isSuccess || !brandCallResponse.response || !brandCallResponse.response.Data) {
    toast.error(brandCallResponse.error ?? t(i18Enum.ErrorPage500_ErrorLabel), toastErrorStyle);
    yield put(manageBrandErrorAction(brandCallResponse.error ?? ""));
    return;
  }
  yield put(getBrandByIdSuccessAction(brandCallResponse.response.Data));
  // yield put(getProductListByBrandIdAction({ id: brandCallResponse.response.Id, params }))
}

function* getBrandsListSaga({ payload }: PayloadAction<BrandsListRequest>) {
  const res: ApiSimpleResponse<PaginatedResponse<BrandType>> = yield getBrandsListCall(payload);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(getBrandsListErrorAction(res.error ?? ""));
    return;
  }
  yield put(getBrandsListSuccessAction(res.response));
}

export function* watchGetBrand() {
  yield takeLatest(GET_BRAND_BY_ID, getBrandByIdSaga);
  yield takeLatest(GET_BRANDS_LIST, getBrandsListSaga);
}
