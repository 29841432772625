import React, { useEffect, useState } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import { capitalize, privateCharacters } from "../../utils";
import Flex from "../primitives/Flex";
import Input from "../primitives/Input";
import Label from "../primitives/Label";
import Txt from "../primitives/Txt";
import Box from "../primitives/Box";
import ViewHideButton from "./ViewHideButton";

interface InputFieldProps extends React.ComponentPropsWithoutRef<"input"> {
  id: string;
  label?: string;
  error?: string;
  spaceProp: SpaceProps & WidthProps;
  isFocused?: boolean;
  isPrivate?: boolean;
  autoComplete?: string;
  customType?: string;
}

const InputFieldWardrobe = React.forwardRef<HTMLInputElement, InputFieldProps>(({ isPrivate, isFocused, id, error, spaceProp, autoComplete, customType, ...props }, ref) => {
  const inputRef = ref;

  const [privateType, setPrivateType] = useState<boolean | undefined>(isPrivate);
  const handlePrivateType = () => {
    setPrivateType(!privateType);
  };
  const [focus, setFocus] = useState(isFocused);

  return (
    <Flex flexDirection={"column"} alignItems={"start"} {...spaceProp}>
      <Box position={"relative"} width={[1]}>
        <Label
          fontSize={focus || props.value ? 2 : 3}
          paddingLeft={["18px"]}
          htmlFor={id}
          position={"absolute"}
          top={focus || props.value ? "-23px" : "6px"}
          $transition={"all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)"}
        >
          {props.placeholder}
        </Label>
        <Input
          {...props}
          id={id}
          onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
            setFocus(true);
            props.onFocus?.(e);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
            setFocus(false);
            props.onBlur?.(e);
          }}
          color={privateType && props.value ? "transparent" : ""}
          width={[1]}
          fontSize={[3]}
          variant='primary'
          padding={["0.48em 20px"]}
          style={{ textTransform: "inherit" }}
          placeholder={""}
          autoComplete={autoComplete ?? "off"}
          type={privateType ? "password" : "text"}
        />
        {privateType && props.value && (
          <Txt color={"transparent"} as={"label"} htmlFor={id} fontSize={[3]} padding={["0.48em 20px"]} position={"absolute"} left={0} top={"0.3em"}>
            {props.value && props.value}
          </Txt>
        )}
        {privateType !== undefined && <ViewHideButton position={"absolute"} top={0} right={0} variant={privateType ? "hidden" : "see"} handleClick={handlePrivateType} />}
      </Box>

      {error && !isFocused && (
        <Txt padding={"13px  0 0 18px"} variant='error' fontSize={[3]} textAlign={"left"}>
          {capitalize(error)}
        </Txt>
      )}
    </Flex>
  );
});

export default InputFieldWardrobe;
