import { AppGeneralContext } from "context/AppGeneralContext";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "assets/icons/grid.svg?react";
import Column from "assets/icons/column.svg?react";
import { CardBack } from "components/molecules";
import Flex from "components/primitives/Flex";
import { CustomThemeContext } from "context/ThemeContext";
import { H3, H6 } from "theme/theme.styled";
import Txt from "components/primitives/Txt";
import Section from "components/primitives/Section";
import GridCard, { CardView } from "components/organisms/GridCard";
import { CardType } from "api/types/responses/cardsRequest";
import useIntersection from "utils/hooks/useIntersection";
import Lottie from "lottie-react";
import loader from "assets/lotties/loader.json";
import ArrowSvg from "components/atoms/ArrowSvg";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { BrandActions } from "redux/features/brand/brandSlice";
import { ProductActions } from "redux/features/product/productSlice";
import { i18Enum } from "i18n/types/translationType";
import { t } from "i18next";
import { PAGE } from "utils/const/pagePath";
import { getBrandIdFromCode } from "api/brandCalls/brandCall";

const initialSearchParams: ProductsListRequest = {
  Name: "",
  MinPrice: 0,
  MaxPrice: null,
  TagsIds: [],
  OrderBy: "Price",
  OrderDirection: "desc",
  PageNum: 1,
  PageSize: 12,
  IsFiltering: false,
  IsPromo: false,
};
//TODO da implementare Flusso API-redux
export const Brand = () => {
  const shopId = useLocation().state;
  const { code } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile, setIsLoading } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const [cardView, setCardView] = useState<CardView>(CardView.card);
  const bottom = useRef<HTMLDivElement | null>(null);
  const inViewport = useIntersection(bottom, "10px");
  const [currentPage, setCurrentpage] = useState<number>(0);
  const { data: brand, isLoading: isBrandFerching, errors: getBrandError } = useAppSelector((state) => state.brand.brand);
  const pager = useAppSelector((state) => state.product.productListByBrand.data?.Pager);
  const [searchParams, setSearchParams] = useState<ProductsListRequest>(initialSearchParams);

  useEffect(() => {
    if (!brand) {
      return;
    }
    const viewItemList = brand?.Products?.map((product) => {
      return {
        item_id: product.Id,
        item_name: product.Name,
        item_brand: product.Shop.Name,
        index: product.Order,
        item_variant: product.Variants?.[0].Sku,
      };
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: brand?.Id,
        item_list_name: brand?.Name,
        items: viewItemList,
      },
    });
    console.log({ viewItems: viewItemList });
  }, [brand]);

  useEffect(() => {
    setIsLoading(isBrandFerching);
  }, [isBrandFerching]);

  const fetchBrandId = async () => {
    if (code === null || code === undefined) {
      navigate(PAGE.notFound.menuPath);
      return;
    }
    const resp = await getBrandIdFromCode(code ?? "");
    if (!resp.isSuccess) {
      navigate(PAGE.notFound.menuPath);
    } else {
      dispatch(BrandActions.getBrandByIdAction(resp.response?.Data ?? ""));
      dispatch(ProductActions.getProductListByBrandIdAction({ id: resp.response?.Data ?? "", params: searchParams }));
    }
  };

  const fetchBrandFromId = useCallback(
    (shopId: string, brandId: string) => {
      if (brandId === shopId) {
        return;
      }
      dispatch(BrandActions.brandResetData());
      dispatch(BrandActions.getBrandByIdAction(shopId));
    },

    [shopId]
  );

  useEffect(() => {
    if (!shopId) {
      fetchBrandId();
      return;
    }

    if (shopId && !brand?.Id) {
      dispatch(BrandActions.getBrandByIdAction(shopId.id));
      return;
    }

    if (shopId && brand?.Id) {
      fetchBrandFromId(shopId.id, brand.Id);
    }
  }, [fetchBrandFromId, shopId, brand?.Id]);

  //Chiamata per InfiniteScroll
  useEffect(() => {
    if (!searchParams || searchParams.IsFiltering || !shopId) {
      return;
    }
    dispatch(ProductActions.getProductListByBrandIdAction({ id: shopId.id, params: searchParams }));
  }, [searchParams?.PageNum, searchParams.IsFiltering]);

  //gestione infinite scroll
  useEffect(() => {
    if (inViewport?.isVisible) {
      if (!pager?.TotalPages || currentPage >= pager?.TotalPages) {
        return;
      }
      let newPage = currentPage + 1;
      setSearchParams((prev) => ({ ...prev, currentPage: newPage }));
      setCurrentpage(newPage);
    }
  }, [inViewport.isVisible]);

  return (
    <>
      <Flex width={[1]} maxWidth={[null, maxW[0]]} position={"relative"}>
        <Flex paddingTop={4} top={[80, 0]} left={"5%"} zIndex={2} flexDirection={"row"} position={"absolute"} onClick={() => navigate(-1)}>
          <ArrowSvg width={[40, 30]} />{" "}
          {!isMobile && (
            <Txt paddingLeft={10} variant='link' color={colors.primaryBase}>
              {t(i18Enum.Common_Back)}{" "}
            </Txt>
          )}
        </Flex>
      </Flex>
      <Flex paddingTop={["120px"]} />
      <CardBack height={["300px", " 400px"]} width={["100%"]} img={brand?.Picture ?? ""} />
      <Section width={[1]} maxWidth={[null, maxW[0]]} padding={["0 5%"]} flexDirection={"column"} alignItems={"start"}>
        <Txt as={"h3"} fontSize={H3}>
          {brand?.Name}
        </Txt>
        {/* <Txt as={"h6"} fontSize={H6} color={colors.primaryBase}>{brand?.Location}</Txt> */}
        <Txt as={"p"} textAlign={["left"]} fontSize={[4, 5]} variant='light' style={{ textTransform: "none" }}>
          {brand?.Description}
        </Txt>
        <Txt as={"p"} textAlign={["left"]} fontSize={[3]} variant='light' style={{ textTransform: "none" }}>
          {brand?.About}
        </Txt>
        {/* <Flex width={[1]} justifyContent={"end"} paddingTop={[4]}>
                    <Link to={`mailto:${brand?.Email}`}  >
                        <Button variant='register'>
                            CONTACT
                        </Button>
                    </Link>
                </Flex> */}
        <Flex padding={["10% 0"]} maxWidth={[maxW[0]]} width={[1]} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Txt as={"h6"} $textTransform={"default"} fontFamily={"BauPro"} fontWeight={"500"} fontSize={H6}>
            {brand?.Name}
          </Txt>
          <Flex flexDirection={"row"} justifyContent={"end"} alignItems={"center"}>
            <Column
              stroke={cardView === CardView.images ? colors.primaryBase : colors.thirdBase}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setCardView((x) => (x === CardView.images ? CardView.card : CardView.images));
              }}
            />
            <Grid
              stroke={cardView === CardView.card ? colors.primaryBase : colors.thirdBase}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCardView((x) => (x === CardView.card ? CardView.images : CardView.card));
              }}
            />
          </Flex>
        </Flex>
      </Section>
      <Section width={[1]} maxWidth={[null, maxW[0]]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
        <GridCard isHomeCard={false} products={brand?.Products} variant={cardView} />
      </Section>
      <Section ref={bottom} width={["50%", "20%"]} maxWidth={[null, maxW[0]]} padding={["5% 0"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} position={"relative"}>
        {isBrandFerching && <Lottie animationData={loader} autoPlay />}
      </Section>
      <Flex width={[1]} justifyContent={"end"} paddingBottom={[4]} />
    </>
  );
};
