import { put, takeLatest } from "redux-saga/effects";
import { GET_REGISTER_ACTION } from "./RegisterTypes";
import { PayloadAction } from "@reduxjs/toolkit";
import { RegisterRequest } from "api/types/requests/registerRequest";
import { ApiSimpleResponse, BaseResponse} from "api/types/responses";
import { registerCall } from "api/registerCall";
import { getRegisterErrorAction, getRegisterSuccessAction } from "./registerSlice";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";

function* getRegisterSaga({ payload }: PayloadAction<RegisterRequest>) {
    const res: ApiSimpleResponse<BaseResponse<string>> = yield registerCall(payload)
    if(!res.isSuccess|| !res.response){
        yield put(getRegisterErrorAction(res.error?? t(i18Enum.Error_Occurred)))
        return
    }
    yield put(getRegisterSuccessAction(res.response?.Data ?? ''));
}





export function* watchGetRegister() {
    yield takeLatest(GET_REGISTER_ACTION, getRegisterSaga)
}