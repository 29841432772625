import { useContext } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import { ModalContext } from "context/ModalContext";
import { VolumeMute, VolumeUp } from "react-bootstrap-icons";
import Flex from "components/primitives/Flex";
import VideoPlayer from "components/primitives/VideoPlayer";
import { HomepagePopupDTO } from "utils/generalTypes/popupSlider";
import { saveCurrentTimestamp } from "utils/functions/saveCurrentTimestamp";
import PopupSlideContainer from "components/atoms/PopupSlideContainer";
import ProgressiveImg from "components/atoms/ProgressiveImg";

interface popupContentProps {
  item: HomepagePopupDTO;
  id: string;
  isVideoMuted: boolean;
  onToggleMute: (id: string, isMuted: boolean) => void;
}

export const PopupContent = ({ item, id, isVideoMuted, onToggleMute }: popupContentProps) => {
  const { isMobile } = useContext(AppGeneralContext);
  const { closeModal } = useContext(ModalContext);

  const handleNavigation = (url?: string): void => {
    saveCurrentTimestamp();
    window.open(url, "_blank", "noopener,noreferrer");
    closeModal();
  };

  return (
    <Flex justifyContent={"center"} height={"100%"} width={"100%"} alignItems={"center"} padding={["1em", "1em", "1em", "0"]}>
      <Flex width={isMobile ? "100%" : "1100px"} height={isMobile ? "100%" : "92%"} justifyContent={"center"}>
        <PopupSlideContainer alignItems={isMobile ? "center" : "center"}>
          {isMobile ? (
            !item.IsVideoMobile ? (
              <ProgressiveImg
                style={{ cursor: "pointer", aspectRatio: "9/16", borderRadius: 16 }}
                height={"100%"}
                src={item.MediaMobileUrl}
                onClick={() => handleNavigation(item.RedirectUrl)}
                placeholderSrc={""}
              />
            ) : (
              <VideoPlayer src={item.MediaMobileUrl} aspectRatio='9/16' isVideoMuted={isVideoMuted} handleClick={() => handleNavigation(item.RedirectUrl)} />
            )
          ) : !item.IsVideoDesktop ? (
            <ProgressiveImg
              style={{ cursor: "pointer", borderRadius: 16, objectFit: "cover", objectPosition: "center" }}
              height={"100%"}
              src={item.MediaDesktopUrl}
              onClick={() => handleNavigation(item.RedirectUrl)}
              placeholderSrc={""}
            />
          ) : (
            <VideoPlayer src={item.MediaDesktopUrl} isVideoMuted={isVideoMuted} handleClick={() => handleNavigation(item.RedirectUrl)} />
          )}
        </PopupSlideContainer>
      </Flex>

      {(item.IsVideoDesktop && !isMobile || item.IsVideoMobile && isMobile) && (
        <Flex position={"absolute"} zIndex={20} bottom={isMobile ? 1 : 3} left={isMobile ? 1 : 3}>
          {isVideoMuted ? (
            <VolumeMute color='white' fontSize={"25px"} cursor={"pointer"} onClick={() => onToggleMute(id, !isVideoMuted)} />
          ) : (
            <VolumeUp color='white' fontSize={"25px"} cursor={"pointer"} onClick={() => onToggleMute(id, !isVideoMuted)} />
          )}
        </Flex>
      )}
    </Flex>
  );
};
