/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import Txt from "../components/primitives/Txt";
import { ModalTypeEnum, TitleEnum } from "../utils/generalTypes/enum";
import { ApiSimpleResponse, BaseResponse } from "../api/types/responses";
import Flex from "../components/primitives/Flex";
import Button from "components/primitives/Button";
import { CustomThemeContext } from "./ThemeContext";
import { H2, H3, debug1 } from "theme/theme.styled";
import Box from "components/primitives/Box";
import { StyledModalBody, StyledModalHeader } from "components/organisms/Modal/Modal";
import { DotsLoader } from "components/molecules/DotsLoader";
import CrossSvg from "components/atoms/CrossSvg";
import Image from "components/primitives/Image";
import logo from "../assets/icons/logo.svg";

export interface ModalContextType {
  modalType: ModalTypeEnum;
  setModalType: (modalType: ModalTypeEnum) => void;
  show: boolean;
  setShow: (show: boolean) => void;
  child: React.ReactNode;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  closeModal: () => void;
  openModalWithChildren: (child: React.ReactElement, type: ModalTypeEnum, onCloseAction?: () => void) => void;
}

const modalContextDefaultValue: ModalContextType = {
  modalType: ModalTypeEnum.message,
  setModalType: (_: ModalTypeEnum) => {},
  show: false,
  setShow: (_: boolean) => {},
  isLoading: false,
  setIsLoading: (_: boolean) => {},
  closeModal: () => {},
  openModalWithChildren: () => {},
  child: <></>,
};
export const ModalContext = createContext<ModalContextType>(modalContextDefaultValue);

interface ModalContextProviderProps {
  children: React.ReactElement;
}
export const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [show, setShow] = useState<boolean>(false);
  const [title, setTitle] = useState<TitleEnum | string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [child, setChild] = useState<React.ReactElement>();
  const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.message);

  const closeModal = () => {
    setShow(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!show) {
      setIsLoading(false);
      setChild(<></>);
      setModalType(ModalTypeEnum.message);
    }
  }, [show]);

  const openModalWithChildren = (child: React.ReactElement, type: ModalTypeEnum, onCloseAction?: () => void) => {
    setShow(true);
    setModalType(type);
    
    if (type === ModalTypeEnum.popup) {
      setChild(
        <>
          <StyledModalHeader>
            <Flex
              style={{ cursor: "pointer" }}
              flexDirection={"column"}
              onClick={() => {
                if (onCloseAction) onCloseAction();
                closeModal();
              }}
            >
              <Txt $cursor='pointer' variant='medium' fontSize={[3]}>
                Vai su
              </Txt>
              <Image src={logo} width={[100]} height={[20]} />
            </Flex>
          </StyledModalHeader>

          <StyledModalBody>
            {isLoading ? (
              <Flex justifyContent='center' alignItems='center'>
                <DotsLoader colors={colors.thirdBase} />
              </Flex>
            ) : (
              <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
                {child}
              </Flex>
            )}
          </StyledModalBody>
        </>
      );
    } else {
      setChild(child);
    }
  };

  const modalContextInitialValue: ModalContextType = {
    modalType,
    setModalType,
    show,
    setShow,
    isLoading,
    setIsLoading,
    closeModal,
    openModalWithChildren,
    child,
  };
  return <ModalContext.Provider value={modalContextInitialValue}>{children}</ModalContext.Provider>;
};
