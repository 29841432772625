
import { IState } from "../SharedStateType";
import { PaginatedResponse } from "api/types/responses";


export type ProductType = {
    Id?: string;
    Published?: boolean;
    LikesCount?: number;
    Name?: string;
    ShopId?: string;
    AddedDate?: Date;
    Code?: string;
    Description?: string;
    Gtin?: string;
    MetatagDescription?: string;
    MetatagKeywords?: string;
    MetatagTitle?: string;
    Sku?: string;
    Tags?:Tags[]
    ShowInBsportal?: boolean
    ExternalLink?: string;
    AddedBy?: string;
    LastModifiedDate?: string;
    EditorChoice?: boolean;
    PublishDate?: string;
    Composition?: string;
    Delivery?: string
    ShortDescription?: string;
    Order?: number;
    Shop: ShopDetailInProductDTO;
    Variants?: VariantDTO[];
}

export type ShopDetailInProductDTO = {
    Id?: string,
    Description?: string,
    Name?: string,
    Picture?: string,
    About?: string,
    CoverPicture?: string,
    ShopLocation?: string
    Code?: string,
}

export type ProductImageDTO = {
    Id?: string;
    Index?: number;
    ProductId?: string;
    VariantId?: string;
    Url?: string;
}

export type VariantDTO = {
    Id?: string;
    ProductId?: string;
    Tags?: Tags[];
    Colors?: Tags[];
    Sizes?: Tags[];
    Price?: number;
    DiscountedPrice?: number;
    LikesCount?: number;
    Images?: ProductImageDTO[];
    IsPublished?: boolean;
    IsSpicy?: boolean;
    IsWishlisted?:boolean;
    AddedDate?: string;
    PublishedDate?: string;
    LastModifiedDate?: string;
    ProductName?:string;
    Sku?: string;
}

export type Tags = {
    Id?: string
    ParentId?: string
    Label?: string
    Icon?: string
    IsDisabled?: boolean
    IsOnHeader?:boolean,
    IsExploreByCategories?:boolean,
    IsMultichoice?:boolean
    Tags:Tags[],
    IsCategory?:boolean
}


export type ProductStateType = {
    product: IState<ProductType>,
    productList: IState<PaginatedResponse<ProductType>>
    similarProducts: IState<ProductType[]>
    productListByBrand: IState<PaginatedResponse<ProductType>>

}


export const PRODUCT = 'product'
export type PRODUCT = typeof PRODUCT

export const SET_PRODUCT = `${PRODUCT}/setProductAction`;
export type SET_PRODUCT = typeof SET_PRODUCT; // Typescript line

export const SEARCH_PRODUCTS = `${PRODUCT}/searchProductAction`;
export type SEARCH_PRODUCTS = typeof SEARCH_PRODUCTS

export const GET_SIMILAR_PRODUCT = `${PRODUCT}/getSimilarProductAction`;
export type GET_SIMILAR_PRODUCT = typeof GET_SIMILAR_PRODUCT;

export const GET_PRODUCT_BY_ID = `${PRODUCT}/getProductByIdAction`
export type GET_PRODUCT_BY_ID = typeof GET_PRODUCT_BY_ID

export const GET_PRODUCT_LIST_BY_BRAND_ID = `${PRODUCT}/getProductListByBrandIdAction`;
export type GET_PRODUCT_LIST_BY_BRAND_ID = typeof GET_PRODUCT_LIST_BY_BRAND_ID;