import Section from "components/primitives/Section";
import React, { useContext, useMemo, useState } from "react";
import OutfitCardWrapper from "components/primitives/OutfitCardWrapper";
import { CustomThemeContext } from "context/ThemeContext";
import Txt from "components/primitives/Txt";
import Button from "components/primitives/Button";
import { OutfitHomePageDTO } from "api/types/responses/HomePageResponse";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface OutfitListProps {
  outfits: OutfitHomePageDTO[];
  selectedOutfit: OutfitHomePageDTO | null;
  setSelectedOutfit: (outfits: OutfitHomePageDTO) => void;
}
export const OutfitList = ({ outfits, selectedOutfit, setSelectedOutfit }: OutfitListProps) => {
  const data = useMemo(
    () =>
      outfits.filter((x) => {
        if (x.Title !== selectedOutfit?.Title) return x;
      }),
    [outfits, selectedOutfit]
  );
  const [cardSelected, setCardSelected] = useState<number>(0);
  const { customTheme } = useContext(CustomThemeContext);
  const maxW = customTheme.maxWidth;
  const { t } = useTranslation();

  function getY(currentIndex: number) {
    let h: number = 250;
    let y: number = ((h * 3) / 10) * currentIndex - 10 * currentIndex;
    return `-${y}px`;
  }

    return (
        <Section flexDirection={"column"} width={[1]}>
            {data.map((card, index) => {
                const variantImage = card.Products?.[0]?.Variants?.[0]?.Images?.[0]?.Url

                return (
                  <OutfitCardWrapper
                    key={card.Id}
                    transform={`translateY(${getY(index)})`}
                    hasShadow={false}
                    background={`url(${variantImage})`}
                    width={[1]}
                    style={{ cursor: "pointer" }}
                    $transition='all 2s'
                    variant={index === cardSelected ? "open" : "close"}
                    height={index === cardSelected ? 500 : 250}
                    onClick={() => setCardSelected(index)}
                    onMouseEnter={() => setCardSelected(index)}
                  >
                    <OutfitCardWrapper
                      hasShadow={false}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      background={"linear-gradient(80deg, rgba(0, 0, 0, 0.81) 4.94%, rgba(0, 0, 0, 0.19) 93.94%)"}
                      width={[1]}
                      variant={index === cardSelected ? "open" : "close"}
                      height={"100%"}
                      $transition='all 2s'
                    >
                      <Txt maxWidth={maxW[0]}>{" " + card.Title}</Txt>
                      {index === cardSelected && (
                        <>
                          <Txt marginBottom={[3]} fontWeight={"400"} width={["90%", "50%"]} fontFamily={"BauPro"} fontSize={[3, 4]} $textTransform='default' maxWidth={maxW[0]}>
                            {card.Description}
                          </Txt>
                          <Button
                            maxWidth={maxW[0]}
                            padding={"10px 20px"}
                            variant='switcherGenre'
                            onClick={() => {
                              setSelectedOutfit(card);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {t(i18Enum.OutfitBestSellers_Watch)}
                          </Button>
                        </>
                      )}
                    </OutfitCardWrapper>
                  </OutfitCardWrapper>
                );


            })}
        </Section>
    )
}
