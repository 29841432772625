import { FormField } from "components/organisms";
import Box from "components/primitives/Box";
import Grid from "components/primitives/Grid";
import { OptionType } from "components/primitives/Select";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useState } from "react";

interface ColorGridProps {
  values?: number[];
  handleChange: (ids: number[]) => void;
  onBlur?:()=> void
  onFocus?:()=> void
}

function ColorGrid({ values, handleChange, onFocus, onBlur }: ColorGridProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colorsTheme = customTheme.colors;
  const [colorsSelected, setColorsSelected]= useState<number[]>([])

  useEffect(()=>{
    handleChange(colorsSelected)
  },[colorsSelected])

  function addColor (value:number){
     setColorsSelected( x => [ ...x , value])
  }

  function deleteColor (value:number){
    let deletedColor = colorsSelected.filter(x=> x !== value)
    setColorsSelected(deletedColor)
  }



  return (
    <Grid gridTemplateColumns={"repeat(14,1fr)"} onFocus={()=>onFocus ?  onFocus() : null} onBlur={()=>onBlur ?  onBlur() : null}>
      {colors.map((color) => (
        <Box
          key={color.id}
          width={[1]}
          backgroundColor={color.label}
          borderColor={colorsTheme.primaryBase}
          borderStyle={"solid"}
          borderWidth={ values?.includes(color.id) ? "2px" : "0"}
          onClick={()=> values?.includes(color.id) ? deleteColor(color.id) : addColor(color.id)}
          style={{ aspectRatio: "1 / 1 ", cursor: "pointer" }}
        ></Box>
      ))}
    </Grid>
  );
}

export default ColorGrid;

export const colors: FormField[] = [
  {
    label: "#F44336",
    id: 0,
  },
  {
    label: "#E91E63",
    id: 1,
  },
  {
    label: "#9C27B0",
    id: 2,
  },
  {
    label: "#673AB7",
    id: 3,
  },
  {
    label: "#3F51B5",
    id:4,
  },
  {
    label: "#2196F3",
    id: 5,
  },
  {
    label: "#03A9F4",
    id:6
  },
  {
    label: "#08BED5",
    id:7
  },
  {
    label: "#009688",
    id:8
  },
  {
    label: "#4CAF50",
    id:9,
  },
  {
    label: "#8BC34A",
    id:10,
  },
  {
    label: "#CDDC39",
    id:11,
  },
  {
    label: "#FFEB3B",
    id:12,
  },
  {
    label: "#FFC107",
    id:13,
  },
  {
    label: "#FF9800",
    id:14,
  },
  {
    label: "#FF5722",
    id:15,
  },
  {
    label: "#795548",
    id:16,
  },
  {
    label: "#9E9E9E",
    id:17,
  },
  {
    label: "#5F7EA1",
    id:18,
  },
  {
    label: "#FFFFFF",
    id:19,
  },
  {
    label: "#000000",
    id:20,
  },
  {
    label: "#B9F4D6",
    id:21,
  },
  {
    label: "#C9F4E1",
    id:22,
  },
  {
    label: "#E0F6E9",
    id:23,
  },
  {
    label: "#CAF3D3",
    id:24,
  },
  {
    label: "#DFF0C6",
    id:25,
  },
  {
    label: "#DBF3B5",
    id:26,
  },
  {
    label: "#F5E5D6",
    id:27,
  },
  {
    label: "#BBF0F4",
    id:28,
  },
  {
    label: "#BCC7F4",
    id:29,
  },
  {
    label: "#F5AED6",
    id:30,
  },
  {
    label: "#E9E0F3",
    id:31,
  },
  {
    label: "#D9DFF7",
    id:32,
  },
  {
    label: "#DDE9F5",
    id:33,
  },
  {
    label: "#D6F4F4",
    id:34,
  },
  {
    label: "#F5F0D2",
    id:35,
  },
  {
    label: "#F5C1EF",
    id:36,
  },
  {
    label: "#D0D7F3",
    id:37,
  },
  {
    label: "#E0CEF4",
    id:38,
  },
  {
    label: "#F6D7E7",
    id:39,
  },
  {
    label: "#F4D1CB",
    id:40,
  },
  {
    label: "#F5ECB1",
    id:41,
  },
  {
    label: "#F4CDAC",
    id:42,
  },
  {
    label: "#F3C0BD",
    id:43,
  },
  {
    label: "#F3BDDD",
    id:44,
  },
  {
    label: "#F3BDDD",
    id:45,
  },
  {
    label: "#D3BCF4",
    id:46,
  },
  {
    label: "#BCDCF3",
    id:47,
  },
  {
    label: "#A3F1F3",
    id:48,
  },
  {
    label: "#F3E896",
    id:49,
  },
  {
    label: "#F5C49C",
    id:50,
  },
  {
    label: "#F5A4A3",
    id:51,
  },
  {
    label: "#DEDEDE",
    id:52,
  },
  {
    label: "#393939",
    id:53,
  },
  {
    label: "#F4D1CB",
    id:54,
  },
  {
    label: "#F5ECB1",
    id:55,
  },
];
