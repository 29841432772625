import { useContext, useMemo, useState } from "react";
import Card from "../molecules/Card";
import Grid from "../primitives/Grid";

import ProductCardImage from "components/atoms/ProductCardImage";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { ProductType } from "redux/features/product/ProductsTypes";
import { useAppDispatch } from "redux/app/hooks";
import { ProductActions } from "redux/features/product/productSlice";

export enum CardView {
  "images",
  "card",
  "cardMobile",
}
interface GridCardProps {
  products?: ProductType[] | undefined;

  variant?: CardView;
  isHomeCard: boolean;
}
export type IdCard = string | number;

function GridCard({ variant, isHomeCard, products }: GridCardProps) {
  const [idShow, setIdShow] = useState<IdCard>("");
  const grid = useMemo(() => {
    if (variant === CardView.images) {
      return ["repeat(4,1fr)", "repeat(5,1fr)", "repeat(5,1fr)", "repeat(5,1fr)"];
    } else if (variant === CardView.cardMobile) {
      return ["repeat(2,1fr)", "repeat(2,1fr)", "repeat(2,1fr)", "repeat(2,1fr)"];
    } else {
      return ["repeat(2,1fr)", "repeat(3,1fr)", "repeat(3,1fr)", "repeat(4,1fr)"];
    }
  }, [variant]);
  const { isMobile, asideIsOpen, setAsideIsOpen } = useContext(AppGeneralContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToProduct = (product: ProductType) => {
    if (asideIsOpen) {
      setAsideIsOpen(false);
    }
    navigate(`${PAGE.product.menuPath}/${product.Code}`, { state: product.Id });
    dispatch(ProductActions.setProductAction(product));
  };

  return (
    <Grid width={[1]} gridTemplateColumns={grid}>
      {products?.map((item) => {
        if (variant !== CardView.images) {
          return <Card product={item} isHomeCard={isHomeCard} onClick={() => goToProduct(item)} key={item.Id} idShow={idShow} />;
        } else {
          return <ProductCardImage key={item.Id} $backgroundImage={item.Variants?.[0].Images?.[0]?.Url} $animated={!isMobile} onClick={() => navigate(`${PAGE.product.menuPath}/${item.Code}`)} />;
        }
      })}
    </Grid>
  );
}

{
  /* <Grid  width={[1]}  gridTemplateColumns={grid}>
{cards?.map((card) => {
 if( variant !== CardView.images) {
    return  <Card key={card.id} card={card} idShow={idShow} handleShowCard={handleShowCard} onClick={()=>  navigate(PAGE.product.menuPath)} /> 
  }else{
    return  <ProductCardImage key={card.id}  $backgroundImage={card.images[0]} $animated={!isMobile}  onClick={()=>  navigate(PAGE.product.menuPath)} />
  }
})}
</Grid> */
}

export default GridCard;
