import { LoginResponse } from "api/types/responses"
import { IState } from "../SharedStateType"

export type AuthStateType = {
    auth: IState<LoginResponse>
 }

 export const  AUTH = "auth"
 export type AUTH = typeof AUTH

 export const ASK_AUTH = `${AUTH}/askAuth`
 export type ASK_AUTH = typeof ASK_AUTH

 export const REMOVE_AUTH = `${AUTH}/removeAuthAction`
 export type REMOVE_AUTH = typeof REMOVE_AUTH