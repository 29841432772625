import { capitalize } from "utils";
import { Step } from "components/organisms";
import StepLoginSecondForm, { FormStepLoginSecond } from "components/organisms/StepLoginSecondForm";
import StepperHeader from "components/molecules/StepperHeader";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { UserPreference } from "redux/features/user/UserTypes";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { putUserPreferenceAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface StepperLoginSecondPageProps {
  handleCurrentStep: (step: Step) => void;
}

export function StepperLoginSecondPage({ handleCurrentStep }: StepperLoginSecondPageProps) {
  const dispatch = useAppDispatch()
  const { isLoading: isLoadingUser, data: user, errors } = useAppSelector((state) => state.user.userPreference)
  const { t } = useTranslation();
  const paragraphFirst = t(i18Enum.User_UserProfile_Desc_Personal);
  const paragraphTwo = t(i18Enum.User_UserProfile_Desc_Personal2);

  const onSubmit = (value: FormStepLoginSecond) => {

  let req:Partial<UserPreference> = {
    Height:value.Height,
    Weight:value.Weight
  }

   dispatch(putUserPreferenceAction(req));
      handleCurrentStep(Step.third);
  };
  return (
    <>
      <Flex flex={1} padding={"28px 5%"} flexDirection={"column"} alignItems={"center"} $gap={1.5} width={[1, 1, "900px"]}>
        <StepperHeader title={t(i18Enum.User_UserProfile_AboutYou)} backToStep={() => handleCurrentStep(Step.first)} />

        <Txt as='p' width={[1]} marginBottom={0} variant='light' $textTransform='inherit' textAlign={"left"} fontSize={[2, 3, 4]}>
          {capitalize(paragraphFirst)}
        </Txt>

        <Txt as='p' width={[1]} margin={"0 0 30px"} variant='light' $textTransform='inherit' textAlign={"left"} fontSize={[2, 3, 4]}>
          {capitalize(paragraphTwo)}
        </Txt>
      </Flex>
      <StepLoginSecondForm data={user} onSubmit={onSubmit} />
    </>
  );
}
