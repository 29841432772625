import FlippingText from "components/atoms/FlippingText";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Image from "components/primitives/Image";
import Txt from "components/primitives/Txt";
import icon from "../assets/icons/logo.svg";
import React from "react";
import { PAGE } from "utils/const/pagePath";
import { useNavigate } from "react-router-dom";
import { H1 } from "theme/theme.styled";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import errorImage from "/images/error404.png";

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mailTo = "mailto:info@mooore.com?subject=richiesta%20informazioni";
  const handleNavigation = (link: string, external: boolean): void => {
    if (external) window.location.href = link;
    else navigate(link);
  };

  return (
    <Flex width={"100%"} height={"calc(100dvh - 80px)"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
      <Flex flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"} marginBottom={3}>
        <FlippingText text='?' />
        <Image marginX={3} src={icon} alt='payment' width='30%' />
        <FlippingText text='?' />
      </Flex>

      <Image src={errorImage} width={["50%", "40%", "20%"]} />

      {/* <Txt variant={"light"} fontSize={H1} mt={4} as={"h1"} textAlign={"left"}>
        {t(i18Enum.ErrorPage404)}
      </Txt> */}

      <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Txt variant={"light"} fontSize={[0, 1, 3]} as={"p"} lineHeight={"12px"} marginTop={"5%"} textAlign={"center"}>
          {t(i18Enum.ErrorPage404_ErrorLabel)}
        </Txt>

        <Txt variant={"light"} fontSize={[0, 1, 3]} as={"p"} lineHeight={"12px"} textAlign={"center"}>
          {t(i18Enum.ErrorPage404_ErrorLabel2)}
        </Txt>
      </Flex>
      <Flex flexDirection={"row"} pt={4} justifyContent={"space-around"} alignItems={"center"}>
        <Button mr={4} padding={"13px 35px"} variant='addToCart' onClick={() => handleNavigation(mailTo, true)}>
          {t(i18Enum.ErrorPage_Help)}
        </Button>
        <Button padding={"13px 35px"} variant='buyNow' onClick={() => handleNavigation(PAGE.home.menuPath, false)}>
          {t(i18Enum.ErrorPage_Back)}
        </Button>
      </Flex>
      {/* <BackgroundVideo variant={"right"} video={"second"} height={"calc(100dvh - 80px)"} top={"80px"} /> */}
    </Flex>
  );
};
