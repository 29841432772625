/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppGeneralContext } from "../context/AppGeneralContext";
import { CustomThemeContext } from "../context/ThemeContext";
import Section from "../components/primitives/Section";
import Flex from "../components/primitives/Flex";
import Txt from "../components/primitives/Txt";
import { ToggleWithLabels } from "../components/molecules/ToggleWithLabels";
import { EyeSlashSvg } from "../components/atoms/EyeSlashSvg";
import { EyeSvg } from "../components/atoms/EyeSvg";
import { WardrobeTemplate } from "../components/template/WardrobeTemplate";
import { parseWardrobeItems, sortCards, toastType } from "../utils";
import { UserAvatarAndFollowers } from "../components/molecules/UserAvatarAndFollowers";
import { ProfileFields, UserType } from "../redux/features/user/UserTypes";
import { ContentWithVisibleButton } from "../components/molecules/ContentWithVisibleButton";
import { SocialIcon } from "../components/atoms/SocialIcon";
import Search from "../components/molecules/Search";
import { PAGE } from "../utils/const/pagePath";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import MenuNavigate from "components/organisms/MenuNavigate";
import { WardrobeItemType } from "utils/generalTypes/wardrobe";
import { UserActions, switchPrivateAccountAction } from "redux/features/user/userSlice";
import Box from "components/primitives/Box";
import { switchPrivateAccountCall } from "api/userCalls/userCalls";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const dispatch = useAppDispatch();
  const { addToast, isMobile, setIsLoading, isLoading } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const userPreference = useAppSelector((state) => state.user.userPreference.data);
  const { isLoading: isFetchingProfile, errors: profileError, ...userProfile } = useAppSelector((state) => state.user.user);
  const user = useAppSelector((state) => state.user.user.data);
  const userData = useMemo(() => user, [user]);
  const { t } = useTranslation();
  // const  wardrobeItems  = useAppSelector((state) => state.wardrobe.wardrobe.data?.items)
  //const {  isLoading: isFetchingWardrobeItems, errors: wardrobeError } = useAppSelector((state) => state.wardrobe.wardrobe)

  const [cardsData, setCardsData] = useState<WardrobeItemType[][]>([]);

  // TODO rivalutare quando presente wardrobe
  // useEffect(() => {
  //   if (!wardrobeItems)
  //     return

  //   const arrFromWardrobeItems:WardrobeItemType[]= wardrobeItems.map(parseWardrobeItems)
  //   const cardsArray: WardrobeItemType[][] = [];
  //   sortCards(arrFromWardrobeItems, cardsArray, isMobile ? 4 : 8);
  //   setCardsData(cardsArray);
  //  }, [isMobile, wardrobeItems]);

  function handleIsPrivate() {
    dispatch(switchPrivateAccountAction());
  }

  useEffect(() => {
    setIsLoading(isFetchingProfile);
  }, [isFetchingProfile]);

  return (
    <Flex width={"100%"} height={"100%"} padding={["5% 0", "5% 0", "0"]} justifyContent={"center"}>
      {userData && userPreference && (
        <Flex key={userData.Id} flexDirection={"column"} width={"100%"} padding={["0 1em", "0 5em"]} $gap={1.5} maxWidth={950}>
          <MenuNavigate userData={userData} />

          <Section flexDirection={["column", "row"]}>
            <Flex flexDirection={"column"} width={["100%", "50%"]} $gap={0.75}>
              <Txt as='p' variant='light' fontSize={[3, 2]} color={colors.thirdText} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                {t(i18Enum.User_UserProfile_Change_Visibility)}
              </Txt>
              <Flex justifyContent={["center", "start"]} marginBottom={1}>
                <ToggleWithLabels
                  checked={!userData.IsPrivate}
                  handleClick={handleIsPrivate}
                  firstLabel={t(i18Enum.Common_Private)}
                  firstIcon={<EyeSlashSvg />}
                  secondIcon={<EyeSvg variant='white' />}
                  secondLabel={t(i18Enum.Common_Public)}
                />
              </Flex>
            </Flex>

            <Flex justifyContent={["start", "end"]} width={["100%", "50%"]}>
              {userData.IsPrivate ? (
                <Txt as='p' variant='linkDanger' fontSize={[3, 1]} color={colors.dangerBase} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                  {t(i18Enum.User_UserProfile_Preview_PrivateText)}
                </Txt>
              ) : (
                <Link to={PAGE.profilePreview.menuPath + user?.Id}>
                  <Txt as='p' variant='linkLight' fontSize={[3, 2]} color={colors.primaryBase} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                    {t(i18Enum.User_UserProfile_Preview_PublicText)}
                  </Txt>
                </Link>
              )}
            </Flex>
          </Section>

          <Flex>
            <Txt as='p' variant='light' fontSize={[3, 2]} color={colors.thirdText} textAlign={"left"} $textTransform='inherit' marginBottom={"0.5em"}>
              {/* TODO: riattivare il testo insieme ai bottoni per gestire la visibilità dei campi */}
              {/* {t(i18Enum.User_UserProfile_Desc_PrivacyLegend)} */}
            </Txt>
          </Flex>

          <Flex justifyContent={"center"} marginBottom={"2em"}>
            <Flex width={["100%", "60%"]}>
              <UserAvatarAndFollowers
                //TODO: riattivare quando sarà implementata la funzionalità per cambiare la visibilità dei campi
                // hasEdit
                avatar={userData.PictureUrl ?? null}
                firstText={t(i18Enum.Common_Followers)}
                followers={userData?.FollowersCount ?? 0}
                secondText={t(i18Enum.Common_Following)}
                following={userData?.FollowingCount ?? 0}
                idComponent={ProfileFields.avatar}
              />
            </Flex>
          </Flex>

          <Section flexDirection={"column"} $gap={1.5}>
            <ContentWithVisibleButton checked={true} idComponent={ProfileFields.fullName}>
              <Txt as={"h3"} variant='medium' color={colors.primaryBase} fontSize={[3]} textAlign={"left"} marginBottom={0}>{`${userData.GivenName} ${userData.FamilyName}`}</Txt>
            </ContentWithVisibleButton>

            {!userData.ShortDescription ? (
              <Box>
                <Link to={PAGE.account.menuPath}>
                  <Txt as='p' variant='linkLight' fontSize={[3, 2]} color={colors.primaryBase} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                    {t(i18Enum.User_UserProfile_AddBio)}
                  </Txt>
                </Link>
              </Box>
            ) : (
              <ContentWithVisibleButton checked={true} idComponent={ProfileFields.description}>
                <Txt as='p' variant='light' maxWidth={["95%", "100%"]} fontSize={[3, 2]} color={colors.thirdText} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                  {userData.ShortDescription}
                </Txt>
              </ContentWithVisibleButton>
            )}

            {!userData.FacebookUrl && !userData.InstagramUrl && !userData.TikTokUrl && !userData.TwitterUrl && !userData.YouTubeUrl && !userData.PinterestUrl ? (
              <Box>
                <Link to={PAGE.account.menuPath}>
                  <Txt as='p' variant='linkLight' fontSize={[3, 2]} color={colors.primaryBase} textAlign={"left"} $textTransform='inherit' marginBottom={0}>
                    {t(i18Enum.User_UserProfile_AddSocials)}
                  </Txt>
                </Link>
              </Box>
            ) : (
              <ContentWithVisibleButton checked={true} idComponent={ProfileFields.social}>
                <Flex $gap={2}>
                  {/* map userCard.user.socialLinkObj */}
                  {userData.FacebookUrl && <SocialIcon variant='facebook' url={userData.FacebookUrl} />}
                  {userData.InstagramUrl && <SocialIcon variant='instagram' url={userData.InstagramUrl} />}
                  {userData.TikTokUrl && <SocialIcon variant='tiktok' url={userData.TikTokUrl} />}
                  {userData.TwitterUrl && <SocialIcon variant='twitter' url={userData.TwitterUrl} />}
                  {userData.YouTubeUrl && <SocialIcon variant='youtube' url={userData.YouTubeUrl} />}
                  {userData.PinterestUrl && <SocialIcon variant='pinterest' url={userData.PinterestUrl} />}
                </Flex>
              </ContentWithVisibleButton>
            )}
          </Section>
          {/* TODO cambiare con booleano corretto quando presente per la gestione della visualizzazione del guardaroba e scommentare quando presenti api e slice del wardrobe*/}
          {/* <Flex marginTop={"1em"}>
           
            <ContentWithVisibleButton checked={userPreference.IsPrivate} handleToggle={(checked) => handleProfileIsPublic(checked, ProfileFields.wardrobe)} idComponent={ProfileFields.wardrobe}>
              <Txt as={"h3"} variant='light' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
                Wardrobe
              </Txt>
            </ContentWithVisibleButton>
          </Flex>

          <Search placeholder='FIND' />

          <Flex flexDirection={["column", "row"]} flexWrap={"wrap"} justifyContent={"center"}>
            {cardsData.map((cards, index) => {
              return (
                <Flex key={index} flexWrap={"wrap"}>
                  <WardrobeTemplate isFirst={index === 0 ? true : false} hasEdit={false} data={cards} />
                </Flex>
              );
            })}
          </Flex> */}
        </Flex>
      )}
    </Flex>
  );
};
